import { PDP_PAGINATION_LOCAL_STORAGE_LIMIT } from '../../pdp/constants';

export class StorageHandler {
  constructor(storage = '') {
    if (storage === '') {
      if (typeof (localStorage) !== 'undefined') {
        this.storage = localStorage;
      } else {
        this.storage = undefined;
      }
    } else {
      this.storage = storage;
    }
  }

  setContextInStorage(uuid, context) {
    if (this.storage === undefined) {
      return;
    }

    if (!this.isPaginationDataInLocalStorageValid()) {
      this.clearPaginationDataInLocalStorage();
    }

    if (!this.storage.getItem(uuid)) {
      this.addContextToStorage(uuid, context);
    } else {
      this.appendCanonicalUrlsToContextInStorage(uuid, context.canonicalUrls);
    }
  }

  appendCanonicalUrlsToContextInStorage(uuid, canonicalUrls) {
    const context = this.getContextFromStorage(uuid);
    if (context) {
      context.canonicalUrls = Object.assign({}, context.canonicalUrls, canonicalUrls);
      this.addNewContextToStorage(uuid, context);
    }
  }

  parseContextFromStorage(key) {
    let result;
    try {
      result = JSON.parse(this.storage.getItem(key));
    } catch (error) {
      return undefined;
    }
    return result;
  }

  addContextToStorage(uuid, context) {
    if (this.getCurrentCount() === 0) {
      this.addFirstItem(uuid, context);
    } else if (this.getCurrentCount() < PDP_PAGINATION_LOCAL_STORAGE_LIMIT) {
      this.addToTail(uuid, context);
    } else {
      this.removeFromHead();
      this.getCurrentCount() === 0
        ? this.addFirstItem(uuid, context) : this.addToTail(uuid, context);
    }
  }

  getContextFromStorage(uuid) {
    if (this.storage === undefined) {
      return undefined;
    }

    if (!this.isPaginationDataInLocalStorageValid()) {
      this.clearPaginationDataInLocalStorage();
      return undefined;
    }

    const context = this.parseContextFromStorage(uuid);

    if (!context) {
      return undefined;
    }

    if (!context.canonicalUrls) {
      return undefined;
    }

    delete (context.PDP_PAGINATION_NEXT);
    return context;
  }

  getCurrentCount() {
    const currentCount = this.storage.getItem('PDP_PAGINATION_COUNT');
    return !currentCount ? 0 : parseInt(currentCount, 10);
  }

  incrementCount() {
    const currentCount = this.getCurrentCount();
    this.storage.setItem('PDP_PAGINATION_COUNT', currentCount + 1);
  }

  decrementCount() {
    const currentCount = this.getCurrentCount();
    this.storage.setItem('PDP_PAGINATION_COUNT', currentCount - 1);
  }

  addFirstItem(uuid, context) {
    this.addNewContextToStorage(uuid, context);
    this.storage.setItem('PDP_PAGINATION_COUNT', 1);
    this.storage.setItem('PDP_PAGINATION_HEAD', uuid);
    this.storage.setItem('PDP_PAGINATION_TAIL', uuid);
  }

  addToTail(uuid, context) {
    this.addNewContextToStorage(uuid, context);
    this.setNextOfCurrentTailToNewContext(uuid);
    this.setTailToNewContext(uuid);
    this.incrementCount();
  }

  addNewContextToStorage(uuid, context) {
    const contextToBeStored = Object.assign({}, context, { PDP_PAGINATION_NEXT: null });
    this.storage.setItem(uuid, JSON.stringify(contextToBeStored));
  }

  setNextOfCurrentTailToNewContext(uuid) {
    const tailUUID = this.storage.getItem('PDP_PAGINATION_TAIL');
    const tailContext = this.parseContextFromStorage(tailUUID);
    tailContext.PDP_PAGINATION_NEXT = uuid;
    this.storage.setItem(tailUUID, JSON.stringify(tailContext));
  }

  setTailToNewContext(uuid) {
    this.storage.setItem('PDP_PAGINATION_TAIL', uuid);
  }

  removeFromHead() {
    const headUUID = this.storage.getItem('PDP_PAGINATION_HEAD');
    const tailUUID = this.storage.getItem('PDP_PAGINATION_TAIL');
    if (headUUID === tailUUID) {
      this.storage.removeItem(headUUID);
      this.storage.setItem('PDP_PAGINATION_HEAD', null);
      this.storage.setItem('PDP_PAGINATION_TAIL', null);
    } else {
      const headContext = this.parseContextFromStorage(headUUID);
      const newHead = headContext.PDP_PAGINATION_NEXT;
      this.storage.setItem('PDP_PAGINATION_HEAD', newHead);
      this.storage.removeItem(headUUID);
    }
    this.decrementCount();
  }

  isPaginationDataInLocalStorageValid() {
    if (this.isInitialData()) {
      return true;
    }

    return this.listMetaKeysExist()
      && this.isValidCount()
      && this.isValidHead()
      && this.isValidTail();
  }

  isInitialData() {
    const keys = this.paginationKeysInLocalStorage();
    return keys.length === 0;
  }

  listMetaKeysExist() {
    return this.storage.getItem('PDP_PAGINATION_COUNT') !== null
      && this.storage.getItem('PDP_PAGINATION_HEAD') !== null
      && this.storage.getItem('PDP_PAGINATION_TAIL') !== null;
  }

  isValidCount() {
    const count = this.getCurrentCount();
    if (!count) {
      return false;
    }

    return this.paginationKeysInLocalStorage().length === count + 3;
  }

  isValidHead() {
    const headUUID = this.storage.getItem('PDP_PAGINATION_HEAD');
    return this.storage.getItem(headUUID) !== null;
  }

  isValidTail() {
    const tailUUID = this.storage.getItem('PDP_PAGINATION_TAIL');
    return this.storage.getItem(tailUUID) !== null;
  }

  clearPaginationDataInLocalStorage() {
    this.paginationKeysInLocalStorage().map((key) => {
      return this.storage.removeItem(key);
    });
  }

  paginationKeysInLocalStorage() {
    return this.getAllKeys().filter((key) => {
      return key.startsWith('PDP_PAGINATION');
    });
  }

  getAllKeys() {
    if (typeof this.storage.keys === 'function') {
      return this.storage.keys();
    }
    return Object.keys(this.storage);
  }
}
