import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import classNames from 'classnames';
import reactCookie from 'react-cookie';
import isEmpty from 'lodash/isEmpty';
import ToggleSwitch from 'shared/components/ToggleSwitch/ToggleSwitch';
import { IN_STORE, CSP, SELECT_LOCATION } from 'plp/constants';
import { handleFilterOptionSelection, handleURLUpdate, setStoreName } from 'plp/components/ProductListPage/components/FacetedLeftNav/actions';
import { getAvailableStoresInfo, getMappedStoreInfo } from 'srp/utils/srpUtils';
import { checkBodyClass } from 'client-utils/bodyClass';
import './CSPToggle.scss';

export const CSPToggle = ({
  className = '',
  pickupText,
  pageId,
  stores = [],
  itemsCount = 0,
  checked = false,
  selectedStoreName,
  handleFilterOptionSelection,
  selectedStoreNumber,
  handleURLUpdate,
  router,
  applicableFilters,
  isModalOpened,
  modalType,
  selectedFilterKey,
  facetedFiltersList,
  filterValue,
  isNmNewHeader,
  setStoreValue,
}) => {
  const baseClassName = 'csp-toggle';
  const [storeName, setStoreName] = useState(SELECT_LOCATION);
  const [isChecked, setIsChecked] = useState(checked);
  const changeStorePrompt = () => {
    window.YourNeimans && window.YourNeimans.setData({
      isOpen: true,
      pageStack: ['storeListPage'],
    });
  };

  useEffect(() => {
    const { selectedStore, favoriteStore, cleanZip } = getAvailableStoresInfo();

    (async function getStoreData() {
      const mappedStoreData = await getMappedStoreInfo(selectedStore, favoriteStore, cleanZip);
      const storeName = mappedStoreData.selectedStore || mappedStoreData.favoriteStore
        || (stores && stores[0]?.name);

      if (storeName) {
        setStoreName(storeName);
        if(isNmNewHeader) {
          setStoreValue(storeName);
        }
      }
    }());
  }, [stores, selectedStoreNumber]);

  const isInventoryAvailableForCSP = () => {
    const inStoreFacet = applicableFilters.find(({ filterKey }) => filterKey === IN_STORE);
    const isCSPResultAvailable = inStoreFacet?.facets?.csp;

    return !!isCSPResultAvailable;
  };
  
  const cspSelectHandler = (isCSPToggleChecked, isCSPStoreSelected = false) => {
    if (selectedStoreName || (storeName && storeName !== SELECT_LOCATION)) {
      setIsChecked(isCSPToggleChecked);
      handleFilterOptionSelection(IN_STORE, CSP, pageId, isCSPStoreSelected);
      if(isCSPToggleChecked && !checkBodyClass('pane-open')){
        reactCookie.save('cCspTogglePath', (isCSPToggleChecked ? 'facet' : ''), { path: '/' });
        handleURLUpdate(router, false, IN_STORE, CSP, false);
      } else {
        const filterDescriptions = {};
        filterValue?.map((filterOption) => {
          filterDescriptions[filterOption.filterKey] = filterOption.displayText;
          if (filterOption.filterKey === IN_STORE) {
            filterDescriptions[filterOption.filterKey] = 'Get it Fast';
          }
        });
        const selectedValues = facetedFiltersList?.[selectedFilterKey];
        const selectedKeys = !isEmpty(selectedValues) ? Object.keys(selectedValues) : [];
        const filteredKeys = selectedKeys?.filter((e) => {
          return selectedValues[e] === true ? e : null;
        });
        filteredKeys?.includes('csp') && reactCookie.save(`cCspTogglePath`, 'facet', { path: '/' });
        const firstValue = filteredKeys?.[0];
        handleURLUpdate(router, false, selectedFilterKey, firstValue, false, filterDescriptions)
      }
    } else {
      changeStorePrompt();
    }
  };

  useEffect(() => {
    if (selectedStoreNumber) cspSelectHandler(true, true);
  }, [selectedStoreNumber]);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  useEffect(() => {
    isModalOpened && modalType === 'ModalWithoutHeader' && cspSelectHandler(isInventoryAvailableForCSP());
  }, [isModalOpened]);

  return (
    <div className={classNames(baseClassName, className, 'hide-on-desktop', 'hide-on-tablet')}>
      <ToggleSwitch
        id="csp-toggle"
        className={`${baseClassName}__toggle`}
        checked={isChecked}
        onChange={() => cspSelectHandler(!isChecked)}
      />
      <div className={`${baseClassName}__text`}>
        <p>{pickupText}</p>
        <button type="button" onClick={changeStorePrompt}>{selectedStoreName || storeName}</button>
      </div>
      <span className={`${baseClassName}__count`}>
        {itemsCount}
        &nbsp;Items
      </span>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { selectedFilterKey } = state?.facetedLeftNav;
  let facetedFiltersList = [];
  let filterValue = []
  const pageId = state?.page?.pageId;
  switch (pageId) {
    case 'PAGE_ID_PLP':
      facetedFiltersList = state?.facetedLeftNav?.facetedFiltersList;
      filterValue = state?.productListPage?.products?.applicableFilters
      break;
    case 'PAGE_ID_SRP':
      facetedFiltersList = state?.srp?.search?.facetedFiltersList;
      filterValue = state?.srp?.search?.applicableFilters
      break;
    default:
      facetedFiltersList = state?.facetedLeftNav?.facetedFiltersList;
  }
 
  return {
    pickupText: get(state, 'omniFacet.pickup.nonGeoText', 'Curbside pickup'),
    pageId: get(state, 'page.pageId'),
    stores: get(state, 'facetedLeftNav.stores', []),
    checked: get(state, `facetedLeftNav.facetedFiltersList.${IN_STORE}.csp`, false),
    selectedStoreName: get(state, 'facetedLeftNav.selectedStore.name', ''),
    selectedStoreNumber: get(state, 'facetedLeftNav.selectedStoreNumber', ''),
    applicableFilters: get(state, 'productListPage.products.applicableFilters', []),
    isModalOpened: get(state, 'modal.open', false),
    modalType: get(state, 'modal.type', ''),
    selectedFilterKey,
    facetedFiltersList,
    filterValue,
    isNmNewHeader: get(state, 'toggles.NM_NEW_HEADER', false),
  }
};

const mapDispatchToProps = {
  handleFilterOptionSelection,
  handleURLUpdate,
  setStoreValue: setStoreName,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CSPToggle));
