import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import qs from 'query-string';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import { updateParams, setParams } from 'client-utils/utilities-router';
import { loadMoreMagazines, selectFacet, setSelectedFacet } from 'ecm/actions/actions-ecmcontent';
import screenReaderAnnouncer from 'storefront/components/ScreenReaderAlert/screenReaderAnnouncer-actions';
import { ECMSlots } from 'ecm/constants';
import EcmComplex from 'ecm/components/EcmComplex';
import './MagazineIndex.scss';

export class MagazineIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
    };
    this.loadMoreClick = this.loadMoreClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (window) {
      if (prevProps.isLoadingMore && this.props.hasLoadedMore) {
        const relPage = this.state.page > 1 ? this.state.page - 1 : 1;
        const magazineIndexContainer = document.getElementById(`ecm-container-${ECMSlots.MAGAZINE_INDEX.contentId}`);
        if (magazineIndexContainer) {
          const rows = magazineIndexContainer.getElementsByClassName('ecm-container__row');
          const rowNum = (relPage * 10) + (this.props.compulsoryFeaturedStoryRowCount - 1);
          if (rows && rows[rowNum]) {
            const relAnchor = rows[rowNum].querySelector('a');
            if (relAnchor) {
              relAnchor.focus();
            } else {
              const relButton = rows[rowNum].querySelector('button');
              if (relButton) {
                relButton && relButton.focus();
              }
            }
          }
        }
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.loadingError && nextProps.loadingError) {
      this.props.screenReaderAnnouncer('We are experiencing issues loading more content');
    }
    if (this.props.isLoadingMore && nextProps.hasLoadedMore) {
      if (window.picturefill) window.picturefill();
      const newPage = this.state.page + 1;
      this.setState({ page: newPage });
      updateParams(this.props.router, { page: newPage, scroll: false });
    }
    if (this.props.selectedFacet !== nextProps.selectedFacet) {
      this.setState({ page: 1 });
      const facet = nextProps.selectedFacet.toLowerCase() === 'featured'
        ? ''
        : nextProps.selectedFacet;
      this.props.selectFacet(
        facet,
        ECMSlots.MAGAZINE_INDEX
      );
      if (window.picturefill) window.picturefill();
      if (isEmpty(facet)) {
        setParams(this.props.router, {});
      } else {
        setParams(this.props.router, { facet });
      }
    }
  }

  UNSAFE_componentWillMount() {
    const { location = {} } = this.props;
    const query = qs.parse(location.search);
    const facet = query.facet || '';
    const page = parseInt(query.page || 1, 10);
    if (!isEmpty(facet)) {
      this.props.setSelectedFacet(facet, ECMSlots.MAGAZINE_INDEX);
    }
    this.setState({ page });
  }

  loadMoreClick() {
    if (!this.props.isLoadingMore) {
      this.props.loadMoreMagazines(ECMSlots.MAGAZINE_INDEX);
    }
  }

  render() {
    const {
      loadMoreFlagEnabled,
      compulsoryFeaturedStoryRowCount,
      slotInState,
      isLoadingMore,
      loadingError,
      improvePerformanceOfStyleToggle,
    } = this.props;
    const containerId = `ecm-container-${ECMSlots.MAGAZINE_INDEX.contentId}`;

    let styleBlock = `.magazine-index .ecm-container .ecm-container__complex .ecm-container__row:nth-child(${compulsoryFeaturedStoryRowCount}) {padding-left:0;padding-right:0;}
      .magazine-index .ecm-container .ecm-container__complex .ecm-container__row:nth-child(${compulsoryFeaturedStoryRowCount}) .ecm-container__col {padding-right: 0;padding-left: 0;}`;
    for (let i = 1; i < compulsoryFeaturedStoryRowCount; i++) {
      styleBlock += `.magazine-index .ecm-container .ecm-container__complex .ecm-container__row:nth-child(${i}) .ecm-container__col {padding-bottom: 0;}`;
    }

    return (
      <Fragment>
        <style dangerouslySetInnerHTML={{ __html: styleBlock }} />
        <div className="magazine-index">
          <div className="ecm-container" id={containerId}>
            {
              <EcmComplex
                ecmContent={slotInState}
                ecmSlot={ECMSlots.MAGAZINE_INDEX}
                improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
              />
            }
            {
              loadMoreFlagEnabled
              && (
              <div className="magazine-index__load-more">
                <div className="magazine-index__load-more__container">
                  <button
                    className={classnames('magazine-index__load-more__button',
                      { 'load-more-pending': isLoadingMore })}
                    onClick={() => { this.loadMoreClick(); }}
                  >
                    Load More
                  </button>
                </div>
                { loadingError
                  && (
                  <div className="magazine-index__load-more__error">
                    We are experiencing issues loading more content
                  </div>
                  )
                }
              </div>
              )
            }
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const defaultSlot = { rows: [] };
  const slotInState = state.ecm.ecmContent[ECMSlots.MAGAZINE_INDEX.contentId] || defaultSlot;
  const apiName = `ECM${ECMSlots.MAGAZINE_INDEX.contentId}_MAGAZINE_LOAD_MORE`.toLowerCase();
  const apiState = state.api[apiName] || {};
  return {
    loadMoreFlagEnabled: get(slotInState, 'storyConfig.loadMoreFlagEnabled', false),
    compulsoryFeaturedStoryRowCount: get(slotInState, 'storyConfig.compulsoryFeaturedStoryRowCount', 0),
    slotInState,
    isLoadingMore: apiState.loading || false,
    loadingError: apiState.rejected || false,
    hasLoadedMore: apiState.resolved || false,
    selectedFacet: slotInState.selectedFacet || '',
    improvePerformanceOfStyleToggle: get(state, 'toggles.IMPROVE_PERFORMANCE_OF_STYLE', false),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadMoreMagazines: (...args) => dispatch(loadMoreMagazines(...args)),
  selectFacet: (...args) => dispatch(selectFacet(...args)),
  setSelectedFacet: (...args) => dispatch(setSelectedFacet(...args)),
  screenReaderAnnouncer: (...args) => dispatch(screenReaderAnnouncer(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MagazineIndex);
