import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Slider from 'react-slick';
import { getProductRecommendations } from 'plp/components/ProductListPage/actions';
import { isBoutiquePLP } from 'plp/components/ProductListPage/productListPage';
import './productRecs.scss';
import {renderFormattedPrice} from 'client/utilities/utilities-price.js'
import { updateOmCookie } from 'client/common/actions/actions-page';
import SalePrice from 'plp/components/ProductListPage/components/ProductList/components/Product/components/SalePrice/salePrice';
import partition from 'lodash/partition';
import Promotions from 'plp/components/ProductListPage/components/ProductList/components/Product/components/Promotions/promotions';
import {  ABTEST_PROMO_PRIORTIZATION, ABTEST_SALE_PERCENTAGE } from 'shared/actions/actions-page';
import { func } from 'prop-types';

const breakpoint = {
  sm: 576,
  md: 767,
  lg: 1024,
  xl: 1200
};

const TYPE_113 ="113";

const sliderSettings = {
  infinite: false,
  speed: 500,
  lazyLoad: false,
  swipeToSlide: true,
  draggable: true,
  responsive: [
    {
      breakpoint: breakpoint.sm,
      settings: {
        arrows: false,
        slidesToShow: 2.15,
      },
    },
    {
      breakpoint: breakpoint.md,
      settings: {
        arrows: true,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: breakpoint.lg,
      settings: {
        arrows: true,
        slidesToShow: 3,
      },
    },
    {
      breakpoint: breakpoint.xl,
      settings: {
        arrows: true,
        slidesToShow: 3,
      },
    },
  ],
};

function transformFallbackProducts(fallbackProductList = []) {
  return fallbackProductList.map(fallbackProduct => ({
    ...fallbackProduct,
    id: fallbackProduct.id || '',
    name: fallbackProduct.name || '',
    displayable: fallbackProduct.displayable || false,
    merchandiseType: 'OTHERS',
    details: {
      canonicalUrl: fallbackProduct.canonical || '',
      parentheticalCharge: '0.00'
    },
    designer: {
      name: fallbackProduct.designer || ''
    },
    media: {
      main: {
        dynamic: {
          url: fallbackProduct.main || '',
          tag: 'm0'
        }
      },
      alternate: {
        a: {
          dynamic: {
            url: fallbackProduct.alt || '',
            tag: 'a0'
          }
        }
      }
    }
  }));
}

export function ConnectedProductRecs({
  cmsContent,
  productRecs,
  title,
  subTitle,
  fallbackProductRecs,
  isMobilePhone,
  isTablet,
  catId,
  designerName,
  boutiqueRecsToggle,
  boutiquePage,
  abTestsOpt,
  getProductRecommendations,
  brand,
  isDomestic,
  newProductThumbStyle,
  isUIPRICTest,
  plpSRPPricingToggle,
  promoPriorityAbTest,
  promoPriorityToggle,
  salePercentageAbTest,
  productRecToggle
}) {
  const [loading, setLoading] = useState(true);
  const promoPriority = promoPriorityToggle || promoPriorityAbTest;

const convertProductPriceToPLPPrice = (products) =>{
  products?.map(product =>{
    if(product.price){
      product.rprc = renderFormattedPrice(product.price?.retailPrice, product.price?.currencyCode);
      let adornments = product.price?.adornments;
      product.oprc = adornments?.length > 0 && adornments[0]?.price && renderFormattedPrice(adornments[0]?.price, product.price?.currencyCode);
      product.pprc = product.price?.promotionalPrice && renderFormattedPrice(product.price?.promotionalPrice, product.price?.currencyCode);
      product.adornTxt = adornments?.length > 0 && adornments[adornments.length - 1]?.label;

      if(product.promotions?.length > 0) {
          let valid113Promotions = product.promotions.filter(promotion => promotion.showPromoPrice && TYPE_113 === promotion.type);
          mapValid113Promotions(valid113Promotions, product);
          let validNon113Promos = product.promotions.filter(promotion => !(promotion.showPromoPrice && TYPE_113 === promotion.type));
          mapNon113AdvertisePromos(validNon113Promos, product);
      }
    }
  })
  return products;
}

const mapValid113Promotions = (valid113Promotions, product) =>{
  if(valid113Promotions.length > 0){
    product.promoTxt = valid113Promotions[0].templateHTML;
    product.adColor = valid113Promotions[0].templatePricingAdornColorFlag &&  valid113Promotions[0].templatePricingAdornColorFlag;
    product.promoColor = valid113Promotions[0].thumbnailPromoColor;
  }
}

const mapNon113AdvertisePromos = (validNon113Promos, product) =>{
  if(validNon113Promos.length > 0){
    product.advPromos = validNon113Promos.map(non113Promo => non113Promo.templateHTML);
  }
}

  if (isMobilePhone) {
    sliderSettings.slidesToShow = 2.5;
    sliderSettings.arrows= false
  } else if (isTablet) {
    sliderSettings.slidesToShow = 3;
  } else {
    sliderSettings.slidesToShow = 4;
  }

  const fetchProductRecommendations = () => {
    if (catId) {
      getProductRecommendations(catId, boutiqueRecsToggle, boutiquePage, {
        brand: { name: designerName, id: catId },
        groups: abTestsOpt
      }, productRecToggle).then(() => {
        setLoading(false);
      });
    }
  };

  useEffect(() => {
      fetchProductRecommendations();
  }, []);

  const onRecommendationClick = (rec) => {
    if(rec.details?.canonicalUrl || rec.canonical){
      if(boutiqueRecsToggle){
        updateOmCookie('product', title, 'boutique', 'stylyze');
      } else {
        updateOmCookie('product', title, 'browse', 'stylyze');
      }
    }
  }

 const filterPromotions = (promotions) => {
  return promotions ? partition(promotions, { showPromoPrice: true }) : [[], []];
}
 const displayPrice = (product) =>{
  const [
    promotionWithPrice,
    promotionWithoutPrice
  ] = filterPromotions(product?.promotions);

  return  <div className={`${newProductThumbStyle ? "new-style" : ""}`}>
    <SalePrice
      currencyCode={product?.price?.currencyCode}
      retailPrice={product.rprc}
      adornments={product?.price?.adornments}
      priceAdornmentColor={product.adColor}
      isPLP={true}
      original={product.oprc}
      adornLabel={(plpSRPPricingToggle || isUIPRICTest) ? '' : product.adornTxt}
      plpSRPPricingToggle={plpSRPPricingToggle}
      promotionComponent={isDomestic && (isUIPRICTest || plpSRPPricingToggle)
        ? () => (
          <Promotions
            promotions={promotionWithPrice}
            currencyCode={product?.price?.currencyCode}
            promotionalPrice={product.pprc}
            isPLP={true}
            promoColor={product.promoColor || brand == "NM" ? 'C2252D' : 'EF4036' }
            promoText={product.promoTxt}
            plpSRPPricingToggle={plpSRPPricingToggle}
            isUIPRICTest={isUIPRICTest}
            newProductThumbStyle={newProductThumbStyle}
            promoPriority={promoPriority}
            promoInRed={true}
          />
        )
        : null
      }
      isUIPRICTest={isUIPRICTest}
      newProductThumbStyle={newProductThumbStyle}
      promotionalPrice={product.pprc}
      promoText={product.promoTxt}
      isDomestic={isDomestic}
      salePercentageAbTest={salePercentageAbTest}
      brand={brand}
    />
    {isDomestic && product.advPromos?.length > 0 && (
        <Promotions
          promotions={promotionWithoutPrice}
          isPLP={true}
          advertisePromos={product.advPromos}
          isUIPRICTest={isUIPRICTest}
          newProductThumbStyle={newProductThumbStyle}
          promoPriority={promoPriority}
          promoInRed={true}
        />
    )}
    </div>
  }

  fallbackProductRecs = transformFallbackProducts(fallbackProductRecs);
  const layouts = cmsContent[0]?.fields?.l1Layouts || [];
  const stylyzeRecs = layouts.filter(layout => layout.fields?.placement === 'StylyzeRecs');
  const stylyzeRecsItems = stylyzeRecs[0]?.fields?.components || [];
  const stylyzeRecsItemsFields = stylyzeRecsItems[0]?.fields;
  if (loading) return ""; //<div className="grid-100 product-recommendations loading">Loading...</div>;
  let productsToRender =  productRecs.length > 0 ? convertProductPriceToPLPPrice(productRecs) : fallbackProductRecs;
  productsToRender = productsToRender.filter(obj => obj.hasOwnProperty('id') && obj.displayable);
  if(productsToRender.length===0) return "";
  
  return (
    <div className={`grid-100 product-recommendations ${productRecs.length ? "product-recs" : "fallback-product-recs"}`}>
      {(title || stylyzeRecsItemsFields?.title) ? <h1 className='recs-title'>{title || stylyzeRecsItemsFields?.title}</h1> : <h1 className='recs-title'>You May Also Like</h1> }
      {(subTitle || stylyzeRecsItemsFields?.subTitle) && <h2 className='recs-sub-title'>{subTitle || stylyzeRecsItemsFields?.subTitle}</h2>}
      <Slider {...sliderSettings}>
        {productsToRender.map((i, index) => (
          <div key={index} className='product-card grid-33 tablet-grid-33 mobile-grid-50'>
            <a href={i.details?.canonicalUrl || i.canonical} key={i.id} onClick={() => onRecommendationClick(i)} className='card-wrapper'>
              <div className="product-tile">
                <div className="product-info">
                  <div className="image-container">
                    <img src={i.media?.main?.dynamic.url || i.main} alt={i.name} />
                  </div>
                  <ul>
                    <li className="designer-name">{i.designer?.name}</li>
                    <li className="description">{i.name}</li>
                   { displayPrice(i)}
                  </ul>
                </div>
              </div>
            </a>
          </div>  
        ))}
      </Slider>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isMobilePhone: get(state, 'device.isMobilePhone', false),
  isTablet: get(state, 'device.isTablet', false),
  cmsContent: get(state, 'cms.entries', []),
  productRecs: get(state, 'productListPage.productRecs.products', []),
  title: get(state, 'productListPage.productRecs.stylyze.masterApiLabel', null),
  subTitle: get(state, 'productListPage.productRecs.stylyze.subHeadlineLabel', null),
  fallbackProductRecs: get(state, 'productListPage.products.list', []).slice(-4),
  catId: get(state, 'templates.templateDetails.id', false),
  designerName: get(state, 'navigation.breadcrumbs[1].name', ''),
  boutiqueRecsToggle: get(state, 'toggles.AB_TEST_PRODUCT_RECS_BOUTIQUE', false) && get(state, `abTestsOpt.tl329-2.variation`, 'a') === 'b',
  boutiquePage: isBoutiquePLP(state),
  abTestsOpt: get(state, "abTestsOpt"),
  brand: state.brand_name?.env,
  isDomestic : get(state, 'locale.countryCode') === 'US',
  newProductThumbStyle: get(state, 'toggles.NEW_PLPSRP_GRID', false),
  isUIPRICTest: get(state.abTestsOpt, 'UIPRIC.variation', 'a') === 'b',
  plpSRPPricingToggle: get(state.toggles, 'PRICING_STYLE_UPDATE', false),
  promoPriorityAbTest: get(state, `abTestsOpt.${ABTEST_PROMO_PRIORTIZATION}.variation`, 'a') === 'b',
  promoPriorityToggle: get(state, 'toggles.PROMO_PRIORTIZATION', false),
  salePercentageAbTest: get(state, 'toggles.ABTEST_SALE_PERCENTAGE', false) &&
  get(state, `abTestsOpt.${ABTEST_SALE_PERCENTAGE}.variation`, 'a') === 'b',
  productRecToggle: get(state.toggles, 'PRODUCT_RECS_PLP', false),
});

const mapDispatchToProps = {
  getProductRecommendations,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedProductRecs);
