import React, { PureComponent } from 'react';
import classNames from 'classnames';

import './AlphaIndexItem.scss';

class AlphaIndexItem extends PureComponent {
  render() {
    const {
      keyName, active, selected, clickHandler,
    } = this.props;
    const style = classNames({
      'index-link-active': active,
      'index-link-inactive': !active,
      selected,
    });
    return (
      <li role="menuitem" className="alpha-item">
        { /* TODO: NMOWEB-8459 Fix invalid anchor */ }
        { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
        <a
          aria-label={keyName}
          aria-current={selected ? 'page' : ''}
          tabIndex={active ? 0 : -1}
          className={style}
          onKeyPress={clickHandler}
          onClick={clickHandler}
        >
          {keyName}
        </a>
      </li>
    );
  }
}

export default AlphaIndexItem;
