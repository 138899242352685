import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { getDesignerIndexDesignerSearchTerm } from 'storefront/components/Navigation/NavSlider/navSlider-reducer';
import { designerIndexDesignerSearchUpdate } from 'storefront/components/Navigation/NavSlider/navSlider-actions';


const DesignerSearchBox = ({ searchTerm, handleOnChange }) => (
  <div className="designer-index-designers-search-box hide-on-desktop hide-on-tablet">
    <input
      id="designer-search"
      type="text"
      title="designer search box"
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      className="designer-index-designers-search-box__text"
      placeholder="Search Designers"
      value={searchTerm}
      onChange={(e) => handleOnChange(e.target.value)}
    />
  </div>
);

const mapStateToProps = (state) => ({
  templates: get(state, 'templates', {}),
  searchTerm: getDesignerIndexDesignerSearchTerm(state),
});

const mapDispatchToProps = {
  handleOnChange: designerIndexDesignerSearchUpdate,
};

export default connect(mapStateToProps, mapDispatchToProps)(DesignerSearchBox);
