import React from 'react';
import classNames from 'classnames';

import './ToggleSwitch.scss';

const ToggleSwitch = ({
  id,
  name,
  className,
  onChange = () => {},
  checked = false,
  disabled = false,
}) => {
  const baseClassName = 'toggle-switch';
  const handleKeyPress = (e) => {
    if (e.keyCode !== 32) return;

    e.preventDefault();
    onChange(!checked);
  };

  return (
    <div
      className={classNames(baseClassName, className, {
        [`${baseClassName}--disabled`]: disabled,
      })}
    >
      <input
        type="checkbox"
        name={name}
        className={`${baseClassName}__checkbox`}
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
      {id && (
        <label
          className={`${baseClassName}__label`}
          tabIndex={disabled ? -1 : 1}
          onKeyDown={(e) => handleKeyPress(e)}
          htmlFor={id}
        >
          <span
            className={`${baseClassName}__switch`}
            tabIndex={-1}
          />
        </label>
      )}
    </div>
  );
};

export default ToggleSwitch;
