import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import pullAt from 'lodash/pullAt';
import { SearchSuggestions } from 'srp/components/atoms/SearchSuggestions/SearchSuggestions';
import { updateTopProducts, setGenderTA } from 'client/srp/actions/actions';
import { formatTopItems } from 'srp/utils/srpUtils';
import { getGenderWithOverride } from 'client-utils/utilities-gender';
import { setGender, setTypeaheadUtag } from 'shared/components/App/app-actions';
import { GENDER_PLACEMENT } from 'universal/constants/genderPlacement';
import closeSVG from 'client/assets/images/header-close-icon.svg';
import {
    getIsTypeaheadSuggestionEmpty,
    getMobileSearchTerm, getTypeaheadUIConf, typeaheadSuggestionPayload,
} from '../search-reducers';
import { saveToLocalStorage } from 'client-utils/utilities-storage';
import { loadTypeaheadUIConfig, setMobileTextBoxValue } from '../search-actions';
import { searchFormSubmit } from '../submitHelper';

import './collapseSearchBarMobile.scss';
import '../bloomreach.scss';

const CollapseSearchBarMobile = (props) => {
    const {
        searchTerm,
        dtGender,
        typeaheadUIConf,
        typeaheadSuggestionPayload,
        topProducts,
        setGender,
        handleOnChange,
        setTypeaheadUtag,
        isTypeaheadSuggestionEmpty,
        taGender,
        isCollapsedSearchBarToggle,
        closeModal
    } = props;

    const selectedUIConfig = typeaheadUIConf;
    const [inputIsFocused, setInputIsFocused] = useState(false);
    const [recentSearchHover, setRecentSearchHover] = useState(false);
    const [selectedGender, setSelectedGender] = useState(dtGender);
    const [typeaheadSuggestions, setTypeaheadSuggestions] = useState({});
    const [suggestions, setSuggestions] = useState([]);
    const [typeaheadUIConfig, setTypeaheadUIConfig] = useState(selectedUIConfig);
    const [isSuggestionSelected, setIsSuggestionSelected] = useState(false);
    const [showTopProduct, setShowTopProduct] = useState({});

    const recentSearchRef = useRef(null);
    const mobileBrSearchInputRef = useRef(null);
    const modalBrSearchInputRef = useRef(null);
    const searchIconRef = useRef(null);
    const prevPropsRef = useRef(props);

    useEffect(() => {
        const storageKey = 'SRP_SEARCH_RECENT_KEYWORDS';
        const recentSearchSample = 7;
        try {
            const recentSearches =
                typeof localStorage !== 'undefined'
                    ? JSON.parse(localStorage.getItem(storageKey)) || []
                    : false;
            if (recentSearches && recentSearches.length >= recentSearchSample) {
                recentSearches.splice(recentSearchSample, recentSearches.length);
            }
            if (searchTerm !== '') {
                if (recentSearches) {
                    const repeatedSearchValue = recentSearches.indexOf(searchTerm);
                    if (repeatedSearchValue > -1 && pageId.indexOf('SRP') !== -1) {
                        pullAt(recentSearches, repeatedSearchValue);
                    }
                    recentSearches.unshift(searchTerm);
                }
            }
            typeof localStorage !== 'undefined' && saveToLocalStorage(storageKey, JSON.stringify(recentSearches));
        } catch (e) {
            localStorage.removeItem(storageKey);
        }

        setTimeout(() => modalBrSearchInputRef.current.focus(), 20);

        // Populates initial BR results if there's a previous term
        if (searchTerm.length > 0) {
            if (typeof window !== 'undefined' && window.$ && window.BRAutosuggest) {
                window.BRAutosuggest.search(searchTerm);
            }
        }

        setShowTopProduct(topProducts);
        setTypeaheadSuggestions(typeaheadSuggestionPayload);
    }, []);

    useEffect(() => {
        const prevProps = prevPropsRef.current;
        if (typeaheadSuggestionPayload !== prevProps.typeaheadSuggestionPayload) {
            onSearchTermChange(selectedGender);
        }
        if (prevProps.typeaheadPayload !== props.typeaheadPayload) {
            setSuggestions(props.typeaheadPayload);
            setIsSuggestionSelected(true);
        }
    }, [typeaheadSuggestionPayload]);

    useEffect(() => {
        const {
            loadTypeaheadUIConfig,
            dtGender,
            topProducts,
            updateTopProducts,
        } = props;
        if (!IS_CLIENT && global.typeaheadDataObj) {
            const { topProducts: commonTopProducts = [], women, men } = global.typeaheadDataObj;

            loadTypeaheadUIConfig();

            if (isEmpty(topProducts)) {
                const topProductsConfig = women?.topProducts && men?.topProducts
                     && dtGender
                    ? [...women.topProducts, ...men.topProducts]
                    : commonTopProducts;

                if (!isEmpty(topProductsConfig)) {
                    const docsArray = topProductsConfig.map((id) => {
                        return { pId: id };
                    });
                    const body = {
                        docs: docsArray,
                    };
                    const cacheKey = topProductsConfig.map((id) => id).toString();
                    updateTopProducts(body, `${cacheKey}`);
                }
            }
        }
    }, []);

    const handleSelectionOnRecentSearch = (value) => {
        if (value) {
            handleOnChange(value);
            setTimeout(() => {
                if (mobileBrSearchInputRef.current) {
                    mobileBrSearchInputRef.current.click();
                }
            }, 200);
        } else {
            setRecentSearchHover(false);
            setInputIsFocused(false);
        }
    };

    const handleTextChange = (value) => {
        handleOnChange(value);
    };

    const openRecentSearchOnTouch = () => {
        setInputIsFocused(true);
        mobileBrSearchInputRef.current && mobileBrSearchInputRef.current.focus();
    };

    const toggleGenderTA = (gender) => {
        setSelectedGender(gender);
    };

    const closeModalButton = () => {
        closeModal(false);
    };

    const onRecentSearchHover = (hoverStatus) => {
        const resetGenderValue = hoverStatus === false
            ? props.dtGender
            : selectedGender;

        setSelectedGender(resetGenderValue);
        setRecentSearchHover(hoverStatus);
    };

    return (
        <div className={'new-search-modal-view'}>
            <form
                action={'/s/'}
                onSubmit={(e) => {
                    searchFormSubmit(e, searchTerm);
                }}
                method="GET"
                style={{ padding: '10px 10px 0' }}
            >
                <div className="modal-view__close-icon">
                    <span className="close-text" onClick={closeModalButton}>Close</span>
                    <img
                        alt="Close Icon"
                        aria-hidden="true"
                        className="new-close-icon"
                        height="16"
                        src={closeSVG}
                        width="16"
                        onClick={closeModalButton}
                    />
                </div>
                <input title="from-input" name="from" type="hidden" value='brSearch' />
                <div className='input-search'>
                    <input title="l-input" name="l" type="hidden" value={searchTerm} />
                    <input
                        type="button"
                        ref={(input) => (searchIconRef.current = input)}
                        name="search-icon"
                        title="search icon"
                        aria-label="Get Results"
                        className={classnames('search-box-mobile__text__search-icon', {
                            'search-box-mobile__text__search-icon--focused': inputIsFocused,
                        })}
                        onClick={(e) => {
                            setFocusAtEnd(e);
                        }}
                        onFocus={() => {
                            if (inputIsFocused) {
                                setTimeout(() => {
                                    modalBrSearchInputRef.current.focus();
                                }, 20);
                            }
                        }}
                        style={{ transform: 'TranslateX(-10px)' }}
                    />
                    <input
                        ref={(input) => modalBrSearchInputRef.current = input}
                        title="search-term-input"
                        placeholder="What are you looking for?"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        aria-label="Search Box"
                        id="mobileBrSearchInput"
                        name="q"
                        className="search-box-mobile__text"
                        value={searchTerm}
                        onChange={(e) => handleTextChange(e.target.value)}
                        onFocus={() => {
                            setInputIsFocused(true);
                        }}
                        onTouchEnd={() => openRecentSearchOnTouch()}
                    />
                    <div className="brm-autosuggest-wrap-mobile">
                        <div id="newBRTypeAhead" />
                    </div>
                </div>
                {(inputIsFocused || recentSearchHover) && (
                    <div className='search-suggestions-renderer'>
                        <SearchSuggestions
                            closeRecentSearchOnClear={onRecentSearchHover}
                            handleSelection={handleSelectionOnRecentSearch}
                            mobileView
                            arrowIndex={-1}
                            forwardRef={recentSearchRef}
                            typeaheadData={typeaheadUIConfig}
                            typeaheadSuggestionPayload={typeaheadSuggestions}
                            searchTerm={searchTerm}
                            topItems={!isEmpty(topProducts) ? formatTopItems(topProducts) : []}
                            noResults={isTypeaheadSuggestionEmpty}
                            selectedGender={selectedGender}
                            toggleGenderTA={toggleGenderTA}
                            isGenderTypeahead={false}
                            setGender={setGender}
                            setTypeaheadUtag={setTypeaheadUtag}
                            storageKey={'SRP_SEARCH_RECENT_KEYWORDS'}
                            onListClick={() => setGender(selectedGender)}
                            brand={'NM'}
                            collapsedSearchExperience={isCollapsedSearchBarToggle}
                        />
                    </div>
                )}
            </form>
        </div>
    );
};

const mapStateToProps = (state) => {
    const dtGender = getGenderWithOverride(state);

    return {
        searchTerm: getMobileSearchTerm(state),
        isMobilePhone: get(state, 'device.isMobilePhone', false),
        pageId: get(state, 'page.pageId', ''),
        routing: get(state, 'routing.locationBeforeTransitions', {}),
        typeaheadUIConf: getTypeaheadUIConf(state),
        typeaheadSuggestionPayload: typeaheadSuggestionPayload(state),
        isTypeaheadSuggestionEmpty: getIsTypeaheadSuggestionEmpty(state),
        topProducts: get(state, 'srp.search.topProducts', []),
        selectedGender: get(state, 'srp.search.ta_gender'),
        dtGender,
        taGender: get(state, 'srp.search.ta_gender', dtGender),
        isDomestic: get(state, 'locale.countryCode') === 'US',
    };
};

const mapDispatchToProps = ({
    handleOnChange: setMobileTextBoxValue,
    loadTypeaheadUIConfig,
    updateTopProducts,
    setGender: (gender) => setGender(gender, GENDER_PLACEMENT.HEADER),
    setTypeaheadUtag,
    setGenderTA
});

export default connect(mapStateToProps, mapDispatchToProps)(CollapseSearchBarMobile);