import React from 'react';
import isEmpty from 'lodash/isEmpty';
import take from 'lodash/take';
import capitalize from 'lodash/capitalize';
import uniqBy from 'lodash/uniqBy';
import classnames from 'classnames';
import closeSVG from 'assets/images/close.svg';
import './RecentSearchSuggestions.scss';
import { saveToLocalStorage } from 'client-utils/utilities-storage';

const clearAllRecentSearch = (closeRecentSearchOnClear, e) => {
  e.preventDefault();
  e.stopPropagation();
  if (typeof (localStorage) !== 'undefined') {
    saveToLocalStorage('SRP_SEARCH_RECENT_KEYWORDS', null);
  }
  closeRecentSearchOnClear(false);
};

const getAllRecentSearch = () => {
  if (typeof (localStorage) === 'undefined' || isEmpty(localStorage)) {
    return [];
  }

  const parsedRecentSearches = JSON.parse(localStorage.getItem('SRP_SEARCH_RECENT_KEYWORDS'));
  return take(uniqBy(parsedRecentSearches?.filter((word) => word), (item) => item?.toLowerCase()), 5);
};

const removeRepeatSearchValue = (recentSearches, searchItem) => {
  const repeatedSearchValue = recentSearches.indexOf(searchItem);
  if (repeatedSearchValue !== -1) {
    recentSearches.splice(repeatedSearchValue, 1);
    recentSearches.unshift(searchItem);
  }
  return recentSearches;
};

const updateLocalStorage = (recentSearchStorageKey, searchItem) => {
  if (typeof localStorage === 'undefined') return;
  const recentSearch = JSON.parse(localStorage.getItem(recentSearchStorageKey)) || [];
  const updatedSearchTerm = removeRepeatSearchValue(recentSearch, searchItem);
  saveToLocalStorage(recentSearchStorageKey, JSON.stringify(updatedSearchTerm));
};

const handleItemClick = (e, handleSelection, searchItem) => {
  e.preventDefault();
  e.stopPropagation();
  window.sessionStorage.setItem('recently_searched', true);

  updateLocalStorage('SRP_SEARCH_RECENT_KEYWORDS', searchItem);
  handleSelection(searchItem);
};

export const RecentSearchSuggestions = ({
  closeRecentSearchOnClear,
  mobileView = false,
  forwardRef = null,
  handleSelection,
  arrowIndex,
}) => {
  const recentSearch = getAllRecentSearch();
  if (!isEmpty(recentSearch)) {
    return (
      <div
        className={classnames('recent-search-frame', {
          'desktop-view__frame': !mobileView,
          'mobile-view__frame': mobileView,
        })}
        ref={forwardRef}
      >
        <div className="recent-search-title-control">
          <div className="recent-search-title">
            <span className="title-text">Recent Searches</span>
          </div>
          <div className="recent-search-control">
            <button
              className={classnames('clear-all-btn', { 'no-background': mobileView })}
              onClick={(e) => { clearAllRecentSearch(closeRecentSearchOnClear, e); }}
              onTouchEnd={(e) => { clearAllRecentSearch(closeRecentSearchOnClear, e); }}
            >
              Clear History
            </button>
          </div>
        </div>
        <div className="recent-searchItems">
          <ul>
            {recentSearch.map((searchItem, index) => !isEmpty(searchItem) && (
            <li
              key={searchItem}
              className={classnames({ 'hightlight-arrow-updown': arrowIndex === index })}
              onClick={(e) => {
                handleItemClick(e, handleSelection, searchItem);
              }}
              onTouchEnd={(e) => {
                handleItemClick(e, handleSelection, searchItem);
              }}
            >
              <button>{searchItem?.replace(/\w+/g,capitalize)}</button>
            </li>
            ))}
          </ul>
        </div>
        {mobileView && (
          <button
            aria-label="Close"
            className="close-button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSelection();
            }}
            onTouchEnd={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSelection();
            }}
          >
            <img
              alt="Close Icon"
              aria-hidden="true"
              className="close-icon"
              height="12"
              src={closeSVG}
              width="12"
            />
          </button>
        )}
      </div>
    );
  }
  return null;
};
