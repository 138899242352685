import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import includes from 'lodash/includes';
import get from 'lodash/get';
import classnames from 'classnames';
import { unescape } from 'he';
import partition from 'lodash/partition';
import isEmpty from 'lodash/isEmpty';
import { InView } from 'react-intersection-observer';
import { dangerousProperty } from 'client-utils/utilities-html-sanitizer';
import NO_IMAGE_AVAILABLE from 'assets/images/no-image.jpeg';
import HC_NO_IMAGE_AVAILABLE from 'hcAssets/images/hc-no-image.jpeg';
import DOG_EAR_IMAGE from 'assets/images/dogear.png';
import {
  SOLD_OUT_TEXT,
  BRAND_CODE,
  PROMOTION_TYPE_113,
  SHOW_MORE_COLORS_AVAILABLE_TEXT,
  ECO_FRIENDLY,
  QUICK_LOOK,
  QL_VS_SUPPRESS_DESIGNER_LIST,
  FASHIONED_FOR_CHANGE,
} from 'plp/constants';
import { ABTEST_MOBILE_ALT_IMAGES, ABTEST_PROMO_PRIORTIZATION, ABTEST_VIEW_SIMILAR, ABTEST_ENLARGE_TILES, ABTEST_SALE_PERCENTAGE, WEB_ADDITIONAL_IMAGES_ALT_SHOTS } from 'shared/actions/actions-page';
import { setSelectedColorCode } from 'pdp/pages/ProductPage/actions';
import {
  showQLModalWindow,
  updateSwatchesValue,
  matchFristAvalibleShot
} from 'plp/components/ProductListPage/actions';
import { clearQRFromUtag as clearQRFromUtagAction } from 'srp/actions/actions';
import Toggle from 'shared/components/Toggle/toggle';
import Image from './components/Image/image';
import ImageLazyLoad from './components/Image/imageLazyLoad';
import ImageLazyLoadIntersectionObserver from './components/Image/imageLazyLoadIntersectionObserver';
import SalePrice from './components/SalePrice/salePrice';
import ColorSwatches from './components/ColorSwatches/colorSwatches';
import Promotions from './components/Promotions/promotions';
import ProductMetadata from './components/ProductMetadata/productMetadata';
import QuickLookViewSimilarMobile from './components/QuickLookViewSimilarMobile/quickLookViewSimilarMobile'
import './product.scss';
import './inline-product.scss';
import { setPanelTitle, openQLProductPanel } from 'client/cms/actions/index';
import { isGWPFeatureEnabled } from 'client/utilities/utilities-page';

function trimSpaces(str) {
  return str.trim().replace(/\s\s+/g, ' ');
}

function stripHtml(html) {
  return html.replace(/<[^>]+>/g, '');
}

function get113TypePromotion(promotions) {
  return promotions.filter((promotion) => promotion.type === PROMOTION_TYPE_113)[0] || {};
}

export const ProductLink = (props) => (
  <Link target="_self" id={props.productId} onClick={() => onProductClick(props)} className={props.className} to={props.to}>
    {props.children}
  </Link>
);

export const ProductNameAndPrice = ({
  name = '',
  price = {},
  promotions = [],
  isPLP,
  retail,
  original,
  adornLabel,
  adornColor = '',
  plpSRPPricingToggle = false,
  promotionComponent,
  isUIPRICTest,
  splitTitle = true,
  newProductThumbStyle = false,
  promotionalPrice = null,
  promoText,
  isDomestic = false,
  salePercentageAbTest = false,
  brand = 'NM'
}) => (
  <Fragment>
    <h2
      className={classnames(
        {
          'seo-wrapper': true,
          'product-name': !newProductThumbStyle,
          'split-into-two-row': splitTitle,
          'text-truncate': !splitTitle,
          'inherit-font-size': true,
          'text-enhancement': true,
        }
      )}
    >
      <span tabIndex={0} className="name" dangerouslySetInnerHTML={dangerousProperty(name)} />
    </h2>
    <div className="product-thumbnail__description__price">
      <SalePrice
        currencyCode={price.currencyCode}
        retailPrice={isPLP ? retail : price.retailPrice}
        adornments={price.adornments}
        priceAdornmentColor={isPLP ? `#${adornColor}` : `#${get113TypePromotion(promotions).templatePricingAdornColor || ''}`}
        isPLP={isPLP}
        original={original}
        adornLabel={(plpSRPPricingToggle || isUIPRICTest) ? '' : adornLabel}
        plpSRPPricingToggle={plpSRPPricingToggle}
        promotionComponent={promotionComponent}
        isUIPRICTest={isUIPRICTest}
        newProductThumbStyle={newProductThumbStyle}
        promotionalPrice={promotionalPrice}
        promoText={promoText}
        isDomestic={isDomestic}
        salePercentageAbTest={salePercentageAbTest}
        brand={brand}
      />
    </div>
  </Fragment>
);

export const ProductLoadingPlaceholder = ({ animate }) => (
  <div className="product-thumbnail tablet-grid-33 mobile-grid-50 grid-1600">
    <div className={classnames(
      {
        'product-thumbnail__image': true,
        'product-thumbnail2to3__image': true,
        animate,
      }
    )}
    />
    <div className="product-details-placeholder">
      <div className={classnames(
        {
          'loading-bar__name': true,
          animate,
        }
      )}
      />
      <div className={classnames(
        {
          'loading-bar__description': true,
          animate,
        }
      )}
      />
      <div className={classnames(
        {
          'loading-bar__price': true,
          animate,
        }
      )}
      />
    </div>
  </div>
);

function onQuickLookButtonClick(props, colorCode, navPath, isDisplayAsGroups) {
  const swatches = get(props, 'colorOptions', []);
  let selectedColorCode = null;
  if (swatches.length !== 0 && props.setSelectedColorCode !== undefined) {
    selectedColorCode = !isEmpty(colorCode) ? colorCode : swatches[0].key;
    props.setSelectedColorCode(selectedColorCode);
  }

  if (!props.newQLSidebarToggle) {
    if (props.showQLModalWindow !== undefined) {
      props.showQLModalWindow(props.id, props.productUrl, navPath, isDisplayAsGroups);
    }
  } else if (props.newQLSidebarToggle && props.openQLProductPanel && !props.isDomestic) {
    if (props.showQLModalWindow !== undefined) {
      props.showQLModalWindow(props.id, props.productUrl, navPath, isDisplayAsGroups);
    }
  } else if (props.newQLSidebarToggle && props.openQLProductPanel && props.isDomestic) {
    props.openQLProductPanel(props.id, selectedColorCode);
    props.setPanelTitle(QUICK_LOOK);
  }

  if (props.clearQRFromUtag !== undefined) {
    props.clearQRFromUtag();
  }
}

function onProductClick(props) {
  if (props.isPLP || props.searchFlow) {
    if (props.colorOptions.length !== 0) {
      const colorCode = get(props, `colorOptions[${props.index}].key`, '');
      if (props.setSelectedColorCode !== undefined) {
        props.setSelectedColorCode(colorCode);
      }
    }
  } else if (props.colorOptions.values.length !== 0) {
    const colorCode = props.colorOptions.values[props.index].key;
    if (props.setSelectedColorCode !== undefined) {
      props.setSelectedColorCode(colorCode);
    }
  }
}


export class Product extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedColorSwatchIndex: 0,
      hoveredColorSwatchIndex: -1,
      selectedColorSwatchCode: '',
      lastHoveredColorSwatchIndex: -1,
      isSwatchHover: false,
      exitingSwatch: false,
      mainImageFailed: false,
      mainImageLoaded: false,
      selectFirstImage: false,
    };
    this.hasSwatchBeenSelectedManually = false;
    this.changeSelectedColorSwatchIndex = this.changeSelectedColorSwatchIndex.bind(this);
    this.changeSelectedColorSwatchCode = this.changeSelectedColorSwatchCode.bind(this);
    this.handleMouseOutForSwatch = this.handleMouseOutForSwatch.bind(this);
    this.handleMouseOverForSwatch = this.handleMouseOverForSwatch.bind(this);
    this.getProductImageURL = this.getProductImageURL.bind(this);
    this.getProductAltImageURL = this.getProductAltImageURL.bind(this);
    this.getDefaultSwatchImageURL = this.getDefaultSwatchImageURL.bind(this);
    this.getLastWatchImage = this.getLastWatchImage.bind(this);
    this.isDefaultSwatch = this.isDefaultSwatch.bind(this);
    this.getValidImageUrl = this.getValidImageUrl.bind(this);
    this.setMainImageFailed = this.setMainImageFailed.bind(this);
    this.setMainImageLoaded = this.setMainImageLoaded.bind(this);
    this.handleOnClickForColorSwatch = this.handleOnClickForColorSwatch.bind(this);
    this.renderShowMoreColorsAvailableFlag = this.renderShowMoreColorsAvailableFlag.bind(this);
    const filteredColorSwatchIndex = this.getFilteredColorSwatchIndex(props);
    if (filteredColorSwatchIndex !== -1) {
      setTimeout(() => this.changeSelectedColorSwatchIndex(filteredColorSwatchIndex), 0);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.isFavorite !== this.props.isFavorite
      || nextState !== this.state
      || nextProps.filteredColors !== this.props.filteredColors;
  }

  getFilteredColorSwatchIndex(props) {
    if (props.isPLP || props.searchFlow) {
      return isEmpty(props.colorOptions) ? -1 : findIndex(props.colorOptions,
        (colorOption) => includes(props.filteredColors, colorOption.fct));
    }
    return (isEmpty(props.colorOptions) || isEmpty(props.colorOptions.values))
      ? -1
      : findIndex(
        props.colorOptions.values,
        (colorOption) => (colorOption.displaySkuImg
          && includes(props.filteredColors, colorOption.facet))
      );
  }

  getDefaultSwatchImageURL(colorOptions) {
    if (!isEmpty(colorOptions)) {
      const activeColorOptions = colorOptions[this.state.selectedColorSwatchIndex];
      if (activeColorOptions && (this.props.isPLP || this.props.searchFlow)) {
        return activeColorOptions.main ? activeColorOptions.main : this.props.url;
      }
      return this.props.url;
    }
    return this.props.url;
  }

  getLastWatchImage(colorOptions) {
    if (!isEmpty(colorOptions) && this.state.lastHoveredColorSwatchIndex !== -1) {
      const activeColorOptions = colorOptions[this.state.lastHoveredColorSwatchIndex];
      if (activeColorOptions && (this.props.isPLP || this.props.searchFlow)) {
        return activeColorOptions.main ? activeColorOptions.main : this.props.url;
      }
      return this.props.url;
    }
    return this.props.url;
  }

  getProductImageURL(colorOptions) {
    if (!isEmpty(colorOptions)) {
      const hoveredSwatchIndex = this.state.hoveredColorSwatchIndex;
      const selectedSwatchIndex = this.state.selectedColorSwatchIndex;
      const activeIndex = hoveredSwatchIndex !== -1 ? hoveredSwatchIndex : selectedSwatchIndex;
      const activeColorOptions = colorOptions[activeIndex];
      const isPLPOrSRP = this.props.isPLP || this.props.searchFlow;
      const dynamicMainMediaUrl = activeColorOptions && isPLPOrSRP
        ? activeColorOptions.main
        : get(activeColorOptions, 'media.main.medium.url');
      const dynamicAlternateMediaAUrl = activeColorOptions && isPLPOrSRP
        ? activeColorOptions.alt
        : get(activeColorOptions, 'media.alternate.a.medium.url');
      const dynamicAlternateMediaLUrl = activeColorOptions && isPLPOrSRP
        ? activeColorOptions.alt
        : get(activeColorOptions, 'media.alternate.l.medium.url');
      return dynamicMainMediaUrl
        || dynamicAlternateMediaLUrl
        || dynamicAlternateMediaAUrl
        || this.props.url;
    }
    return this.props.url;
  }

  getProductAltImageURL(colorOptions) {
    const index = this.state.selectedColorSwatchIndex;
    const activeColorOptions = colorOptions ? colorOptions[index] : {};
    const isPLPOrSRP = this.props.isPLP || this.props.searchFlow;
    const dynamicAlternateMediaAUrl = activeColorOptions && isPLPOrSRP
      ? activeColorOptions.alt
      : get(activeColorOptions, 'media.alternate.a.medium.url');
    const dynamicAlternateMediaLUrl = activeColorOptions && isPLPOrSRP
      ? activeColorOptions.alt
      : get(activeColorOptions, 'media.alternate.l.medium.url');
    return dynamicAlternateMediaLUrl || dynamicAlternateMediaAUrl || this.props.altImage;
  }

  getProductAdnlImageURL(colorOptions) {
    const index = this.state.selectedColorSwatchIndex;
    const activeColorOptions = colorOptions ? colorOptions[index] : {};
    const isPLPOrSRP = this.props.isPLP || this.props.searchFlow;
    const adnlImgUrl = activeColorOptions && matchFristAvalibleShot(activeColorOptions.adnlShots, WEB_ADDITIONAL_IMAGES_ALT_SHOTS) || null;
    return adnlImgUrl && isPLPOrSRP ? adnlImgUrl : this.props.adnlImage;
  }

  getValidImageUrl(imageUrl) {
    if (!imageUrl) {
      if (this.props.brand === 'HC') {
        return HC_NO_IMAGE_AVAILABLE;
      }
      return NO_IMAGE_AVAILABLE;
    }
    return imageUrl;
  }

  getImageSource(colorOptionValues, errorMessage) {
    let imageUrl;
    if (this.isDefaultSwatch()) {
      imageUrl = this.getDefaultSwatchImageURL(colorOptionValues);
    } else {
      imageUrl = this.getProductImageURL(colorOptionValues);
    }
    return this.getValidImageUrl(imageUrl, errorMessage);
  }

  getPromotionsWithDogEar(promotions) {
    return find(promotions, (promotion) => promotion.thumbnailOutlineDisplayFlag);
  }

  getStyleForDogEar(promotion) {
    const backgroundColor = promotion.thumbnailOutlineColor;
    return backgroundColor
      ? { backgroundColor: `#${backgroundColor}` }
      : undefined;
  }

  getStyleForFlattenedDogEar() {
    const backgroundColor = this.props.dogEarColor;
    return backgroundColor
      ? { backgroundColor: `#${backgroundColor}` }
      : undefined;
  }

  getDiscount(discount) {
    return discount > 0
      && (
        <div className="product-thumbnail__discount">
          {`As much as ${discount}% off`}
        </div>
      );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const filteredColorSwatchIndex = this.getFilteredColorSwatchIndex(nextProps);
    if (filteredColorSwatchIndex > -1) {
      this.changeSelectedColorSwatchIndex(filteredColorSwatchIndex);
    }
  }

  handleMouseOverForSwatch(swatchIndex) {
    this.setState({
      hoveredColorSwatchIndex: swatchIndex,
      isSwatchHover: true,
    });
  }

  handleMouseOutForSwatch() {
    this.setState({
      hoveredColorSwatchIndex: -1,
      isSwatchHover: false,
      exitingSwatch: true,
      lastHoveredColorSwatchIndex: this.state.hoveredColorSwatchIndex,
    });
  }

  filterPromotions(promotions) {
    return promotions ? partition(promotions, { showPromoPrice: true }) : [[], []];
  }

  handleOnClickForColorSwatch(index, swatchCode) {
    this.hasSwatchBeenSelectedManually = true;
    this.changeSelectedColorSwatchIndex(index);
    this.changeSelectedColorSwatchCode(swatchCode);
  }

  changeSelectedColorSwatchCode(swatchCode) {
    this.setState({ selectedColorSwatchCode: swatchCode });
  }

  changeSelectedColorSwatchIndex(index) {
    this.setState({ selectedColorSwatchIndex: index < 0 ? 0 : index });
  }

  setMainImageFailed() {
    const numOfSwatches = this.props.colorOptions?.length;
    
    if (
      !this.state.mainImageLoaded &&
      this.state.selectedColorSwatchIndex === numOfSwatches - 1
    ) {
      this.setState({ selectFirstImage: true });
      this.changeSelectedColorSwatchIndex(0);

      return;
    }

    this.setState({ mainImageFailed: true });

    if (this.props.isColorFilterSelected) {
      return;
    }

    if (
      !this.state.mainImageLoaded
      && !this.hasSwatchBeenSelectedManually
      && !this.state.selectFirstImage
    ) {
      this.changeSelectedColorSwatchIndex(this.state.selectedColorSwatchIndex + 1);
    }

  }

  setMainImageLoaded() {
    this.setState({ mainImageLoaded: true });
  }

  isDefaultSwatch() {
    return !this.hasSwatchBeenSelectedManually && this.state.hoveredColorSwatchIndex === -1;
  }

  isValidDisplayableSwatch(colorOptions) {
    const options = Object.assign({}, colorOptions);
    options.values = options.values ? partition(options.values, 'displaySkuImg')[0] : [];
    options.values = options.values ? partition(options.values, { url: '' })[1] : [];
    return options.values.length >= 1;
  }

  renderShowMoreColorsAvailableFlag(
    flag,
    mca
  ) {
    if (mca) {
      return (
        <div className='product-thumbnail__flags only-more-color'>
          <div className='product-thumbnail__flags__element more-colors'>
            {flag}
          </div>
        </div>
      );
    }
    return null;
  }

  renderProductFlags(flagText, newProductThumbStyle = false) {
    if (flagText.indexOf('Colors') === -1) {

      if (newProductThumbStyle) {
        return (
          <div className="gift-badge">
              <span>{flagText.toUpperCase()}</span>
          </div>
        );
      } 
        
      return (
        <div className={classnames('product-thumbnail__flags', { 'only-more-color': true })}>
          <div className={classnames('product-thumbnail__flags__element',
            { 'more-colors': true })}
          >
            <span>{flagText.toUpperCase()}</span>
          </div>
        </div>
      );
    }
    return null;
  }

  showViewSimilarButton = () => {
    const { designer } = this.props;
    if(designer){
      return QL_VS_SUPPRESS_DESIGNER_LIST.findIndex((name) => name.toLowerCase() === designer.toLowerCase()) === -1;
    }
    
    return true;
  }

  render() {
    if (this.props.isGroup) {
      for (let i = 0; i < this.props.childProducts.length; i++) {
        if (isEmpty(this.props.childProducts[i].name)
          || isEmpty(this.props.isPLP
            ? this.props.childProducts[i].rprc
            : this.props.childProducts[i].price)
        ) {
          return null;
        }
      }
    } else if (isEmpty(this.props.name)
      || isEmpty(this.props.isPLP ? this.props.retail : this.props.price)
    ) {
      return null;
    }
    const {
      isPLP = false,
      name = '',
      id = '',
      price = {},
      cmosItem = '',
      promotions = [],
      colorOptions = {},
      productUrl,
      focusProductId,
      enableLazyLoading = true,
      page,
      position,
      navPath,
      applicableDiscount,
      onlyXItemsLeftStockLevel,
      quickLookToggle,
      favoriteToggle,
      productMetadata = {},
      productMetadataStatus = {},
      childProducts = [],
      isGroup,
      hasMoreItems = false,
      plpImageSwatchLazyLoadBeta = false,
      plpImageLazyLoadBeta = false,
      moveProductTogglesToList = false,
      isDevicetypeDesktop,
      searchFlow = false,
      hasMoreSwatches = false,
      sizesFiltered,
      retail,
      original,
      adornLabel,
      adornColor,
      promoPrice,
      advertisePromos = [],
      promoColor,
      promoText,
      plpSRPPricingToggle,
      mca = false,
      isUIPRICTest,
      plpSizeToggle,
      isDomestic,
      router,
      cloudinaryDPRToggle,
      newProductThumbStyle,
      brand,
      isMobilePhone,
      isTablet,
      altImageAbTestToggle = false,
      adnlImageToggleEnabled = false,
      showAltAnimation,
      isMandAShotsFeatureToggleEnabled = false,
      plpGroupsRefreshToggle = false,
      groupProductPriceRange = null,
      quickLookVSToggle,
      viewSimilarLink,
      viewSimilarABtest,
      enlargeEveryFifthTile,
      enlargeEveryFifthTileABtest,
      defaultColorName,
      promoPriorityAbTest,
      promoPriorityToggle,
      msid,
      isGWPToggleEnabled = false,
      boutique,
      salePercentageAbTest,
      esgPLPToggle,
      showESGLabel
    } = this.props;
    const designer = unescape(this.props.designer || '');
    const childProductNames = childProducts.map((product) => product.name).join(' ');
    const productName = (isGroup)
      ? stripHtml(unescape(childProductNames)) : stripHtml(unescape(name));
    const colorOptionValues = colorOptions;
    const productDetailsClassName = (isPLP && !isEmpty(this.props.flag) && this.props.flag.indexOf('Color') === -1)
      || (searchFlow && (this.props.flag || this.props.preOrder)) ? 'product-thumbnail__details'
      : 'product-thumbnail__details header-padding';

    const promoPriority = promoPriorityToggle || promoPriorityAbTest;

    const [
      promotionWithPrice,
      promotionWithoutPrice,
    ] = this.filterPromotions(promotions);
    let colorCode = '';
    if (!isEmpty(this.state.selectedColorSwatchCode)) {
      colorCode = this.state.selectedColorSwatchCode;
    } else if (!isEmpty(colorOptionValues[0])) {
      colorCode = colorOptionValues[0].key;
    }
    const childItemIdValue = `NM${cmosItem}_${colorCode}`;
    const errorMessage = `EVENT_TYPE=MISSING_IMAGE CMOS_ITEM=${this.props.cmosItem}`
      + `CMOS_CATALOG_ID=${this.props.cmosCatalogId} PRODUCT_ID=${this.props.id} BRAND_CODE=${BRAND_CODE}`;
    const defaultQuery = {
      childItemId: childItemIdValue,
      page,
      position,
      navpath: navPath,
      ...plpSizeToggle && sizesFiltered !== '[]' && { sizesFiltered },
    };

    const hasColorOptions = Array.isArray(colorOptions) && colorOptions.length > 0;
    let selectedColorName = null;

    if (hasColorOptions) {
      selectedColorName = colorOptions[this.state.selectedColorSwatchIndex].name
    }

    let selectedColorCode = this.state.selectedColorSwatchCode;
    if(hasColorOptions && isEmpty(selectedColorCode)){
      selectedColorCode = colorOptions[0].key;
    }

    let groupChildId;
    let groupChildColorName;
    let focusId = focusProductId;
    if(isGroup){
      if(plpGroupsRefreshToggle){
        groupChildId = groupProductPriceRange?.fchildId;
        groupChildColorName = groupProductPriceRange?.fchildClr;
      } else if(childProducts?.length > 0) {
        groupChildId = childProducts?.[0]?.id;
        groupChildColorName = childProducts?.[0]?.clrName;
      }
      focusId = groupChildId ?? focusProductId; 
    }
    const query = (focusId) ? { ...defaultQuery, focusProductId:focusId } : defaultQuery;
    const renderThumbnail = () => {
      if (this.props.swatchOptimizationToggle === true) {
        return (
          <ImageLazyLoadIntersectionObserver
            position={position}
            src={this.getImageSource(colorOptionValues, errorMessage)}
            altImage={this.getProductAltImageURL(colorOptionValues)}
            alt={trimSpaces(`${designer} ${productName}`)}
            title={trimSpaces(`${cmosItem} ${designer} ${productName}`)}
            errorMessage={errorMessage}
            enableLazyLoading={enableLazyLoading}
            defaultURL={this.props.url}
            quickLookToggle={quickLookToggle}
            displayable={this.props.displayable}
            productId={this.props.id}
            isFavorite={this.props.isFavorite}
            isDisplayAsGroups={this.props.isDisplayAsGroups}
            cmosItem={this.props.cmosItem}
            cmosCatalogId={this.props.cmosCatalogId}
            favoriteToggle={favoriteToggle}
            cloudinaryDPRToggle={cloudinaryDPRToggle}
            isSwatchHover={this.state.isSwatchHover}
            showQLModalWindow={() => {
              onQuickLookButtonClick(
                this.props,
                this.state.selectedColorSwatchCode,
                navPath,
                this.props.isDisplayAsGroups,
              );
            }}
            isDevicetypeDesktop={isDevicetypeDesktop}
            isUIPRICTest={isUIPRICTest}
            setMainImageFailed={this.setMainImageFailed}
            setMainImageLoaded={this.setMainImageLoaded}
            defaultImageToggle={this.props.defaultImageToggle}
            brand={brand}
            selectedColorName={selectedColorName}
            altImageAbTestToggle={altImageAbTestToggle}
            adnlImageToggleEnabled={adnlImageToggleEnabled}
            mainImageLoaded={this.state.mainImageLoaded}
            showAltAnimation={showAltAnimation}
            quickLookVSToggle={quickLookVSToggle}
            designer={designer}
            defaultColorName={defaultColorName}
            selectedColorCode={selectedColorCode}
            showViewSimilarButton={this.showViewSimilarButton}
            groupChildColorName = {groupChildColorName}
            groupChildId = {groupChildId}
            isDomestic={isDomestic}
            adnlImage={this.getProductAdnlImageURL(colorOptionValues)}
          />
        );
      }
      let thumbnailMarkup = (
        <Toggle
          feature="PLP_IMAGE_LAZY_LOAD_BETA"
          fallback={(
            <Image
              src={this.getImageSource(colorOptionValues, errorMessage)}
              lastColorSwatchImage={this.getLastWatchImage(colorOptionValues, errorMessage)}
              altImage={this.getProductAltImageURL(colorOptionValues)}
              alt={trimSpaces(`${designer} ${productName}`)}
              title={trimSpaces(`${cmosItem} ${designer} ${productName}`)}
              errorMessage={errorMessage}
              enableLazyLoading={enableLazyLoading}
              quickLookToggle={quickLookToggle}
              productId={this.props.id}
              isFavorite={this.props.isFavorite}
              isDisplayAsGroups={this.props.isDisplayAsGroups}
              cmosItem={this.props.cmosItem}
              cmosCatalogId={this.props.cmosCatalogId}
              displayable={this.props.displayable}
              cloudinaryDPRToggle={cloudinaryDPRToggle}
              favoriteToggle={favoriteToggle}
              isSwatchHover={this.state.isSwatchHover}
              showQLModalWindow={() => {
                onQuickLookButtonClick(
                  this.props,
                  this.state.selectedColorSwatchCode,
                  navPath,
                  this.props.isDisplayAsGroups,
                );
              }}
              isUIPRICTest={isUIPRICTest}
              quickLookVSToggle={quickLookVSToggle}
              selectedColorName={selectedColorName}
              designer={designer}
              defaultColorName={defaultColorName}
              selectedColorCode={selectedColorCode}
              showViewSimilarButton={this.showViewSimilarButton}
              groupChildColorName = {groupChildColorName}
            groupChildId = {groupChildId}
            />
          )}
        >
          <ImageLazyLoad
            src={this.getImageSource(colorOptionValues, errorMessage)}
            altImage={this.getProductAltImageURL(colorOptionValues)}
            alt={trimSpaces(`${designer} ${productName}`)}
            title={trimSpaces(`${cmosItem} ${designer} ${productName}`)}
            errorMessage={errorMessage}
            enableLazyLoading={enableLazyLoading}
            defaultURL={this.props.url}
            quickLookToggle={quickLookToggle}
            productId={this.props.id}
            isFavorite={this.props.isFavorite}
            isDisplayAsGroups={this.props.isDisplayAsGroups}
            cmosItem={this.props.cmosItem}
            cmosCatalogId={this.props.cmosCatalogId}
            favoriteToggle={favoriteToggle}
            displayable={this.props.displayable}
            isSwatchHover={this.state.isSwatchHover}
            showQLModalWindow={() => {
              onQuickLookButtonClick(
                this.props,
                this.state.selectedColorSwatchCode,
                navPath,
                this.props.isDisplayAsGroups,
              );
            }}
            isUIPRICTest={isUIPRICTest}
            quickLookVSToggle={quickLookVSToggle}
            selectedColorName={selectedColorName}
            designer={designer}
            defaultColorName={defaultColorName}
            selectedColorCode={selectedColorCode}
            showViewSimilarButton={this.showViewSimilarButton}
            groupChildColorName = {groupChildColorName}
            groupChildId = {groupChildId}
          />
        </Toggle>
      );

      if (moveProductTogglesToList) {
        thumbnailMarkup = plpImageLazyLoadBeta
          ? (
            <ImageLazyLoad
              src={this.getImageSource(colorOptionValues, errorMessage)}
              lastColorSwatchImage={this.getLastWatchImage(colorOptionValues, errorMessage)}
              altImage={this.getProductAltImageURL(colorOptionValues)}
              alt={trimSpaces(`${designer} ${productName}`)}
              title={trimSpaces(`${cmosItem} ${designer} ${productName}`)}
              errorMessage={errorMessage}
              enableLazyLoading={enableLazyLoading}
              defaultURL={this.props.url}
              quickLookToggle={quickLookToggle}
              productId={this.props.id}
              isFavorite={this.props.isFavorite}
              isDisplayAsGroups={this.props.isDisplayAsGroups}
              cmosItem={this.props.cmosItem}
              cmosCatalogId={this.props.cmosCatalogId}
              displayable={this.props.displayable}
              isSwatchHover={this.state.isSwatchHover}
              showQLModalWindow={() => {
                onQuickLookButtonClick(
                  this.props,
                  this.state.selectedColorSwatchCode,
                  navPath,
                  this.props.isDisplayAsGroups,
                );
              }}
              quickLookVSToggle={quickLookVSToggle}
              selectedColorName={selectedColorName}
              designer={designer}
              defaultColorName={defaultColorName}
              selectedColorCode={selectedColorCode}
              showViewSimilarButton={this.showViewSimilarButton}
              groupChildColorName = {groupChildColorName}
              groupChildId = {groupChildId}
            />
          )
          : (
            <Image
              src={this.getImageSource(colorOptionValues, errorMessage)}
              lastColorSwatchImage={this.getLastWatchImage(colorOptionValues, errorMessage)}
              altImage={this.getProductAltImageURL(colorOptionValues)}
              alt={trimSpaces(`${designer} ${productName}`)}
              title={trimSpaces(`${cmosItem} ${designer} ${productName}`)}
              errorMessage={errorMessage}
              enableLazyLoading={enableLazyLoading}
              quickLookToggle={quickLookToggle}
              productId={this.props.id}
              isFavorite={this.props.isFavorite}
              isDisplayAsGroups={this.props.isDisplayAsGroups}
              cmosItem={this.props.cmosItem}
              cmosCatalogId={this.props.cmosCatalogId}
              favoriteToggle={favoriteToggle}
              displayable={this.props.displayable}
              isSwatchHover={this.state.isSwatchHover}
              showQLModalWindow={() => {
                onQuickLookButtonClick(
                  this.props,
                  this.state.selectedColorSwatchCode,
                  navPath,
                  this.props.isDisplayAsGroups,
                );
              }}
              isUIPRICTest={isUIPRICTest}
              quickLookVSToggle={quickLookVSToggle}
              selectedColorName={selectedColorName}
              designer={designer}
              defaultColorName={defaultColorName}
              selectedColorCode={selectedColorCode}
              showViewSimilarButton={this.showViewSimilarButton}
              groupChildColorName = {groupChildColorName}
              groupChildId = {groupChildId}
            />
          );
      }

      return thumbnailMarkup;
    };

    function renderProductNameAndPriceAndPromotionsForGroups() {
      return childProducts.map((product, index) => (
        <Fragment key={index + product.id}>
          <ProductNameAndPrice
            name={product.name}
            price={product.price}
            promotions={product.promotions}
            isPLP={isPLP}
            retail={product.rprc}
            original={product.oprc}
            adornLabel={product.adornTxt}
            adornColor={product.adColor}
            plpSRPPricingToggle={plpSRPPricingToggle}
            newProductThumbStyle={newProductThumbStyle}
            promotionalPrice={isPLP
              ? product.pprc : product.price.promotionalPrice}
            promotionComponent={isDomestic && (isUIPRICTest || plpSRPPricingToggle)
              ? () => (
                <Promotions
                  promotions={product.promotions}
                  currencyCode={product.price && product.price.currencyCode}
                  promotionalPrice={isPLP
                    ? product.pprc : product.price.promotionalPrice}
                  isPLP={isPLP}
                  promoColor={product.promoColor || brand == "NM" ? 'C2252D' : 'EF4036' }
                  promoText={product.promoTxt}
                  plpSRPPricingToggle={plpSRPPricingToggle}
                  isUIPRICTest={isUIPRICTest}
                  newProductThumbStyle={newProductThumbStyle}
                  promoPriority={promoPriority}
                  promoInRed={true}
                />
              )
              : null
            }
            isUIPRICTest={isUIPRICTest}
            promoText={product.promoTxt}
            isDomestic={isDomestic}
            salePercentageAbTest={salePercentageAbTest}
            brand={brand}
          />
          {isDomestic && !plpSRPPricingToggle && !isUIPRICTest
            && (
              <Promotions
                promotions={product.promotions}
                currencyCode={product.price && product.price.currencyCode}
                promotionalPrice={isPLP
                  ? product.pprc : product.price.promotionalPrice}
                isPLP={isPLP}
                promoColor={product.promoColor}
                promoText={product.promoTxt}
                isUIPRICTest={isUIPRICTest}
                newProductThumbStyle={newProductThumbStyle}
                promoPriority={promoPriority}
                promoInRed={true}
              />
            )
          }
          {metaChildProd(product)}
        </Fragment>
      ));
    }

    function renderProductNameAndPriceRangeAndPromotionsForGroups() {
      return (
        <Fragment>
          <ProductNameAndPrice
            name={name}
            price={price}
            promotions={promotions}
            isPLP={isPLP}
            retail= {groupProductPriceRange?.rprcMinPrice ? displayGroupProductPriceText(groupProductPriceRange?.rprcMinPrice, groupProductPriceRange?.rprcMaxPrice) : null}
            original={groupProductPriceRange?.oprcMinPrice ? displayGroupProductPriceText(groupProductPriceRange?.oprcMinPrice, groupProductPriceRange?.oprcMaxPrice) : null}
            adornLabel={adornLabel}
            adornColor={adornColor}
            plpSRPPricingToggle={plpSRPPricingToggle}
            newProductThumbStyle={newProductThumbStyle}
            promotionalPrice={isPLP ? 
                groupProductPriceRange?.pprcMinPrice ? displayGroupProductPriceText(groupProductPriceRange?.pprcMinPrice, groupProductPriceRange?.pprcMaxPrice) : null
               : price.promotionalPrice}
            promotionComponent={isDomestic && (isUIPRICTest || plpSRPPricingToggle)
              ? () => (
                <Promotions
                  promotions={promotionWithPrice}
                  currencyCode={price.currencyCode}
                  promotionalPrice={isPLP ?
                    groupProductPriceRange?.pprcMinPrice ? displayGroupProductPriceText(groupProductPriceRange?.pprcMinPrice, groupProductPriceRange?.pprcMaxPrice) : null
                    : price.promotionalPrice}
                  isPLP={isPLP}
                  promoColor={(groupProductPriceRange?.pprcMinPrice ? groupProductPriceRange?.promoColor : promoColor) || brand == "NM" ? 'C2252D' : 'EF4036' }
                  promoText={groupProductPriceRange?.pprcMinPrice ? groupProductPriceRange?.promoTxt: null}
                  plpSRPPricingToggle={plpSRPPricingToggle}
                  isUIPRICTest={isUIPRICTest}
                  newProductThumbStyle={newProductThumbStyle}
                  plpGroupsRefreshToggle={plpGroupsRefreshToggle}
                  promoPriority={promoPriority}
                  promoInRed={true}
                />
              )
              : null
            }
            isUIPRICTest={isUIPRICTest}
            promoText={groupProductPriceRange?.pprcMinPrice ? groupProductPriceRange?.promoTxt: null}
            isDomestic={isDomestic}
            salePercentageAbTest={salePercentageAbTest}
            brand={brand}
          />
          {isDomestic && !plpSRPPricingToggle && !isUIPRICTest
            && (
              <Promotions
                promotions={promotionWithPrice}
                currencyCode={price.currencyCode}
                promotionalPrice={isPLP ?
                  groupProductPriceRange?.pprcMinPrice ? `${groupProductPriceRange?.pprcMinPrice} - ${groupProductPriceRange?.pprcMaxPrice}` : null
                  : price.promotionalPrice}
                isPLP={isPLP}
                promoColor={(groupProductPriceRange?.pprcMinPrice ? groupProductPriceRange?.promoColor : promoColor) || brand == "NM" ? 'C2252D' : 'EF4036' }
                promoText={groupProductPriceRange?.pprcMinPrice ? groupProductPriceRange?.promoTxt: null}
                plpSRPPricingToggle={plpSRPPricingToggle}
                isUIPRICTest={isUIPRICTest}
                newProductThumbStyle={newProductThumbStyle}
                plpGroupsRefreshToggle={plpGroupsRefreshToggle}
                promoPriority={promoPriority}
                promoInRed={true}
              />
            )
          }
          {metaProd()}
        </Fragment>
      );
    }

    function displayGroupProductPriceText(minPrice, maxPrice){
      if(minPrice === maxPrice) return minPrice;
      else return `${minPrice} - ${maxPrice} `
    }

    function renderProductNameAndPriceAndPromotionsForNonGroups() {
      return (
        <Fragment>
          <ProductNameAndPrice
            name={name}
            price={price}
            promotions={promotions}
            isPLP={isPLP}
            retail={retail}
            original={original}
            adornLabel={adornLabel}
            adornColor={adornColor}
            plpSRPPricingToggle={plpSRPPricingToggle}
            newProductThumbStyle={newProductThumbStyle}
            promotionalPrice={isPLP ? promoPrice : price.promotionalPrice}
            promotionComponent={isDomestic && (isUIPRICTest || plpSRPPricingToggle)
              ? () => (
                <Promotions
                  promotions={promotionWithPrice}
                  currencyCode={price.currencyCode}
                  promotionalPrice={isPLP ? promoPrice : price.promotionalPrice}
                  isPLP={isPLP}
                  promoColor={promoColor || brand == "NM" ? 'C2252D' : 'EF4036' }
                  promoText={promoText}
                  plpSRPPricingToggle={plpSRPPricingToggle}
                  isUIPRICTest={isUIPRICTest}
                  newProductThumbStyle={newProductThumbStyle}
                  promoPriority={promoPriority}
                  promoInRed={true}
                />
              )
              : null
            }
            isUIPRICTest={isUIPRICTest}
            promoText={promoText}
            isDomestic={isDomestic}
            salePercentageAbTest={salePercentageAbTest}
            brand={brand}
          />
          {isDomestic && !plpSRPPricingToggle && !isUIPRICTest
            && (
              <Promotions
                promotions={promotionWithPrice}
                currencyCode={price.currencyCode}
                promotionalPrice={isPLP ? promoPrice : price.promotionalPrice}
                isPLP={isPLP}
                promoColor={promoColor}
                promoText={promoText}
                plpSRPPricingToggle={plpSRPPricingToggle}
                isUIPRICTest={isUIPRICTest}
                newProductThumbStyle={newProductThumbStyle}
                promoPriority={promoPriority}
                promoInRed={true}
              />
            )
          }
          {metaProd()}
        </Fragment>
      );
    }

    function renderProductNameAndPriceAndPromotionsToggleComponent() {
      return (
        isGroup
          ? childProducts.map((product, index) => (
            <Fragment key={index + product.id}>
              <ProductNameAndPrice
                name={product.name}
                price={product.price}
                promotions={product.promotions}
                isPLP={isPLP}
                retail={product.rprc}
                original={product.oprc}
                adornLabel={product.adornTxt}
                adornColor={product.adColor}
                plpSRPPricingToggle={plpSRPPricingToggle}
                newProductThumbStyle={newProductThumbStyle}
                promotionalPrice={isPLP
                  ? product.pprc : product.price.promotionalPrice}
                promotionComponent={isDomestic && (isUIPRICTest || plpSRPPricingToggle)
                  ? () => (
                    <Promotions
                      promotions={product.promotions}
                      currencyCode={product.price && product.price.currencyCode}
                      promotionalPrice={isPLP
                        ? product.pprc : product.price.promotionalPrice}
                      isPLP={isPLP}
                      promoColor={product.promoColor}
                      promoText={product.promoTxt}
                      plpSRPPricingToggle={plpSRPPricingToggle}
                      isUIPRICTest={isUIPRICTest}
                      newProductThumbStyle={newProductThumbStyle}
                      promoPriority={promoPriority}
                      promoInRed={true}
                    />
                  )
                  : null
                }
                isUIPRICTest={isUIPRICTest}
                promoText={product.promoTxt}
                isDomestic={isDomestic}
                salePercentageAbTest={salePercentageAbTest}
                brand={brand}
              />
              {isDomestic && !plpSRPPricingToggle && !isUIPRICTest
                && (
                  <Promotions
                    promotions={product.promotions}
                    currencyCode={product.price && product.price.currencyCode}
                    promotionalPrice={isPLP
                      ? product.pprc : product.price.promotionalPrice}
                    isPLP={isPLP}
                    promoColor={product.promoColor}
                    promoText={product.promoTxt}
                    plpSRPPricingToggle={plpSRPPricingToggle}
                    isUIPRICTest={isUIPRICTest}
                    newProductThumbStyle={newProductThumbStyle}
                    promoPriority={promoPriority}
                    promoInRed={true}
                  />
                )
              }
            </Fragment>
          ))
          : (
            <Fragment>
              <ProductNameAndPrice
                name={name}
                price={price}
                promotions={promotions}
                isPLP={isPLP}
                retail={retail}
                original={original}
                adornLabel={adornLabel}
                adornColor={adornColor}
                plpSRPPricingToggle={plpSRPPricingToggle}
                newProductThumbStyle={newProductThumbStyle}
                promotionalPrice={isPLP
                  ? promoPrice : price.promotionalPrice}
                promotionComponent={isDomestic && (isUIPRICTest || plpSRPPricingToggle)
                  ? () => (
                    <Promotions
                      promotions={promotionWithPrice}
                      currencyCode={price.currencyCode}
                      promotionalPrice={isPLP
                        ? promoPrice : price.promotionalPrice}
                      isPLP={isPLP}
                      promoColor={promoColor}
                      promoText={promoText}
                      plpSRPPricingToggle={plpSRPPricingToggle}
                      isUIPRICTest={isUIPRICTest}
                      newProductThumbStyle={newProductThumbStyle}
                      promoPriority={promoPriority}
                      promoInRed={true}
                    />
                  )
                  : null
                }
                isUIPRICTest={isUIPRICTest}
                promoText={promoText}
                isDomestic={isDomestic}
                salePercentageAbTest={salePercentageAbTest}
                brand={brand}
              />
              {isDomestic && !plpSRPPricingToggle && !isUIPRICTest
                && (
                  <Promotions
                    promotions={promotionWithPrice}
                    currencyCode={price.currencyCode}
                    promotionalPrice={isPLP
                      ? promoPrice : price.promotionalPrice}
                    isPLP={isPLP}
                    promoColor={promoColor}
                    promoText={promoText}
                    plpSRPPricingToggle={plpSRPPricingToggle}
                    isUIPRICTest={isUIPRICTest}
                    newProductThumbStyle={newProductThumbStyle}
                    promoPriority={promoPriority}
                    promoInRed={true}
                  />
                )
              }
              {metaProd()}
            </Fragment>
          )
      );
    }

    function renderProductNameAndPriceAndPromotions() {
      if (moveProductTogglesToList) {
        if (isGroup) {
          if(plpGroupsRefreshToggle){
            return renderProductNameAndPriceRangeAndPromotionsForGroups();
          } else {
            return renderProductNameAndPriceAndPromotionsForGroups();
          }
        }
        return renderProductNameAndPriceAndPromotionsForNonGroups();
      }
      return renderProductNameAndPriceAndPromotionsToggleComponent();
    }

    function metaChildProd(product) {
      if (!isEmpty(productMetadata)) {
        const childProductMetadata = find(productMetadata, ['id', product.id]);
        const groupInfo = find(productMetadata, ['id', id]);
        if (childProductMetadata) {
          return (
            <ProductMetadata
              productMetadata={childProductMetadata}
              groupInfo={groupInfo}
              productMetadataStatus={productMetadataStatus}
            />
          );
        }
        return null;
      }
      return null;
    }

    function metaProd() {
      if (!isEmpty(productMetadata)) {
        const productMetadataInfo = find(productMetadata, ['id', id]);
        if (productMetadataInfo) {
          return (
            <ProductMetadata
              productMetadata={productMetadataInfo}
              groupInfo={{}}
              productMetadataStatus={productMetadataStatus}
            />
          );
        }
        return null;
      }
      return null;
    }

    const renderDesignerName = () => {
      if (!isEmpty(designer)) {
        return (
          <h2 className="seo-wrapper designer-name inherit-font-size">
            {designer && <span className={classnames('designer', { 'designer-enhancement': true }, { 'new-style': newProductThumbStyle })}>{designer}</span>}
          </h2>
        );
      }
      return (<div className="empty-designer" />);
    };

    const displaySwatches = () => {
      return (
        <div className={classnames('product-thumbnail__swatches', { 'swatch-enhanced': true }, { 'mobile-swatches': isMobilePhone && hasColorOptions })}>
          {
            this.props.swatchOptimizationToggle === true
              ? (
                <InView triggerOnce>
                  {({ inView, ref }) => (
                    <div ref={ref}>
                      {
                        (inView)
                          ? (
                            <ColorSwatches
                              changeSelectedColorSwatchIndex={this.handleOnClickForColorSwatch}
                              selectedColorSwatchIndex={this.state.selectedColorSwatchIndex}
                              colorOptions={colorOptions}
                              url={productUrl}
                              onMouseOverHandler={this.handleMouseOverForSwatch}
                              onMouseOutHandler={this.handleMouseOutForSwatch}
                              errorMessage={errorMessage}
                              emptyImageUrlHandler={this.getValidImageUrl}
                              plpImageSwatchLazyLoadBeta={plpImageSwatchLazyLoadBeta}
                              moveProductTogglesToList={moveProductTogglesToList}
                              productId={this.props.id}
                              updateSwatchesValue={this.props.updateSwatchesValue}
                              hideSwatchOnErrorToggle={this.props.hideSwatchOnErrorToggle}
                              newProductThumbStyle={newProductThumbStyle}
                              swatchOptimizationToggle={this.props.swatchOptimizationToggle}
                              isPlp={isPLP}
                              searchFlow={searchFlow}
                              onSwatchListEnter={this.onSwatchListEnter}
                              onSwatchListExit={this.onSwatchListExit}
                              hasMoreSwatches={hasMoreSwatches}
                              brand={brand}
                            />
                          )
                          : null
                      }
                    </div>
                  )}
                </InView>
              )
              : (
                <ColorSwatches
                  changeSelectedColorSwatchIndex={this.handleOnClickForColorSwatch}
                  selectedColorSwatchIndex={this.state.selectedColorSwatchIndex}
                  colorOptions={colorOptions}
                  url={productUrl}
                  onMouseOverHandler={this.handleMouseOverForSwatch}
                  onMouseOutHandler={this.handleMouseOutForSwatch}
                  errorMessage={errorMessage}
                  emptyImageUrlHandler={this.getValidImageUrl}
                  plpImageSwatchLazyLoadBeta={plpImageSwatchLazyLoadBeta}
                  moveProductTogglesToList={moveProductTogglesToList}
                  productId={this.props.id}
                  updateSwatchesValue={this.props.updateSwatchesValue}
                  hideSwatchOnErrorToggle={this.props.hideSwatchOnErrorToggle}
                  newProductThumbStyle={newProductThumbStyle}
                  swatchOptimizationToggle={this.props.swatchOptimizationToggle}
                  onSwatchListEnter={this.onSwatchListEnter}
                  onSwatchListExit={this.onSwatchListExit}
                  isPlp={isPLP}
                  searchFlow={searchFlow}
                  hasMoreSwatches={hasMoreSwatches}
                  brand={brand}
                />
              )
          }
        </div>
      );
    };

    const renderGiftBagde = () => {
      return <div className="gift-badge"><span className="gift-badge-flag">{this.props.flag}</span></div>;
    } 
    const renderESGBagde = () => {
      return( <div
        className={classnames(
          "flag-enhancement",
          "esg-bagde"
        )}
      >
      {this.renderProductFlags(
        FASHIONED_FOR_CHANGE,
        newProductThumbStyle
      )}
    </div>);
    } 
    const renderOtherBadges = () => {
      return <div
      className={classnames({
        "flag-enhancement": true,
      })}
    >
      {this.renderProductFlags(
        this.props.flag,
        newProductThumbStyle
      )}
    </div>;
    } 

    const renderProductBadge = () => {
      if(this.props.flag && this.props.flagType){
        if(this.props.flagType === "Gift Badge"){
          return renderGiftBagde();
        } else if(this.props.flagType === "Monogram" && isDomestic && esgPLPToggle && showESGLabel){
          return renderESGBagde();
        }else{
          return renderOtherBadges();
        }
      } else if(isDomestic && esgPLPToggle && showESGLabel){
        return renderESGBagde();
      }
    }
    const lastSelectedColor = router?.location?.query?.lsc;

    const exclusionCategories = ['cat000000_cat5160748', 'cat000000_cat000553'];

    const isExcludedCategory = navPath && typeof navPath === 'string' && exclusionCategories.some(el => navPath.startsWith(el));

    const shouldTileEnlarge =
      (enlargeEveryFifthTile || enlargeEveryFifthTileABtest) &&
      !(isExcludedCategory || boutique) &&
      (isDomestic && brand === "NM") &&
      (position + 1) % 5 === 0;


    return (
      <div
        className={classnames(
          {
            'product-thumbnail': true,
            'grid-33 tablet-grid-33 mobile-grid-50 grid-1600': !shouldTileEnlarge,
            'large-tile grid-33 tablet-grid-33 mobile-grid-100 grid-1600': shouldTileEnlarge, 
            'enhancement': true ,
            'mandashot-whitespaces' : isMandAShotsFeatureToggleEnabled,
          }
        )}
        id={this.props.id}
        data-product-source={this.props.source}
      >
        <ProductLink
          className="product-thumbnail__link"
          to={{
            pathname: productUrl,
            query: isGWPToggleEnabled ? null : { ...query, ...(lastSelectedColor && { colorKey: lastSelectedColor }), ...(msid && { msid }) },
          }}
          colorOptions={colorOptions}
          index={this.state.selectedColorSwatchIndex}
          dispatch={this.props.dispatch}
          isPLP={this.props.isPLP}
          searchFlow={this.props.searchFlow}
          productId={`${this.props.id}-plp`}
        >
          <div
            className={classnames(
              {
                'product-thumbnail2to3__image': isPLP || searchFlow,
                'product-thumbnail2to3__image-slicker': (adnlImageToggleEnabled || altImageAbTestToggle) && !isDevicetypeDesktop && (isPLP || searchFlow)
              }
            )}
          >
            {renderThumbnail()}
            {!this.props.displayable
              && <div className="product-thumbnail__image__soldOut">{SOLD_OUT_TEXT}</div>
            }
          </div>
          {
            !newProductThumbStyle && 
            <div className={classnames({ 'flag-enhancement-frame': true })}>

            {
              this.props.flag && this.props.flagType === 'Gift Badge' && <div className="gift-badge"><span className="gift-badge-flag">{this.props.flag}</span></div>
            }

            {
              this.props.flag
              && this.props.flagType !== 'Gift Badge'
              && (
                <div className={classnames({ 'flag-enhancement': true })}>
                  {this.renderProductFlags(
                    this.props.flag,
                    newProductThumbStyle
                  )}
                </div>
              )
            }
            </div>
          }
          {isDomestic && this.props.isPLP
            ? this.props.dogEarColor !== undefined && (
              <div className="product-thumbnail__dogEar">
                <img
                  src={DOG_EAR_IMAGE}
                  alt="Promotion"
                  style={this.getStyleForFlattenedDogEar()}
                />
              </div>
            )
            : this.getPromotionsWithDogEar(promotions)
            && (
              <div className="product-thumbnail__dogEar">
                <img
                  src={DOG_EAR_IMAGE}
                  alt="Promotion"
                  style={this.getStyleForDogEar(this.getPromotionsWithDogEar(promotions))}
                />
              </div>
            )
          }
          <div className={classnames(productDetailsClassName,
            { 'product-enhancement': true }, 
            { 'new-style': newProductThumbStyle },
            )}
          >
            <div className="product-thumbnail__description">
              {renderDesignerName()}
            </div>
            {renderProductNameAndPriceAndPromotions()}
            {displaySwatches()}
            {hasMoreItems ? <span className="moreItems">+ More Items</span> : ''}
            {!salePercentageAbTest && this.getDiscount(applicableDiscount)}
            {onlyXItemsLeftStockLevel
              && (
                <div className="product-thumbnail__onlyXLeft">
                  {'Only '}
                  {onlyXItemsLeftStockLevel}
                  {newProductThumbStyle ? ' left' : ' Left!'}
                </div>
              )
            }
            {this.renderShowMoreColorsAvailableFlag(
              SHOW_MORE_COLORS_AVAILABLE_TEXT,
              mca
            )}

            {
              newProductThumbStyle &&
              <div className="product-badges">
                <div
                  className={classnames({
                    "flag-enhancement-frame new-style": true,
                  })}
                >
                   { renderProductBadge()}
                </div>
                  
                {isDomestic && (
                    <Promotions
                      promotions={promotionWithoutPrice}
                      isPLP={isPLP}
                      advertisePromos={advertisePromos}
                      isUIPRICTest={isUIPRICTest}
                      newProductThumbStyle={newProductThumbStyle}
                      promoPriority={promoPriority}
                      promoInRed={true}
                    />
                )}
              </div>
            }

            {isDomestic && !newProductThumbStyle && (
              <div className={classnames({ 'adv-promo-enhancement': true })}>
                <Promotions
                  promotions={promotionWithoutPrice}
                  isPLP={isPLP}
                  advertisePromos={advertisePromos}
                  isUIPRICTest={isUIPRICTest}
                  promoPriority={promoPriority}
                  promoInRed={true}
                />
              </div>
            )}
          </div>
        </ProductLink>
        {(viewSimilarLink || viewSimilarABtest) && isDomestic && (isMobilePhone || isTablet) && (
          <QuickLookViewSimilarMobile
            productId={groupChildId ? groupChildId : this.props.id}
            defaultColorName={defaultColorName}
            selectedColorName={groupChildColorName ? groupChildColorName : selectedColorName}
            selectedColorCode={selectedColorCode}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  hideSwatchOnErrorToggle: state.toggles.HIDE_SWATCH_ON_ERROR,
  swatchOptimizationToggle: state.toggles.SWATCH_OPTIMIZATION,
  plpSRPPricingToggle: get(state.toggles, 'PRICING_STYLE_UPDATE', false),
  plpSizeToggle: get(state.toggles, 'PLP_SIZE', false),
  sizesFiltered: get(state, 'productListPage.sizesFiltered', []),
  isDomestic: get(state, 'locale.countryCode', 'US') === 'US',
  cloudinaryDPRToggle: get(state, 'toggles.CLOUDINARY_DPR', false),
  defaultImageToggle: get(state, 'toggles.PLP_USE_DEFAULT_IMAGE', false),
  newProductThumbStyle: get(state, 'toggles.NEW_PLPSRP_GRID', false),
  brand: state.brand_name.env,
  isMobilePhone: get(state, 'device.isMobilePhone', false),
  isTablet: get(state, 'device.isTablet', false),
  isColorFilterSelected: get(state, 'productListPage.selectedFilterOptions["Color"]', []).length > 0,
  altImageAbTestToggle: get(state, `abTestsOpt.${ABTEST_MOBILE_ALT_IMAGES}.variation`, 'a') === 'b',
  esgPLPToggle: get(state.toggles, 'ESG_PLP', false),
  newQLSidebarToggle: get(state, 'toggles.SB_NEW_QUICKLOOK', false),
  isMandAShotsFeatureToggleEnabled: get(state, 'toggles.M_A_PLP_SRP', false),
  plpGroupsRefreshToggle: (get(state.toggles, 'PLP_GROUPS_REFRESH', false) &&  get(state, 'locale.countryCode', 'US') === 'US'),
  quickLookVSToggle: get(state, 'toggles.QL_VS_PLP', false),
  viewSimilarLink: get(state, 'toggles.VS_MOBILE', false),
  viewSimilarABtest: get(state, `abTestsOpt.${ABTEST_VIEW_SIMILAR}.variation`, 'a') === 'b',
  enlargeEveryFifthTile: get(state, 'toggles.ENLARGE_TILES', false),
  enlargeEveryFifthTileABtest: get(state, `abTestsOpt.${ABTEST_ENLARGE_TILES}.variation`, 'a') === 'b',
  promoPriorityAbTest: get(state, `abTestsOpt.${ABTEST_PROMO_PRIORTIZATION}.variation`, 'a') === 'b',
  promoPriorityToggle: get(state, 'toggles.PROMO_PRIORTIZATION', false),
  isGWPToggleEnabled: isGWPFeatureEnabled(state),
  boutique: get(state, 'utag.productListPage.page_type') === 'boutique',
  salePercentageAbTest: get(state, 'toggles.ABTEST_SALE_PERCENTAGE', false) &&
    get(state, `abTestsOpt.${ABTEST_SALE_PERCENTAGE}.variation`, 'a') === 'b',
});

const mapDispatchToProps = ({
  setSelectedColorCode,
  showQLModalWindow,
  updateSwatchesValue,
  clearQRFromUtag: clearQRFromUtagAction,
  openQLProductPanel,
  setPanelTitle
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Product));
