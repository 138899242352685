import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import MobileMiniCart from './MobileMiniCart/MobileMiniCart';
import DesktopTabletMiniCart from './DesktopTabletMiniCart/DesktopTabletMiniCart';
import './miniCart.scss';

const CN = 'miniCartContainer';

const MiniCart = ({ noOfItemsInCart }) => {
  return (
    <div className={classnames(CN, { [`${CN}--empty`]: noOfItemsInCart === 0 })}>
      <DesktopTabletMiniCart />
      <MobileMiniCart />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    noOfItemsInCart: state.miniCart.noOfItemsInCart,
  };
};

export default connect(mapStateToProps)(MiniCart);
