import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Button from 'shared/components/Button/button';
import { addBodyClass } from 'client-utils/bodyClass';
import { openFacetedMobileFilter, toggleCategorySelector } from 'plp/components/ProductListPage/components/FacetedLeftNav/actions';
import { renderSelectedSRP, isGenderNavOn } from 'srp/utils/srpUtils';

export class mobileFacetedFilter extends Component {
  constructor() {
    super();
    this.open = this.open.bind(this);
  }

  open() {
    addBodyClass('pane-open');
    this.props.openFacetedMobileFilter();
    if (this.props.pageId === 'PAGE_ID_PLP') {
      this.props.toggleCategorySelector();
    }
  }

  render() {
    const {
      title, srpSelected, catSelected, genderNavExperience, applicableFilters
    } = this.props;
    const selectedValue = renderSelectedSRP(srpSelected, catSelected, genderNavExperience);
    return (
      <div className="faceted-mobile-option">
        <Button
          className="faceted-mobile-option__button button visual-nav-button"
          value={title}
          onClick={this.open}
          displaySelected={selectedValue}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  pageId: state.page.pageId,
  srpSelected: get(state, 'srp.selectedFilterOptions', {}),
  catSelected: get(state, 'srp.search.facetedFiltersList.Category', []),
  genderNavExperience: isGenderNavOn(state),
});

const mapDispatchToProps = ({
  openFacetedMobileFilter,
  toggleCategorySelector,
});

export default connect(mapStateToProps, mapDispatchToProps)(mobileFacetedFilter);
