import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { dangerousProperty } from 'client-utils/utilities-html-sanitizer';
import { unescape } from 'he';
import IconArrowDown from '../../../../../assets/images/icon-arrow-down.svg';
import classnames from 'classnames';
import './seo.scss';

const Seo = ({ title, content, margin, seoCopyToggle }) => {
  const [isExpanded, setExpanded] = useState(false);
  const [showMoreText, setShowMoreText] = useState(seoCopyToggle);

  const contentRef = useRef();

  useEffect(() => {
    const contentEl = contentRef?.current;
    const hasMoreText = seoCopyToggle && (contentEl && contentEl.clientHeight < contentEl.scrollHeight);
    setShowMoreText(hasMoreText);
  }, [content]);

  const onExpandCollapse = (event) => {
    event.preventDefault();
    setExpanded(!isExpanded);
  }

  return (
    <div className={classnames("seoContentContainer", { "seo-copy-container": seoCopyToggle })} style={{ marginBottom: margin }}>
      <h2 className={classnames("seoTitle", { "seo-copy-title": seoCopyToggle })}>{unescape(title)}</h2>
      <div 
        ref={contentRef}
        className={classnames(
          "seoContent", 
          { "seo-copy-content": seoCopyToggle}, 
          {"seo-content-hidden": (seoCopyToggle & !isExpanded) }
        )} 
        dangerouslySetInnerHTML={dangerousProperty(content, [], { a: ['href'] })} 
      />
      {showMoreText && <div className="seo-copy" onClick={(e) => onExpandCollapse(e)} >
        <h2 className="seo-copy-text">{`See ${isExpanded ? 'Less' : 'More'}`}</h2>
        <img className={`seo-copy-icon ${isExpanded ? "collapse-icon" : "expand-icon"}`} src={IconArrowDown} alt="Seo copy icon" />
      </div>}
    </div>
  );
};

Seo.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  seoCopyToggle: PropTypes.boolean
};

export default Seo;
