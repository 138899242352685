import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

export function isCMSPromoAvailable(cmsEntries) {
  const layouts = get(cmsEntries[0], 'fields.l1Layouts', []);
  const promoLayouts = layouts.filter((layout) => layout.fields?.placement === 'Promo');

  return !isEmpty(promoLayouts);
}

export function isCMSDynamicPromoAvailable(cmsEntries) {
  const layouts = get(cmsEntries[0], 'fields.l1Layouts', []);
  const dynamicPromoLayouts = layouts.filter((layout) => layout.fields?.placement === 'Dynamic Promo');

  return !isEmpty(dynamicPromoLayouts);
}

export function getDynamicPromoTileSpots(cmsEntries) {
  const l1Layouts = get(cmsEntries[0], 'fields.l1Layouts', []);
  const promoLayout = l1Layouts.filter(
    (layout) => layout.fields?.placement === 'Dynamic Promo'
  );
  const tiles = uniq(get(promoLayout?.[0], 'fields.components', []).map(
    (item) => (
      { pos: item.fields?.position - 1, colspan: item.fields?.columnSpan }
    )
  )).sort((a, b) => a.pos - b.pos);

  return tiles.reduce((acc, currentTile, i) => {
    let shift = 0;
    let newPos = currentTile.pos;
    if (i > 0) {
      const prevTile = acc[i - 1];
      shift = (prevTile.colspan + prevTile.shift - 1);
      newPos = currentTile.pos - shift;
    }
    acc.push({ ...currentTile, shift, pos: newPos });
    return acc;
  }, []).map((tile) => tile.pos);
}
