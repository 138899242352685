import React, { PureComponent } from 'react';
import './genderSelect.scss';

// eslint-disable-next-line react/prefer-stateless-function
class GenderSelect extends PureComponent {
  render() {
    const { onGenderSelected, selectedGender } = this.props;
    return (
      <div className="gender-select-container">
        <ul className="gender-select">
          <li
            onClick={() => onGenderSelected('W')}
            className={selectedGender === 'W' ? 'active' : undefined}
          >
            <p>WOMEN</p>
          </li>
          <li
            onClick={() => onGenderSelected('M')}
            className={selectedGender === 'M' ? 'active' : undefined}
          >
            <p>MEN</p>
          </li>
        </ul>
      </div>
    );
  }
}

export default GenderSelect;
