import React, { Fragment } from 'react';
import classnames from 'classnames';
import { ProductListContextConsumer } from '../../../../productListContext';
import './HeaderPosition.scss';

export const HeaderPosition = ({
  children,
  componentName,
  isMobile = false,
  controlClass = '',
  isStickyHeaderActive = false,
  typeAheadExperience = false,
  isSearchExposed = false,
  isCollapsedSearchBarToggle = false,
}) => {
  if (isMobile) {
    return (
      <Fragment>
        <ProductListContextConsumer>
          {({ position, scrollType }) => {
            const stickyFilters = typeAheadExperience
              ? 'sticky-filter-sort-scroll-typeahead'
              : (isStickyHeaderActive && isSearchExposed && !isCollapsedSearchBarToggle) ? 'sticky-search-exposed' : 'sticky-filter-sort-scroll';
            let positionalChange = false;
            if (componentName) {
              if (position < -80) {
                positionalChange = true;
              }
            }
            return (
              <div
                className={
                  classnames({ [`${controlClass}`]: positionalChange && componentName,
                    [`${stickyFilters}`]: scrollType === 'up',
                    'sticky-header-not-active': (!isStickyHeaderActive && positionalChange),
                })
                }
              >
                {children}
              </div>
            );
          }}
        </ProductListContextConsumer>
      </Fragment>
    );
  }
  return children;
};
