import classnames from 'classnames';
import React from 'react';
import './radio.scss';

const Radio = ({
  label,
  value,
  selected = false,
  disabled = false,
  onClick,
}) => (
  <label
    className={classnames('radio', { 'radio--disabled': disabled })}
    htmlFor={`radio__${value}`}
    onClick={() => (disabled ? null : onClick(value))}
  >
    <input
      id={`radio__${value}`}
      className="radio__input"
      type="radio"
      value={value}
      defaultChecked={selected}
      disabled={disabled}
    />
    <span className={classnames('radio__element', { 'radio__element--selected': selected })} />
    {label && <span className="radio__label">{label}</span>}
  </label>
);

export default Radio;
