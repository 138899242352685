import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { getRefreshableHtmlContent } from 'storefront/components/RefreshableContent/refreshableContent-actions';

class LeftNavRefreshableContent extends Component {
  constructor() {
    super();
    this.state = {
      domReady: false,
    };
  }

  componentDidMount() {
    document.addEventListener('readystatechange', () => {
      if (document.readyState === 'complete') {
        this.setState({ domReady: true });
        this.props.fetchLeftNavRefreshableContent(this.props.refreshableContentSrc, 'leftNavRefreshableContent');
      }
    });
  }

  render() {
    const leftNavRefreshableContent = this.props.leftNavRefreshableContent;
    if (isEmpty(leftNavRefreshableContent)) {
      return false;
    }
    const refreshableContent = this.state.domReady ? leftNavRefreshableContent : '';
    return (
      <li>
        <div
          id="leftNav-refreshableContent"
          dangerouslySetInnerHTML={{ __html: refreshableContent }}
        />
      </li>
    );
  }
}

const mapStateToProps = (state) => ({
  leftNavRefreshableContent:
              state.refreshableContent.refreshableHtmlContent.leftNavRefreshableContent,
});

const mapDispatchToProps = ({
  fetchLeftNavRefreshableContent: getRefreshableHtmlContent,
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftNavRefreshableContent);
