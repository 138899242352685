export const TOP_ITEMS = 'TOP_ITEMS';
export const SEARCH_ITEMS = 'SEARCH_ITEMS';
export const CATEGORY_ITEMS = 'CATEGORY_ITEMS';

export const topItemsData = {
  previous_view_search_submit: 'true',
  search_function: 'product_search',
  previous_view_spr_submit: 'true',
  search_type_ahead: [undefined],
  facet_selection: [undefined],
  facet_type: [undefined],
};
export const searchData = {
  previous_view_search_submit: 'true',
  search_function: [undefined],
  search_type_ahead: 'true',
  facet_selection: [undefined],
  facet_type: [undefined],
};
export const categoryData = {
  previous_view_search_submit: 'true',
  search_function: [undefined],
  search_type_ahead: 'true',
};
