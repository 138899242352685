import { ENTER_KEYCODE, SPACE_KEYCODE } from 'client-utils/keyCodes';

export function scrollToTop(e) {
  e.preventDefault();
  window.scrollTo(0, 0);
  window.dispatchEvent(new window.CustomEvent('scrollToTop'));
  const skipToMainContentElement = document.getElementById('skipToMainContent');

  if (e.which === ENTER_KEYCODE || e.which === SPACE_KEYCODE) {
    if (skipToMainContentElement) {
      skipToMainContentElement.focus();
    }
  } else if (e.type === 'click') {
    const contentContainerElement = document.getElementById('contentContainer');
    if (contentContainerElement) {
      contentContainerElement.focus();
    }
  }
}
