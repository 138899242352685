import React from "react";
import "./collapsibleSearchBar.scss";

const CollapsibleSearchBar = ({ brand }) => {
  return (
    <div className="collapsible-searchbar-container desktop-tablet-only">
      <input
        type="button"
        name="search bar icon"
        title="search bar icon"
        aria-label="collapsible-search-bar"
        className="collapsible-searchbar-icon"
      />
      <input
        type="text"
        title="collapsible search bar"
        autoComplete="off"
        id={brand === "HC" ? "elSearchInput" : "brSearchInput"}
        name="collapsible search bar"
        className="collapsible-searchbar"
        aria-label="collapsible-search-bar"
        placeholder={"Search"}
      />
    </div>
  );
};

export default CollapsibleSearchBar;
