import React, { Fragment } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import "./quickLookViewSimilarMobile.scss";
import { openViewSimilarProductPanel, setPanelTitle, VIEW_SIMILAR } from "client/cms/actions/index";
import ArrowRight from 'assets/images/arrow-right.svg';
import { addBodyClass } from "client/utilities/bodyClass";

const QuickLookViewSimilarMobile = ({
  productId,
  selectedColorName,
  setPanelTitle,
  openViewSimilarProductPanel,
  defaultColorName,
  selectedColorCode
}) => {
  const productData = {
    productId,
    selectedColorName: selectedColorName || defaultColorName
  };

  const openPanel = (productData) => {
    addBodyClass("vs-panel-open");
    openViewSimilarProductPanel(productData, selectedColorCode);
    setPanelTitle(VIEW_SIMILAR);
  };

  return (
    <Fragment>
      <div
        className={classnames(
          "viewsimilar-link"
        )}
        onClick={() => {
          openPanel(productData);
        }}
      >
        View Similar
        <img
            className={`arrow-right`}
            src={ArrowRight}
          />
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = {
  openViewSimilarProductPanel,
  setPanelTitle
};

export default connect(
  null,
  mapDispatchToProps
)(QuickLookViewSimilarMobile);
