import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import throttle from 'lodash/throttle';
import classNames from 'classnames';
import window from 'window-or-global';
import SkipToContent from 'storefront/components/SkipToContent/skipToContent';
import Toggle from 'shared/components/Toggle/toggle';
import Navigation from 'storefront/components/Navigation/navigation';
import HeaderController from '../HeaderController/headerController';
import { addFacetsMargin, removeFacetsMargin } from 'plp/components/ProductListPage/components/FacetedLeftNav/actions';
import { isGlobalNavUpdateToggleEnabled } from 'cms/actions/index';
import './HeaderWrapper.scss';

const CN = 'header-wrapper';

export class ConnectedHeaderWrapper extends Component {
  constructor(props) {
    super(props);
    const { isHeaderSticky, isMobilePhone } = props;

    this.isStickyHeaderOn = isHeaderSticky && !isMobilePhone;
    this.state = {
      prevScrollPosition: window.pageYOffset,
      visible: true,
      isTop: true,
    };
  }

  componentDidMount() {
    this.isStickyHeaderOn && window.addEventListener('scroll', throttle(this.handleScroll, 300));
  }

  componentWillUnmount() {
    this.isStickyHeaderOn && window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { prevScrollPosition } = this.state;
    const { facetsMargin } = this.props;

    const currentScrollPosition = window.pageYOffset;
    const visible = prevScrollPosition > currentScrollPosition;

    if (visible) {
      if (!facetsMargin) {
        this.props.addFacetsMargin()
      }
    }
    if (!visible) {
      if (facetsMargin) {
        this.props.removeFacetsMargin()
      }
    }

    this.setState({
      prevScrollPosition: currentScrollPosition,
      visible,
      isTop: currentScrollPosition === 0,
    });
  };

  render() {
    const { visible, isTop } = this.state;
    const {
      isDarkMode,
      tickerComponent,
      ecmTickerRef,
      isMobilePhone,
      router,
      isHeaderSticky,
      selectedGender,
      isGlobalUpdateOn
    } = this.props;

    return (
      <>
        {
          !isMobilePhone && (!isHeaderSticky || (isHeaderSticky && isTop)) && tickerComponent
        }
        <div className={
          classNames(CN, {
            'dark-mode': isDarkMode,
            [`${CN}--hidden`]: this.isStickyHeaderOn && !visible && !isGlobalUpdateOn,
            [`${CN}--sticky`]: this.isStickyHeaderOn && !isGlobalUpdateOn,
            [`${CN}--shadowed`]: this.isStickyHeaderOn && visible && !isTop,
            [`${CN}--scrollableHeader`]: isGlobalUpdateOn,
          })}
        >
          <div className="grid-container large grid-parent">
            <HeaderController
              ecmTickerRef={ecmTickerRef}
              tickerComponent={tickerComponent}
              selectedGender={selectedGender}
            >
              {isMobilePhone && <SkipToContent />}
            </HeaderController>

            <Toggle
              feature="HIDE_MAIN_NAV"
              fallback={<Navigation router={router} />}
            >
              {false}
            </Toggle>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobilePhone: get(state, 'device.isMobilePhone', false),
    isHeaderSticky: get(state, 'toggles.STICKY_HEADER_DESKTOP', false),
    facetsMargin: get(state, 'facetedLeftNav.facetsMargin', false),
    isGlobalUpdateOn: isGlobalNavUpdateToggleEnabled(state),
  };
};

const mapDispatchToProps = ({
  addFacetsMargin,
  removeFacetsMargin,
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedHeaderWrapper);
