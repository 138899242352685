import React, { Component } from 'react';
import classnames from 'classnames';
import './dropDown.scss';

class DropDown extends Component {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.props.onChange(event.target.value);
  }

  render() {
    let options;

    if (typeof this.props.options[0] === 'object') {
      options = this.props.options.map(
        (option) => {
          const [key] = Object.keys(option);
          return <option value={key} key={key} aria-label={`${this.props.featureName} ${option[key]} selected`}>{option[key]}</option>;
        }
      );
    } else {
      options = this.props.options.map(
        (option, key) => <option value={option} key={key}>{option}</option>
      );
    }

    if (typeof this.props.defaultOption !== 'undefined') {
      options.unshift(
        <option key="DEFAULT_VALUE" value={this.props.defaultValue}>
          {this.props.defaultOption}
        </option>
      );
    }

    return (
      <select
        id={this.props.id || ''}
        className={classnames('dropdown', this.props.className)}
        onChange={this.handleChange}
        value={this.props.selectedValue}
        aria-label={this.props['aria-label'] || 'Dropdown'}
        aria-describedby={this.props['aria-describedby'] || 'Select an option from the dropdown'}
      >
        {options}
      </select>
    );
  }
}

export default DropDown;
