import React, { Component } from 'react';
import $ from 'jquery';
import get from 'lodash/get';
import AlphaIndexItem from './AlphaIndexItem';
import './AlphaIndexSelector.scss';

class AlphaIndexSelector extends Component {
  constructor() {
    super();
    this.state = {
      selectedAlphabet: '',
    };

    this.alphaIndexClickHandler = this.alphaIndexClickHandler.bind(this);
  }

  alphaIndexClickHandler(e) {
    const scrollId = e.target.textContent === '#' ? 'NUMBER' : e.target.textContent;
    if (get(e, 'key', undefined) !== undefined || e.type === 'click') {
      $(`#${scrollId} h2`).focus();
    }
    this.setState({ selectedAlphabet: e.target.textContent });

    const selectedSection = document.getElementById(scrollId);
    const alphaIndexSelectorHeight = document.getElementsByClassName('alpha-index')[0]
      .getBoundingClientRect().height;
    let scrollToPos = $('.alpha-index').css('--custom-width-identifier-property') === undefined
      ? selectedSection.getBoundingClientRect().top - alphaIndexSelectorHeight - 10
      : selectedSection.getBoundingClientRect().top - (alphaIndexSelectorHeight - 300);
    const scrolled = window.pageYOffset > 0;

    if (!scrolled) {
      window.scrollTo(0, scrollToPos);
    } else {
      scrollToPos = $('.alpha-index').css('--custom-width-identifier-property') === undefined
        ? selectedSection.getBoundingClientRect().top + (window.pageYOffset - 85)
        : selectedSection.getBoundingClientRect().top + (window.pageYOffset - 10);
      window.scrollTo(0, scrollToPos);
    }
  }

  render() {
    return (
      <ul role="menubar" className="alpha-container">
        { this.props.designersByIndex.map((item, idx) => {
          return item.categories.length > 0
            ? (
              <AlphaIndexItem
                key={idx}
                keyName={item.name}
                active
                selected={item.name === this.state.selectedAlphabet}
                clickHandler={this.alphaIndexClickHandler}
              />
            )
            : (
              <AlphaIndexItem
                key={idx}
                keyName={item.name}
                active={false}
                selected={false}
              />
            );
        })
        }
      </ul>
    );
  }
}

export default AlphaIndexSelector;
