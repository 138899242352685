import React, { Component } from 'react';
import { connect } from 'react-redux';
import LazyLoad from 'react-lazy-load';
import NO_IMAGE_AVAILABLE from 'assets/images/no-image.jpeg';
import HC_NO_IMAGE_AVAILABLE from 'hcAssets/images/hc-no-image.jpeg';
import get from 'lodash/get';
import './image.scss';
import QuickLookButton from '../QuickLookButton/quickLookButton';
import FavoriteButton from '../Favorite/favoriteButton';
import CrossFadeImage from './Animations/transitionImages';

const createImagePreloader = (src, onError) => () => {
  const image = new window.Image();
  image.onerror = onError;
  image.src = src;
  return image;
};

const activeImages = {
  MAIN_IMAGE: 'MAIN_IMAGE',
  ALT_IMAGE: 'ALT_IMAGE',
};

const defaultState = {
  activeImage: activeImages.MAIN_IMAGE,
  mainImageLoadFailed: false,
  altImageLoadFailed: false,
  isHover: false,
};

class Image extends Component {
  constructor(props) {
    super();
    this.state = defaultState;
    this.showAltImage = this.showAltImage.bind(this);
    this.showMainImage = this.showMainImage.bind(this);
    this.imageErrorHandler = this.imageErrorHandler.bind(this);

    const createPreloader = props.createPreloader || createImagePreloader;
    this.preload = createPreloader(props.src, this.imageErrorHandler);
  }

  componentDidMount() {
    IS_CLIENT && !this.props.enableLazyLoading && this.preload();
  }

  getActiveImageSrc() {
    const { activeImage, mainImageLoadFailed } = this.state;
    const { src: mainImage, altImage, brand } = this.props;
    if (activeImage === activeImages.ALT_IMAGE) {
      return altImage;
    }
    if (activeImage === activeImages.MAIN_IMAGE && mainImageLoadFailed) {
      if (brand === 'HC') {
        return this.props.defaultURL || HC_NO_IMAGE_AVAILABLE;
      }
      return this.props.defaultURL || NO_IMAGE_AVAILABLE;
    }
    return mainImage;
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.src !== nextProps.src) {
      this.setState(defaultState);
    }
  }

  imageErrorHandler() {
    if (this.state.activeImage === activeImages.MAIN_IMAGE) {
      this.setState({
        mainImageLoadFailed: true,
      });
    } else {
      this.setState({
        activeImage: activeImages.MAIN_IMAGE,
        altImageLoadFailed: true,
      });
    }
  }

  showMainImage() {
    this.setState({ activeImage: activeImages.MAIN_IMAGE });
  }

  showAltImage() {
    if (this.state.altImageLoadFailed) return;

    this.setState({ activeImage: activeImages.ALT_IMAGE });
  }

  isHover() {
    this.setState({ isHover: true });
  }

  isNotHover() {
    this.setState({ isHover: false });
  }

  renderCtaButton = () => {
    const { quickLookVSToggle, showViewSimilarButton } = this.props;

    if(quickLookVSToggle && showViewSimilarButton() && this.props.isDomestic){
      return this.renderQuickLookViewSimilarButton();
    } 

    return this.renderQuickLookButton();
  }

  renderQuickLookButton = () => {
    const { quickLookToggle, quickLookVSToggle, showQLModalWindow } = this.props;

    if(!quickLookVSToggle && quickLookToggle){
      return (
      <QuickLookButton
        showQLModalWindow={showQLModalWindow}
        isHover={this.state.isHover}
      />
      )
    }

    return;
  }

  renderQuickLookViewSimilarButton = () => {
    const { quickLookVSToggle, selectedColorName, showQLModalWindow, productId, defaultColorName, selectedColorCode } = this.props;

    if(quickLookVSToggle){
      return (<QuickLookViewSimilarButton 
        showQLModalWindow={showQLModalWindow}
        isHover={this.state.isHover}
        selectedColorName={selectedColorName}
        productId={productId}
        defaultColorName={defaultColorName}
        selectedColorCode={selectedColorCode}
      />)
    } 
    return;
  }

  render() {
    const {
      alt,
      title,
      altImage,
      enableLazyLoading = false,
      favoriteToggle = false,
      isSwatchHover,
      isUIPRICTest,
    } = this.props;
    const src = this.getActiveImageSrc();
    const favoritesQuickLookNewImageLayout = (
      <div
        className="main-image-container"
        onMouseEnter={() => {
          if (altImage) {
            this.showAltImage();
          }
          this.isHover();
        }}
        onMouseLeave={() => {
          if (altImage) {
            this.showMainImage();
          }
          this.isNotHover();
        }}
        onFocus={() => {
          this.isHover();
        }}
        onBlur={() => {
          this.isNotHover();
        }}
      >

        <CrossFadeImage
          alt={alt}
          altImage={altImage}
          altImageLoadFailed={this.state.altImageLoadFailed}
          isHover={this.state.isHover}
          imageErrorHandler={this.imageErrorHandler}
          transitionToImage={src}
          isSwatchHover={isSwatchHover}
          mainImageLoadFailed={this.state.mainImageLoadFailed}
          src={src}
          title={title}
        />

        {!isUIPRICTest && favoriteToggle && this.props.displayable
          && (
          <FavoriteButton
            productId={this.props.productId}
            isFavorite={this.props.isFavorite}
            isDisplayAsGroups={this.props.isDisplayAsGroups}
            cmosItem={this.props.cmosItem}
            cmosCatalogId={this.props.cmosCatalogId}
          />
          )
        }
        {this.renderCtaButton()}
      </div>
    );


    return (
      enableLazyLoading
        ? (
          <LazyLoad debounce={false} throttle={0} offset={500}>
            { favoritesQuickLookNewImageLayout }
          </LazyLoad>
        )
        : favoritesQuickLookNewImageLayout
    );
  }
}

function mapStateToProps(state) {
  return {
    isDomLoadComplete: get(state, 'page.isDomLoadComplete', false),
    brand: state.brand_name.env,
    isDomestic: get(state, 'locale.countryCode', 'US') === 'US',
  };
}

export default connect(mapStateToProps)(Image);
export const UnconnectedImage = Image;
