import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import { shouldLoad } from 'universal/http-client';
import { parseUrlId } from 'client/utilities/utilities-url';
import { getParams } from 'client-utils/utilities-router';
import { setPageId, PAGE_ID_CAT_TMPL } from 'shared/actions/actions-page';
import ScrollButton from 'shared/components/ScrollButton/scrollButton';
import {
  fetchCategoryResultsPageMainContent,
} from 'shared/actions/actions-crp';
import { getTemplateTypeDetails } from './dux/templateActions';
import ProductListPage from '../plp/components/ProductListPage/productListPage';
import SC3 from './SC/SC';
import F from './F/F';
import Entry from './Entry/Entry';
import Silo from './Silo/Silo';
import DesignerIndex from './DesignerIndex/DesignerIndex';
import ConnectedMagazineIndex from './MagazineIndex/MagazineIndex';

export class CategoryRoutingStrategy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: '',
    };
    this.renderTemplate = this.renderTemplate.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.setPageId(PAGE_ID_CAT_TMPL);
    const { routeParams = {} } = this.props;
    const categoryId = routeParams.categoryInfo
      ? parseUrlId(routeParams.categoryInfo)
      : get(routeParams, 'categoryId', '').split('_').slice(0, 1).toString();
    const { navpath = 'noNavPath' } = getParams(this.props.location);
    const { facet } = getParams(this.props.location);
    const { page } = getParams(this.props.location);
    if (this.props.categoryTemplatesToggle) {
      if (shouldLoad(this.props.api) && !this.props.isCRPOn) {
        this.props.getTemplateTypeDetails(categoryId, this.props.countryCode, navpath, facet, page);
      }
    } else {
      this.setState({
        renderFlag: 'disableFeature',
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const isSinglePageOn = nextProps.isSinglePageOn;
    if (isSinglePageOn) {
      const { location: prevLocation = {}, router = {} } = this.props;
      const prevCategoryInfo = this.props.routeParams.categoryInfo;
      const nextCategoryInfo = nextProps.routeParams.categoryInfo;
      const isSPA = get(router, 'location.state.spa', false);
      if (this.fallback) {
        this.fallback = false;
        return;
      }
      if (!isEqual(prevCategoryInfo, nextCategoryInfo) && isSPA) {
        const catIdAndNavpath = nextCategoryInfo.split('?');
        const catId = last(get(catIdAndNavpath, '[0]', '').split('-'));
        const navPath = last(get(catIdAndNavpath, '[1]', '').split('='));
        this.props.fetchCategoryResultsPageMainContent(catId, navPath)
          .catch(() => {
            this.fallback = true;
            router.replace(prevLocation);
          });
      }
    }
  }

  renderTemplate() {
    const { routeParams = {} } = this.props;
    const categoryId = routeParams.categoryInfo
      ? parseUrlId(routeParams.categoryInfo)
      : get(routeParams, 'categoryId', '').split('_').slice(0, 1).toString();
    const renderEcmInTemplate = !isEmpty(get(this.props.templateDetails, 'templateAttributes.ecmContent', {}));
    const renderCmsInTemplate = get(this.props.templateDetails, 'templateAttributes.cmsContent[0]', {});
    if (this.state.renderFlag === 'disableFeature') {
      return <ProductListPage {...this.props} />;
    }
    switch (this.props.templateType) {
      case 'P':
        return <ProductListPage {...this.props} />;
      case 'SC':
        return <SC3 {...this.props} />;
      case 'ChanelSC':
        return <SC3 {...this.props} />;
      case 'emag':
        return (
          <F
            categoryId={categoryId}
            renderEcmInTemplate={renderEcmInTemplate}
            renderCmsInTemplate={renderCmsInTemplate}
          />
        );
      case 'F':
        return (
          <F
            categoryId={categoryId}
            renderEcmInTemplate={renderEcmInTemplate}
            renderCmsInTemplate={renderCmsInTemplate}
            aemAbTestUsergroup={this.props.aemAbTestUsergroup}
            personalize={this.props.personalize}
          />
        );
      case 'MagazineIndex': {
        const adjustedProps = { ...this.props, categoryId };
        return (
          <ConnectedMagazineIndex
            {...adjustedProps}
          />
        );
      }
      case 'Entry': {
        const adjustedProps = { ...this.props, renderCmsInTemplate };
        return <Entry {...adjustedProps} />;
      }
      case 'ChanelEntry':
        const adjustedProps = { ...this.props, renderCmsInTemplate };
        return <Entry {...adjustedProps} />;
      case 'Silo': {
        const adjustedProps = { ...this.props, renderCmsInTemplate };
        return <Silo {...adjustedProps} />;
      }
      case 'DesignerIndex': {
        const adjustedProps = { ...this.props, renderCmsInTemplate };
        return <DesignerIndex {...adjustedProps} />;
      }
      default:
        return (
          <h1>
            {'Template type: '}
            {this.props.templateType}
          </h1>
        );
    }
  }

  render() {
    return (
      <Fragment>
        {this.renderTemplate()}
        {this.props.templateType !== 'P' && <ScrollButton isMobilePhone={this.props.isMobilePhone} />}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api.template_type,
    aemAbTestUsergroup: state.abTests.aemAbTestUsergroup,
    personalize: state.session.personalize && state.session.dt_personalize_preview,
    templateType: state.templates.templateType,
    categoryTemplatesToggle: state.toggles.CATEGORY_TEMPLATE,
    countryCode: state.locale.countryCode,
    templateDetails: state.templates.templateDetails,
    isCRPOn: state.toggles.CRP,
    boutiqueChild: get(state, 'templates.templateDetails.boutiqueChild', false),
    designerName: get(state, 'navigation.breadcrumbs[1].name', ''),
    isSinglePageOn: get(state, 'toggles.SINGLE_PAGE_APP_CATEGORY', false),
    isMobilePhone: get(state, 'device.isMobilePhone', false),
  };
};

const mapDispatchToProps = {
  getTemplateTypeDetails,
  setPageId,
  fetchCategoryResultsPageMainContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryRoutingStrategy);
