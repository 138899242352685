import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { shouldLoad } from 'universal/http-client';
import { getParams, keysToExclude, removeDuplicateNavPath } from 'client-utils/utilities-router';
import setConnectCookie from 'client-utils/utilities-connect-app';
import { parseUrlId } from 'client/utilities/utilities-url';
import {
  fetchCategoryResultsPageContent,
  fetchCategoryResultsPageMainContent,
} from 'shared/actions/actions-crp';
import { getRequestOptions } from 'plp/components/ProductListPage/productListPage';
import { setPageId, setSeoParams, setSizesFiltered, PAGE_ID_CAT_TMPL } from 'shared/actions/actions-page';
import CategoryApp from './app-category';

export class DumbCategoryAppWrapper extends Component {
  UNSAFE_componentWillMount() {
    const { routeParams = {}, setSizesFiltered, plpSizeToggle, location, seoFacets, isSeoToggleOn, setSeoParams } = this.props;
    const categoryId = routeParams.categoryInfo
      ? parseUrlId(routeParams.categoryInfo)
      : get(routeParams, 'categoryId', '').split('_').slice(0, 1).toString();
    let { navpath, facet, sizesFiltered, lsc } = getParams(location);

     navpath = removeDuplicateNavPath(navpath);

    if (plpSizeToggle && sizesFiltered) {
      setSizesFiltered(sizesFiltered);
    }

    if (isSeoToggleOn && seoFacets.length > 0) {
      let firstParam = {}
      let secondParam = {}
      const firstSeoFacetFormatted = seoFacets?.[0]?.split(' ').join('-').replace(/'/g, "");
      const secondSeoFacetFormatted = seoFacets?.[1]?.split(' ').join('-').replace(/'/g, "");
      
      const isFirstParamValid = typeof location.query[firstSeoFacetFormatted] === 'string';
      const isSecondParamValid = typeof location.query[secondSeoFacetFormatted] === 'string';

      const setFirstParam = isFirstParamValid && location.query[firstSeoFacetFormatted].split('|').length === 1;
      const setSecondParam = isSecondParamValid && location.query[secondSeoFacetFormatted].split('|').length === 1;

      if (setFirstParam) {
        firstParam[firstSeoFacetFormatted] = location.query[firstSeoFacetFormatted];
      }

      if (setSecondParam) {
        secondParam[secondSeoFacetFormatted] = location.query[secondSeoFacetFormatted];
      }
      
      if (
        (isFirstParamValid &&
          location.query[firstSeoFacetFormatted].split("|").length > 1) ||
        (isSecondParamValid &&
          location.query[secondSeoFacetFormatted].split("|").length > 1)
      ) {
        firstParam = {};
        secondParam = {};
      }
      const keysToExcludeExpanded = [...keysToExclude, 'get-it-fast'];
      
      const nonSeoFacets = Object.keys(location.query)
        .filter(key => !keysToExcludeExpanded.includes(key))
        .filter(
          key =>
            ![firstSeoFacetFormatted, secondSeoFacetFormatted].includes(key)
        );
      
      if (nonSeoFacets.length > 0) {
        firstParam = {};
        secondParam = {};
      }
      
      setSeoParams(firstParam, secondParam);
    }
    
    if (shouldLoad(this.props.crpApi)) {
      this.props.setPageId(PAGE_ID_CAT_TMPL);
      if (this.props.brand === 'NM') {
        setConnectCookie(location?.query);
      }
      this.props.fetchCategoryResultsPageContent(
        categoryId, navpath, getRequestOptions(this.props), facet, lsc
      );
    }
  }

  render() {
    const isAPICalled = (apiInput = {}) => apiInput.resolved || apiInput.rejected;
    return (
      <Fragment>
        {isAPICalled(this.props.crpApi)
          ? <CategoryApp {...this.props} />
          : null
        }
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  templateTypeApi: state.api.template_type,
  templateType: state.templates.templateType,
  countryCode: state.locale.countryCode,
  crpApi: state.api.crp,
  isCRPOn: state.toggles.CRP,
  plpSizeToggle: get(state.toggles, 'PLP_SIZE', false),
  seoFacets: get(state, 'facetedLeftNav.seoFacets', []),
  isSeoToggleOn: get(state, 'toggles.SEO_BUCKET_ONE', false),
  brand: state.brand_name?.env,
});

const mapDispatchToProps = {
  fetchCategoryResultsPageContent,
  fetchCategoryResultsPageMainContent,
  setPageId,
  setSeoParams,
  setSizesFiltered,
};

export default connect(mapStateToProps, mapDispatchToProps)(DumbCategoryAppWrapper);
