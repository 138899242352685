import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import get from 'lodash/get';
import { shouldLoad } from 'universal/http-client';
import { isTypeAheadOn } from 'srp/utils/srpUtils';
import { getGenderWithOverride } from 'client-utils/utilities-gender';
import NewMobileNav from './NewMobileNav/NewMobileNav';
import ConnectedNavBar from './NavBar/navBar';
import './navigation.scss';
import { getSilosDesktop, getSilosMobileInitial, fetchSilosForNewMobileNav } from './actions-navigation';

const Navigation = ({
  apiNavigation,
  apiNavigationMobile,
  fetchNav,
  sliderMenuVisible,
  fetchSilosForNewMobileNav,
  router,
  isMobile,
  enableDynamicNavigation,
  hpGenderToggle,
  srpGenderToggle,
  pageId,
  typeAheadExperience,
  isMenDarkModeToggle,
  selectedGender,
  horchow,
  globalNavigationUpdate,
  isDomestic,
}) => {
  if (enableDynamicNavigation) {
    if (!isMobile && shouldLoad(apiNavigation)) {
      fetchNav();
    }

    if (isMobile && shouldLoad(apiNavigationMobile)) {
      fetchSilosForNewMobileNav();
    }
  } else {
    if (shouldLoad(apiNavigation)) {
      fetchNav();
    }

    if (isMobile && shouldLoad(apiNavigationMobile)) {
      fetchSilosForNewMobileNav();
    }
  }
  const shouldShowGenderNav = () => {
    let genderToggle = false;
    const srpPage = 'PAGE_ID_SRP';
    if (pageId === srpPage) {
      genderToggle = srpGenderToggle;
    } else {
      genderToggle = hpGenderToggle;
    }
    return genderToggle;
  };
  const hideLineOnMobile = typeAheadExperience ? 'hide-on-mobile' : '';
  const horizontalLine = <hr className={`hr-class ${hideLineOnMobile}`} />;
  const connectedNavBar = <ConnectedNavBar router={router} />;
  const componentsOrder = shouldShowGenderNav()
    ? [connectedNavBar, horizontalLine] : [horizontalLine, connectedNavBar];
  const isMenGender = selectedGender === 'M';

  return (
    <div
      className={classNames(
        'navigation',
        {
          'margin-bottom': shouldShowGenderNav(),
          'men-nav': isMenGender,
          'dark-mode': (isMenDarkModeToggle && hpGenderToggle && isMenGender)
            || (isMenDarkModeToggle && isMenGender),
          'global-nav-update' : isDomestic && Boolean(globalNavigationUpdate)
        }
      )}
      id={`${isMobile ? 'mobilenav' : null}`}
    >
      {
        componentsOrder.map((Component) => (
          Component))
      }
      {sliderMenuVisible
        ? ReactDOM.createPortal(<NewMobileNav router={router} horchow={horchow} />, document.getElementById('navSliderContainer'))
        : false}
    </div>
  );
};

const mapStateToProps = (state) => {
  const isDomestic = get(state, 'locale.countryCode') === 'US';
  return {
    apiNavigation: state.api.navigation,
    apiNavigationMobile: state.api.navigation_mobile,
    sliderMenuVisible: state.navigation.navSlider.sliderMenuVisible,
    isMobile: state.device.isMobile,
    enableDynamicNavigation: state.toggles.ACN_DYNAMIC_NAVIGATION,
    hpGenderToggle: get(state, 'toggles.HP_GENDER', false) && isDomestic,
    srpGenderToggle: get(state, 'toggles.SRP_GENDER', false) && isDomestic,
    pageId: state.page.pageId,
    typeAheadExperience: isTypeAheadOn(state),
    isMenDarkModeToggle: get(state, 'toggles.MEN_DARKMODE', false),
    selectedGender: getGenderWithOverride(state),
    horchow: get(state, 'toggles.HORCHOW', false),
    globalNavigationUpdate : get(state, 'toggles.GLOBAL_NAV_UPDATE', false ) && isDomestic,
    isDomestic, 
  };
};

const mapDispatchToProps = ({
  fetchNav: getSilosDesktop,
  fetchNavMobile: getSilosMobileInitial,
  fetchSilosForNewMobileNav,
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
