import React, { Component } from 'react';
import { connect } from 'react-redux';
import favIcon from 'assets/images/favorite.svg';
import favIconActive from 'assets/images/favorite-active.svg';
import favIconHover from 'assets/images/favorites_over.svg';
import { isTouchDevice } from 'client-utils/utilities-page';
import { shouldLoad } from 'universal/http-client';
import {
  addFavoriteCategory,
  removeFavoriteCategory,
  getCategoryFavoriteStatus,
} from './actions-leftnavigation';
import './favoriteCategoryButton.scss';

class FavoriteCategoryButton extends Component {
  constructor(props) {
    super();
    this.state = {
      icon: props.isFavorite ? favIconActive : favIcon,
    };
    this.mouseOver = this.mouseOver.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
    this.toggleFavorite = this.toggleFavorite.bind(this);
    this.loadEvent = this.loadEvent.bind(this);
  }

  componentDidMount() {
    document.addEventListener('readystatechange', this.loadEvent);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isFavorite !== nextProps.isFavorite) {
      let icon;
      if (nextProps.isFavorite) {
        icon = favIconActive;
      } else {
        icon = favIcon;
      }
      this.setState({ icon });
    }
  }

  loadEvent() {
    if (document.readyState === 'complete') {
      if (shouldLoad(this.props.apiFavorite)) {
        this.props.dispatch(getCategoryFavoriteStatus(this.props.id));
      }
    }
  }

  mouseOver() {
    if (!this.props.isFavorite && !isTouchDevice()) {
      this.setState({ icon: favIconHover });
    }
  }

  mouseOut() {
    if (!this.props.isFavorite && !isTouchDevice()) {
      this.setState({ icon: favIcon });
    }
  }

  toggleFavorite() {
    const srAlert = document.getElementById('screenReaderAlert');
    if (!this.props.isFavorite) {
      this.props.dispatch(
        addFavoriteCategory(false, this.props.id, this.props.name)
      ).then(() => {
        srAlert.textContent = 'Added to favorites';
      });
    } else {
      this.props.dispatch(
        removeFavoriteCategory(false, this.props.id, this.props.name)
      ).then(() => {
        srAlert.textContent = 'Removed from favorites';
      });
    }
  }

  render() {
    return (
      <div className="favorite-category-container">
        <button
          aria-label={this.props.isFavorite ? 'Remove from favorites' : 'Add to favorites'}
          className="favorite-category-button"
          onClick={this.toggleFavorite}
          onMouseOut={this.mouseOut}
          onMouseOver={this.mouseOver}
        >
          <img className="favorite-category-icon" alt="Favorite-category" src={this.state.icon} />
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  apiFavorite: state.api.get_favorite_category_status,
  isFavorite: state.leftNavigation.isFavorite,
});

export default connect(mapStateToProps)(FavoriteCategoryButton);
