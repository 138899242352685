import React from 'react';
import classNames from 'classnames';
import ECMGet from 'ecm/components/EcmGet';
import { ECMSlots } from 'ecm/constants';
import './F.scss';
import { renderCMSContent, cmsContentExists } from '../../utilities/utilities-cms';

const F = ({
  categoryId,
  renderEcmInTemplate,
  renderCmsInTemplate,
  aemAbTestUsergroup = '',
  personalize = false,
}) => {
  const ecmClassName = classNames({
    'grid-100': true,
    'mt-20': renderEcmInTemplate,
  });
  const ecmSlot = aemAbTestUsergroup || personalize ? ECMSlots.PLP_BANNER_F0 : null;
  const ecmContent = (
    renderEcmInTemplate
    && (
    <ECMGet
      ecmSlot={ecmSlot}
      catId={categoryId}
    />
    )
  );

  const renderContent = cmsContentExists(renderCmsInTemplate) ? renderCMSContent(renderCmsInTemplate, 'Main') : ecmContent;
  return (
    <div className={ecmClassName}>
      { renderContent }
    </div>
  );
};

export default F;
