import React from 'react';
import './column.scss';

const CreateCategory = (columnIndex, l1Category, getSinglePage = {}, closeFunction) => {
  const l1redText = l1Category.attributes && l1Category.attributes.redTextFlag ? 'red-text' : '';
  const l1AnchorProps = l1redText ? { className: l1redText } : {};

  const listOfSubCategories = l1Category.categories
    && l1Category.categories.map((l2Category, index) => {
      const l2redText = (l2Category.attributes && l2Category.attributes.redTextFlag)
        ? 'red-text'
        : '';
      const l2AnchorProps = l2redText ? { className: l2redText } : {};
      const { id } = l2Category;
      if (getSinglePage.isSinglePageOn && getSinglePage.templateType
          && !getSinglePage.blackListedCategories[id]) {
        return (
          <li
            key={index}
            onClick={
              () => {
                closeFunction();
                getSinglePage.router.push({
                  pathname: l2Category.url,
                  state: { spa: true },
                });
              }
            }
          >
            {l2Category.name}
            {
              (l2Category.attributes && l2Category.attributes.boutiqueTextAdornments)
                ? (
                  <span className="boutique-text-adornments">
                    {l2Category.attributes.boutiqueTextAdornments}
                  </span>
                )
                : false
            }
          </li>
        );
      }
      return (
        <li key={index}>
          <a href={l2Category.url} data-title={l2Category.name} {...l2AnchorProps}>
            {l2Category.name}
            {
              (l2Category.attributes && l2Category.attributes.boutiqueTextAdornments)
                ? (
                  <span className="boutique-text-adornments">
                    {l2Category.attributes.boutiqueTextAdornments}
                  </span>
                )
                : false
            }
          </a>
        </li>
      );
    });

  return (
    <div key={columnIndex}>
      <h6 className="menu-title">  
        <a href={l1Category.url} data-title={l1Category.name} {...l1AnchorProps}>
          {l1Category.name}
          {
            (l1Category.attributes && l1Category.attributes.boutiqueTextAdornments)
              ? (
                <span className="boutique-text-adornments">
                  {l1Category.attributes.boutiqueTextAdornments}
                </span>
              )
              : false
          }
        </a>
      </h6>
      {
        (l1Category.categories && l1Category.categories.length > 0)
          ? (
            <ul>
              {listOfSubCategories}
            </ul>
          )
          : false
      }
    </div>
  );
};

const Column = ({ key, categories }, getSinglePage, closeFunction) => {
  const listOfCategories = categories.map((l1Category, columnIndex) => (
    CreateCategory(columnIndex, l1Category, getSinglePage, closeFunction)
  ));

  return (
    <div key={key} className="silo-column">
      <div className="menu-wrapper">
        {listOfCategories}
      </div>
    </div>
  );
};

export default Column;
