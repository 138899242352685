import React, { Component, createRef } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import values from 'lodash/values';
import pullAt from 'lodash/pullAt';
import { SearchSuggestions } from 'srp/components/atoms/SearchSuggestions/SearchSuggestions';
import closeSVG from 'assets/images/icon-close.svg';
import { isTypeAheadOn, formatTopItems } from 'srp/utils/srpUtils';
import { updateTopProducts, setGenderTA } from 'srp/actions/actions';
import { setTypeaheadUtag, setGender } from 'client/common/components/App/app-actions';
import { WOMEN } from 'client/common/constants';
import { getGenderWithOverride } from 'client-utils/utilities-gender';
import { updateSearchTermResult } from 'clientHorchow/components/SearchBox/search-actions';
import Link from 'client/components/lib/Link/index';
import GenderSelectTA from './GenderSelectTA/genderSelectTA';
import { saveToLocalStorage } from 'client-utils/utilities-storage';
import {
  DOWN_KEY, UP_KEY, ESC_KEY, TAB_KEY, getStorageKey, INVALID_REGEX_PATTERN
} from '../../utils/HeaderUtils';
import {
  updateSearchTerm,
  setSearchTermValueFromPlaceholder,
  setPlaceholderValue,
  setPlaceHolderValueToEmpty,
  setRecentlySearchedAnalytics,
  genderToggleAnalyticsReset,
  loadTypeaheadUIConfig,
} from '../search-actions';
import {
  getPlaceholderValue,
  getSearchTerm,
  typeaheadSuggestionPayload,
  getIsTypeaheadSuggestionEmpty,
  getTypeaheadUIConf,
} from '../search-reducers';
import { searchFormSubmit, genderSelectHandler } from '../submitHelper';
import './newSearchBox.scss';
import '../bloomreach.scss';

export class DumbSearchBox extends Component {
  constructor(props) {
    super(props);
    const {
      dtGender,
      isGenderTypeahead,
      typeaheadUIConf,
      typeAheadExperience,
    } = props;
    const isGenderTypeaheadAvailable = isGenderTypeahead
      && typeAheadExperience
      && !isEmpty(typeaheadUIConf.women) && !isEmpty(typeaheadUIConf.men);
    const genderUIConfig = dtGender === WOMEN ? typeaheadUIConf.women : typeaheadUIConf.men;
    const selectedUIConfig = isGenderTypeaheadAvailable ? genderUIConfig : typeaheadUIConf;

    this.state = {
      isSuggestionsShown: false,
      cursorIndex: -1,
      genderSelectHover: false,
      selectedGender: dtGender,
      typeaheadUIConf: selectedUIConfig,
      topProducts: {},
      isGenderTypeaheadAvailable,
      typeaheadSuggestions: {},
      isSuggestionSelected: false,
      suggestions: [],
      isRemoveButtonShown: false
    };

    this.suggestionsRef = createRef();
    this.onInputFocus = this.onInputFocus.bind(this);
    this.closeSuggestions = this.closeSuggestions.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleSelectionOnRecentSearch = this.handleSelectionOnRecentSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSearchTermChange = this.onSearchTermChange.bind(this);
    this.handleAnalytics = this.handleAnalytics.bind(this);
    this.handleCloseIconClick = this.handleCloseIconClick.bind(this);
    this.changeGender = this.changeGender.bind(this);
    this.getTypeaheadSuggestions = this.getTypeaheadSuggestions.bind(this);
    this.toggleSearchInputStyle = this.toggleSearchInputStyle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.inputRef = null;
    this.wrapperRef = createRef();
    this.searchInput = createRef();

    this.setSearchIcon = (element) => {
      this.searchIcon = element;
    };
    this.setinputRef = (element) => {
      this.inputRef = element;
      this.inputRef.focus();
    };
    this.setFocusAtEnd = (searchIcon) => {
      searchIcon.target.disabled = true;
      if (this.inputRef) {
        const searchTerm = this.inputRef.value;
        this.inputRef.value = '';
        this.inputRef.value = searchTerm;
        this.inputRef.focus();
      }
    };
    this.handleReset = () => {
      if (this.inputRef) this.inputRef.focus();
    };
  }

  componentDidMount() {
    const {
      placeholder,
      setRecentlySearchedAnalytics,
      isMobilePhone,
      routing,
      pageId,
      isGenderTypeahead,
      typeAheadExperience,
      taGender,
      topProducts,
    } = this.props;
    const { isGenderTypeaheadAvailable } = this.state;
    const storageKey = getStorageKey({ isGenderTypeahead, typeAheadExperience, taGender });

    try {
      if (!isMobilePhone) {
        const query = routing.query && routing.query.filterOptions
          ? JSON.parse(routing.query.filterOptions)
          : null;
        const queryExists = query ? values(query).some((val) => { return val.length > 0; }) : false;
        const recentSearches = typeof (localStorage) !== 'undefined'
          ? (JSON.parse(localStorage.getItem(storageKey)) || [])
          : false;
        if (recentSearches && recentSearches.length >= 7) {
          recentSearches.splice(7, recentSearches.length);
        }
        if (placeholder !== '' && placeholder !== null) {
          if (recentSearches) {
            const repeatedSearchValue = recentSearches.indexOf(placeholder);
            if (repeatedSearchValue > -1 && pageId.indexOf('SRP') !== -1) {
              if (
                window.utag_data_dt
                && !(window.utag_data_dt.recently_searched !== undefined)
                && !queryExists
              ) {
                if (window.sessionStorage.getItem('recently_searched') === 'true') {
                  setRecentlySearchedAnalytics(true);
                  window.sessionStorage.removeItem('recently_searched');
                }
              } else if (routing.query.recent !== 'true') {
                setRecentlySearchedAnalytics(false);
              }
              pullAt(recentSearches, repeatedSearchValue);
            } else {
              setRecentlySearchedAnalytics(false);
            }
            recentSearches.unshift(placeholder);
          }
        }
        typeof (localStorage) !== 'undefined'
        && saveToLocalStorage(storageKey, JSON.stringify(recentSearches));
      }
    } catch (e) {
      localStorage.removeItem(storageKey);
    }
    document.addEventListener('mousedown', this.handleClickOutside);
    window.addEventListener('beforeunload', this.handleAnalytics);

    this.setState({
      topProducts: isGenderTypeaheadAvailable ? this.getGenderTopProducts() : topProducts,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedGender, isSuggestionsShown } = this.state;
    const { searchTerm } = this.props;
    if (this.props.typeaheadSuggestionPayload !== prevProps.typeaheadSuggestionPayload) {
      this.onSearchTermChange(selectedGender);
    }
    // Populates initial BR results if there's a previous term
    if (!prevState.isSuggestionsShown && isSuggestionsShown && searchTerm.length > 0) {
      if (typeof window !== 'undefined' && window.$ && window.BRAutosuggest) {
        window.BRAutosuggest.search(searchTerm);
      }
    }
    if (prevProps.typeaheadPayload !== this.props.typeaheadPayload) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        suggestions: this.props.typeaheadPayload,
        isSuggestionSelected: true,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    window.removeEventListener('beforeunload', this.handleAnalytics);
  }

  onInputFocus(e) {
    e.preventDefault();

    this.props.setPlaceHolderValueToEmpty();

    const { handleOnFocus } = this.props;
    handleOnFocus(e.target.placeholder, e.target.value);
    this.setState((prevState) => {
      return {
        ...prevState,
        isSuggestionsShown: true,
        isRemoveButtonShown: true
      };
    });
  }

  onSearchTermChange(selectedGender) {
    const { isGenderTypeahead, typeaheadSuggestionPayload } = this.props;
    this.setState({
      typeaheadSuggestions: isGenderTypeahead
        ? this.getTypeaheadSuggestions(selectedGender)
        : { ...typeaheadSuggestionPayload },
    });
  }

  onSubmit(e) {
    const {
      isGenderTypeahead, typeAheadExperience, searchTerm, dtGender, setGender,
    } = this.props;
    const { selectedGender } = this.state;
    const isGenderTypeaheadOn = isGenderTypeahead && typeAheadExperience;

    searchFormSubmit(e, searchTerm);
    genderSelectHandler(isGenderTypeaheadOn, dtGender, selectedGender, setGender);
  }

  getGenderTopProducts() {
    const {
      topProducts,
      typeaheadUIConf: {
        women: { topProducts: womenTopProductsIds },
        men: { topProducts: menTopProductsIds },
      },
    } = this.props;
    const womenTopProducts = [];
    const menTopProducts = [];

    topProducts.forEach((product) => {
      if (womenTopProductsIds?.includes(product.id)) womenTopProducts.push(product);
      if (menTopProductsIds?.includes(product.id)) menTopProducts.push(product);
    });

    return { women: womenTopProducts, men: menTopProducts };
  }

  getTypeaheadSuggestions(gender) {
    const { typeaheadSuggestionPayload } = this.props;
    const { women: womenSuggestions, men: menSuggestions } = typeaheadSuggestionPayload;
    return Object.assign(typeaheadSuggestionPayload, gender !== 'M' ? womenSuggestions : menSuggestions);
  }

  toggleSearchInputStyle(event) {
    if (this.searchInput && this.searchInput.current.contains(event.target)
      && !event.target.classList.contains('icon-close')) {
      this.searchInput.current.classList.add('extended');
      this.searchIcon.disabled = false;
    } else {
      this.searchInput.current.classList.remove('extended');
    }
  }

  handleClickOutside(event) {
    const { dtGender } = this.props;

    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ isSuggestionsShown: false, isRemoveButtonShown: false });
      this.changeGender(dtGender);
    }

    this.toggleSearchInputStyle(event);
  }

  UNSAFE_componentWillMount() {
    const {
      loadTypeaheadUIConfig,
      typeAheadExperience,
      isGenderTypeahead,
      dtGender,
      topProducts,
      updateTopProducts,
    } = this.props;
    if (!IS_CLIENT && global.typeaheadDataObj) {
      const { topProducts: commonTopProducts = [], women, men } = global.typeaheadDataObj;

      loadTypeaheadUIConfig();

      if (typeAheadExperience) {
        if (isEmpty(topProducts)) {
          const topProductsConfig = women?.topProducts && men?.topProducts && isGenderTypeahead
            && typeAheadExperience && dtGender
            ? [...women.topProducts, ...men.topProducts]
            : commonTopProducts;

          if (!isEmpty(topProductsConfig)) {
            const docsArray = topProductsConfig.map((id) => {
              return { pId: id };
            });
            const body = {
              docs: docsArray,
              toggles: {
                USE_CLOUDINARY_IMAGES: true,
              },
            };
            const cacheKey = topProductsConfig.map((id) => id).toString();
            updateTopProducts(body, `${cacheKey}`);
          }
        }
      }
    }
  }

  handleSelectionOnRecentSearch(value) {
    const { handleOnChange, setRecentlySearchedAnalytics } = this.props;
    const thisContext = this;
    if (value) {
      setRecentlySearchedAnalytics(true);
      handleOnChange(value);
    } else {
      this.setState((prevState) => {
        return {
          ...prevState,
          isSuggestionsShown: false,
          cursorIndex: 0,
        };
      });
    }
    setTimeout(() => {
      if (thisContext.submitRef) {
        thisContext.submitRef.click();
      }
    }, 200);
  }

  handleChange(e) {
    const {
      setPlaceholderValue,
      searchSuggestionsToggle = true,
      searchTerm,
      isGenderTypeahead,
      typeAheadExperience,
      taGender,
    } = this.props;
    const { cursorIndex, isSuggestionsShown } = this.state;
    const storageKey = getStorageKey({ isGenderTypeahead, typeAheadExperience, taGender });
    const limit = typeof (localStorage) !== 'undefined'
      ? JSON.parse(localStorage.getItem(storageKey))
      : [];
    if (e.keyCode === UP_KEY && cursorIndex > 0 && searchSuggestionsToggle) {
      if (document
        .getElementsByClassName('brm-autosuggest-menu').length > 0
        && searchSuggestionsToggle
        && isSuggestionsShown
        && searchTerm === '') {
        document
          .getElementsByClassName('brm-autosuggest-menu')[0]
          .getElementsByTagName('ul')[0].innerHTML = '';
      }
      this.setState((prevState) => ({
        cursorIndex: prevState.cursorIndex - 1,
      }));
      setPlaceholderValue(limit[cursorIndex - 1]);
    } else if (e.keyCode === DOWN_KEY && limit !== null) {

      if (cursorIndex < limit.length - 1) {
        if (document
          .getElementsByClassName('brm-autosuggest-menu').length > 0
          && searchSuggestionsToggle
          && isSuggestionsShown
          && searchTerm === '') {
          document
            .getElementsByClassName('brm-autosuggest-menu')[0]
            .getElementsByTagName('ul')[0].innerHTML = '';
        }
        this.setState((prevState) => ({
          cursorIndex: prevState.cursorIndex + 1,
        }));
        setPlaceholderValue(limit[cursorIndex + 1]);
      }

      if (cursorIndex === limit.length - 1) {
        this.setState(() => ({
          cursorIndex: 0,
        }));
      }
      
    } else if (e.keyCode === TAB_KEY) {
      this.closeSuggestions();
    } else if (e.keyCode === ESC_KEY) {
      this.closeSuggestions();
      this.inputRef.blur();
    }
  }

  closeSuggestions() {
    this.setState({ isSuggestionsShown: true });
  }

  handleAnalytics() {
    const { isGenderTypeahead, typeAheadExperience, taGender, brand, } = this.props;
    const storageKey = getStorageKey({ isGenderTypeahead, typeAheadExperience, taGender });
    if (brand !== 'HC' && this.searchEnterTypeRef.value !== 'bloomreach') {
      const { setRecentlySearchedAnalytics } = this.props;
      const recentSearches = localStorage
        ? JSON.parse(localStorage.getItem(storageKey))
        : [];
      if (recentSearches.includes(this.inputRef.placeholder)) {
        window.sessionStorage.setItem('recently_searched', true);
      } else {
        setRecentlySearchedAnalytics(false);
      }
    }
  }

  handleCloseIconClick(event) {
    const { dtGender } = this.props;

    this.props.handleOnChange('');
    this.setState({
      isSuggestionsShown: false,
      isRemoveButtonShown: false
    });

    this.changeGender(dtGender);
    this.toggleSearchInputStyle(event);
  }

  changeGender(gender, withAnalytics = false) {
    const genderKey = gender === WOMEN ? 'women' : 'men';
    const { typeaheadUIConf, searchTerm, isGenderTypeahead } = this.props;

    this.setState({
      selectedGender: gender,
      typeaheadUIConf: typeaheadUIConf[genderKey] || typeaheadUIConf,
      typeaheadSuggestions: (searchTerm && isGenderTypeahead)
        ? this.getTypeaheadSuggestions(gender)
        : typeaheadSuggestionPayload,
    });

    window.sessionStorage.setItem('ta_gender', gender);
    this.props.setGenderTA(gender, withAnalytics);
  }

  handleTextChange(value) {
    const {
      handleOnChange, brand, getSearchSuggestions, searchURL,
    } = this.props;
    if (brand === 'HC' && value !== '') {
      getSearchSuggestions(value, searchURL);
    }
    handleOnChange(value);
  }

  searchSuggestion(suggestion, searchTerm) {
    // Use the replace method to remove invalid characters
    if(searchTerm){
      searchTerm = searchTerm.replace(INVALID_REGEX_PATTERN, '');
    }
    const parts = suggestion.split(new RegExp(`(${searchTerm})`, 'gi'));
    return <span>{parts.map((part) => (part.toLowerCase() === searchTerm.toLowerCase() ? <span className="match">{part}</span> : part))}</span>;
  }

  render() {
    const {
      searchTerm,
      placeholder = 'Search',
      isTypeaheadSuggestionEmpty,
      topProducts,
      isGenderTypeahead,
      typeAheadExperience,
      taGender,
      dtGender,
      setGender,
      isDomestic,
      brand,
    } = this.props;
    const {
      isSuggestionsShown,
      cursorIndex,
      selectedGender,
      topProducts: { women, men },
      isGenderTypeaheadAvailable,
      typeaheadUIConf,
      typeaheadSuggestions,
      isSuggestionSelected,
      suggestions,
    } = this.state;
    const isGenderTypeaheadOn = isDomestic && typeAheadExperience && isGenderTypeahead;
    const storageKey = getStorageKey({ isGenderTypeahead, typeAheadExperience, taGender });
    const genderTopProducts = selectedGender === WOMEN ? women : men;
    const selectedTopProducts = isGenderTypeaheadAvailable ? genderTopProducts : topProducts;


    const suggestionsList = brand === 'HC' && suggestions.length > 0 && searchTerm !== '' ? (
      suggestions.map((suggestion, index) => {
        return (
          <li key={index}>
            <Link
              aria-label={`Shop more: ${suggestion}`}
              target="_self"
              to={{
                pathname: '/s/',
                query: {
                  from: 'elSearch',
                  responsive: 'true',
                  request_type: 'search',
                  search_type: 'keyword',
                  q: `${suggestion}`,
                  l: `${suggestion}`,
                },
              }}
            >
              {
                this.searchSuggestion(suggestion, searchTerm)
              }
            </Link>
          </li>
        );
      })
    ) : (<li />);
    return (
      <div className="header-search-box-container desktop-tablet-only">
        <form
          className="search-bar"
          action="/search.jsp"
          onSubmit={(e) => this.onSubmit(e)}
          method="GET"
          ref={this.wrapperRef}
        >
          <div ref={this.searchInput} className={classNames('search-input', { 'search-input--shifted': isGenderTypeaheadOn })}>
            <input type="hidden" title="from-input" name="from" value="brSearch" />
            <input type="hidden" title="l-input" name="l" value={searchTerm} />
            <input
              type="button"
              ref={(input) => input && this.setSearchIcon(input)}
              name="search-icon"
              title="search icon"
              aria-label="Get Results"
              className="search-bar__search-icon"
              onClick={(e) => { this.setFocusAtEnd(e); }}
            />
            <input
              ref={(inputRef) => { this.inputRef = inputRef; }}
              type="text"
              title="search box"
              autoComplete="off"
              id={brand === 'HC' ? 'elSearchInput' : 'brSearchInput'}
              name="q"
              className="search-bar__text"
              value={searchTerm}
              aria-label="Search Box"
              placeholder={placeholder}
              onFocus={this.onInputFocus}
              onKeyDown={this.handleChange}
              onChange={(e) => this.handleTextChange(e.target.value)}
            />
            <input
              ref={(searchEnterType) => { this.searchEnterTypeRef = searchEnterType; }}
              type="hidden"
              id="searchEnterType"
              value="bloomreach"
            />
            {
              brand !== 'HC' && isDomestic && isGenderTypeaheadOn && isSuggestionsShown
              && (
                <GenderSelectTA
                  onGenderSelected={(gender) => {
                    genderToggleAnalyticsReset();
                    this.changeGender(gender, true);
                    this.inputRef && this.inputRef.focus();
                  }}
                  selectedGender={selectedGender}
                  isTypeAhead={typeAheadExperience}
                />
              )
            }
            <div className="make-relative br-search-static" />
            { isSuggestionsShown
               && (
                 <div className="recent-search-renderer">
                   <SearchSuggestions
                     closeRecentSearchOnClear={this.closeSuggestions}
                     handleSelection={this.handleSelectionOnRecentSearch}
                     arrowIndex={cursorIndex}
                     forwardRef={this.suggestionsRef}
                     typeaheadData={typeaheadUIConf}
                     typeaheadSuggestionPayload={typeaheadSuggestions}
                     noResults={isTypeaheadSuggestionEmpty}
                     searchTerm={searchTerm}
                     topItems={
                       !isEmpty(selectedTopProducts)
                         ? formatTopItems(selectedTopProducts)
                         : []
                     }
                     setTypeaheadUtag={this.props.setTypeaheadUtag}
                     storageKey={storageKey}
                     onListClick={() => genderSelectHandler(
                       isGenderTypeaheadAvailable, dtGender, selectedGender, setGender
                     )}
                     brand={brand}
                   />
                 </div>
               )
            }
            { this.state.isRemoveButtonShown && <img
              className="icon-close"
              alt="Close Navigation Menu"
              src={closeSVG}
              onClick={(e) => this.handleCloseIconClick(e)}
            />
            }
            {isSuggestionSelected
            && brand === 'HC'
              ? (
                <div className="make-relative br-search-static">
                  {isSuggestionSelected ? (
                    <div className="autosuggest-wrap">
                      <ul className="ui-autocomplete">
                        {suggestionsList}
                      </ul>
                    </div>
                  ) : ''}
                </div>
              )
              : ''}
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const dtGender = getGenderWithOverride(state);

  return {
    searchTerm: getSearchTerm(state),
    typeaheadSuggestionPayload: typeaheadSuggestionPayload(state),
    isTypeaheadSuggestionEmpty: getIsTypeaheadSuggestionEmpty(state),
    placeholder: getPlaceholderValue(state),
    routing: get(state, 'routing.locationBeforeTransitions', {}),
    isMobilePhone: get(state, 'device.isMobilePhone', false),
    pageId: get(state, 'page.pageId', ''),
    typeAheadExperience: isTypeAheadOn(state),
    typeaheadUIConf: getTypeaheadUIConf(state),
    topProducts: get(state, 'srp.search.topProducts', []),
    isGenderTypeahead: get(state, 'toggles.GENDER_TYPEAHEAD', false),
    dtGender,
    taGender: get(state, 'srp.search.ta_gender', dtGender),
    isDomestic: get(state, 'locale.countryCode', 'US') === 'US',
    brand: state.brand_name.env,
    searchURL: get(state, 'hcSearch.searchURL', ''),
    typeaheadPayload: get(state, 'hcSearch.typeaheadPayload', {}),
  };
};

const mapDispatchToProps = ({
  handleOnChange: updateSearchTerm,
  getSearchSuggestions: updateSearchTermResult,
  handleOnFocus: setSearchTermValueFromPlaceholder,
  setPlaceholderValue,
  setPlaceHolderValueToEmpty,
  setRecentlySearchedAnalytics,
  loadTypeaheadUIConfig,
  updateTopProducts,
  setTypeaheadUtag,
  setGenderTA,
  setGender,
});

export default connect(mapStateToProps, mapDispatchToProps)(DumbSearchBox);
