import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import screenReaderAnnouncer from 'storefront/components/ScreenReaderAlert/screenReaderAnnouncer-actions';
import {
  addFavoriteCategory,
  removeFavoriteCategory,
}
  from 'storefront/components/LeftNavigation/actions-leftnavigation';
import { setFavoriteDesignerUtag } from 'templates/dux/templateActions';
import { isTouchDevice } from 'client-utils/utilities-page';

import './FavoriteDesignerButton.scss';


export class FavoriteDesignerButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFavorite: props.isFavorite,
      text: '',
      formattedName: `tooltip${this.props.id.replace(/\.|#| /gi, '')}`,
    };
    this.toggleFavorite = this.toggleFavorite.bind(this);
    this.mouseOver = this.mouseOver.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
  }


  mouseOver() {
    if (!this.state.isFavorite && !isTouchDevice()) {
      this.setState({
        text: 'Add to Favorites',
      });
      $(`#${this.state.formattedName}`).css('z-index', -1);
      $(`#${this.state.formattedName}`).css('width', 'auto');
      $(`.favorite-designer-container.container-${this.props.id.replace(/\.|#| /gi, '')}`).addClass('active');
    }
  }

  mouseOut() {
    if (!this.state.isFavorite && !isTouchDevice()) {
      this.setState({
        text: '',
      });
      $(`#${this.state.formattedName}`).css('width', '0px');
      $(`.favorite-designer-container.container-${this.props.id.replace(/\.|#| /gi, '')}`).removeClass('active');
    }
  }

  toggleFavorite() {
    if (this.state.isFavorite) {
      this.setState({
        isFavorite: false,
        text: '',
      });
      this.props.dispatch(
        removeFavoriteCategory(false, this.props.id, this.props.name)
      );
      this.props.dispatch(
        screenReaderAnnouncer('Removed')
      );
      this.props.dispatch(
        setFavoriteDesignerUtag(this.props.name, false)
      );
      $(`#${this.state.formattedName}`).css('visibility', 'visible');
      $(`.favorite-designer-container.container-${this.props.id.replace(/\.|#| /gi, '')}`).removeClass('active');
    } else {
      $(`#${this.state.formattedName}`).css('width', 'auto');
      $(`#${this.state.formattedName}`).css('z-index', -1);
      $(`.favorite-designer-container.container-${this.props.id.replace(/\.|#| /gi, '')}`).addClass('active');
      this.setState({
        isFavorite: true,
        text: 'Added to Favorites',
      });
      setTimeout(() => {
        $(`#${this.state.formattedName}`).css('z-index', -1);
        $(`#${this.state.formattedName}`).css('visibility', 'hidden');
        $(`#${this.state.formattedName}`).css('width', '0px');
      },
      2000);
      this.props.dispatch(
        addFavoriteCategory(false, this.props.id, this.props.name)
      );
      this.props.dispatch(
        screenReaderAnnouncer('Added')
      );
      this.props.dispatch(
        setFavoriteDesignerUtag(this.props.name, true)
      );
    }
  }

  render() {
    const containerButtonClassName = this.props.isFavorite
      ? `favorite-designer-container active container-${this.props.id.replace(/\.|#| /gi, '')}`
      : `favorite-designer-container container-${this.props.id.replace(/\.|#| /gi, '')}`;

    return (
      <div className="favorite-designer-button-wrapper">
        <div
          aria-hidden="true"
          className="favorite-designer-tooltip"
          id={this.state.formattedName}
        >
          {this.state.text}
        </div>
        <div
          role="button"
          aria-describedby={this.props.id}
          className={containerButtonClassName}
          aria-label={this.props.isFavorite ? 'Remove from favorites' : 'Add to favorites'}
          aria-pressed={this.state.isFavorite}
          onClick={this.toggleFavorite}
          onFocus={this.mouseOver}
          onBlur={this.mouseOut}
          onMouseOver={this.mouseOver}
          onMouseOut={this.mouseOut}
          onKeyPress={this.toggleFavorite}
          tabIndex={0}
        />

      </div>
    );
  }
}

export default connect()(FavoriteDesignerButton);
