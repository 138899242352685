/* eslint-disable jsx-a11y/img-redundant-alt */

import CloudinaryPicture from "client/common/components/CloudinaryPicture/CloudinaryPicture";
import { isValidCloudinaryUrl } from "client/utilities/utilities-cloudinary";
import { connect } from "react-redux";
import React, { useRef } from "react";
import { get } from "lodash";
import Slider from "react-slick";
import "./mobileImageAnimations.scss";
import classnames from "classnames";
import { updateAltImageUtag } from "client/plp/components/ProductListPage/actions";
import { InView } from "react-intersection-observer";
import { ALTERNATE_IMAGE } from "client/plp/constants";

const MobileImageAnimation = ({
  cloudinaryDPRToggle,
  alt,
  altImage,
  altImageLoadFailed,
  imageErrorHandler,
  imageLoadHandler,
  src,
  title,
  productId,
  selectedColorName,
  position,
  mainImageLoaded,
  updateAltImageUtag,
  plpInteraction,
  showAltAnimation,
  adnlImage,
  adnlImageToggleEnabled
}) => {
  const sliderRef = useRef();

  const onSwipe = () => {
    if(sliderRef?.current?.innerSlider){
      sliderRef.current.innerSlider.clickable = true;
    }

    if(position === 0 && plpInteraction !== ALTERNATE_IMAGE && altImage && !altImageLoadFailed){
      updateAltImageUtag();
    }
  }

  const sliderProps = {
    dots: !!(mainImageLoaded && adnlImageToggleEnabled && (altImage || adnlImage)),
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: false,
    autoplay: false,
    swipeToSlide: true,
    onSwipe: () => onSwipe()
  };

  const playAltImgAnimation = (inView) => {
    return Boolean(mainImageLoaded &&
      position === 0 &&
      altImage &&
      !altImageLoadFailed &&
      inView && showAltAnimation);
  }

  const renderImage = (imageSrc, name, className) => {
    if (imageSrc) {
      return isValidCloudinaryUrl(imageSrc) && cloudinaryDPRToggle ? (
        <CloudinaryPicture
          className={className}
          src={imageSrc}
          alt={alt}
          title={title}
          onError={event => {
            imageErrorHandler(event);
          }}
          onLoad={event => {
            imageLoadHandler(event);
          }}
          name={name}
          dataProductId={productId}
          selectedColorName={selectedColorName}
        />
      ) : (
        <img
          className={className}
          src={imageSrc}
          alt={alt}
          title={title}
          onError={event => {
            imageErrorHandler(event);
          }}
          onLoad={event => {
            imageLoadHandler(event);
          }}
          name={name}
          data-product-id={productId}
          data-color-name={selectedColorName}
        />
      );
    }
    return null;
  };

  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <div ref={ref}>
          <Slider ref={sliderRef} {...sliderProps}>
            <div
              className={classnames({
                "mobile-image-animation": playAltImgAnimation(inView)
              })}
            >
              {renderImage(src)}
            </div>
            {altImage && !altImageLoadFailed && (
              <div
                className={classnames({
                  "mobile-image-animation": playAltImgAnimation(inView)
                })}
              >
                {renderImage(altImage)}
              </div>
            )}
            {adnlImage && adnlImageToggleEnabled && (
              <div
                className={classnames({
                  "mobile-image-animation": playAltImgAnimation(inView)
                })}
              >
                {renderImage(adnlImage)}
              </div>
            )}
          </Slider>
        </div>
      )}
    </InView>
  );
};

const mapStateToProps = state => ({
  cloudinaryDPRToggle: get(state, "toggles.CLOUDINARY_DPR", false),
  plpInteraction: get(state.utag, "productListPage.plp_interaction", null)
});

const mapDispatchToProps = {
  updateAltImageUtag
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileImageAnimation);
