import React, { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';
import './productMetadata.scss';

const ProductMetadata = ({
  productMetadata,
  groupInfo,
  productMetadataStatus = { loading: true },
}) => {
  const productColors = productMetadata.colors && JSON.stringify(productMetadata.colors);
  const productSizes = productMetadata.sizes && JSON.stringify(productMetadata.sizes);

  const pimAttributes = productMetadata.pimAttributes && JSON.stringify(
    productMetadata.pimAttributes
  );

  const webDimensions = productMetadata.mappings
    && productMetadata.mappings.map((dim, index) => (
      <Fragment key={index}>
        <span className="product-metadata__metaHeader">{dim.type}</span>
        {': '}
        {'['}
        {dim.dimensions && dim.dimensions.join(', ')}
        {']'}
        <br />
      </Fragment>
    ));

  return (
    <table className="product-metadata">
      {productMetadataStatus.loading
        ? (
          <tbody>
            <tr>
              <th align="center">Product Metadata</th>
            </tr>
            <tr>
              <td>
                <div className="product-metadata__spinner" />
              </td>
            </tr>
          </tbody>
        )
        : (
          <tbody>
            <tr>
              <td>
                {'Catalog: '}
                {productMetadata.cmosCatalogId}
                &#160;
                {'Depiction: '}
                {productMetadata.cmosItem}
                {!isEmpty(groupInfo) ? `/${groupInfo.cmosItem}` : ''}
              </td>
            </tr>
            <tr>
              <td>
                {'PIM Style: '}
                {productMetadata.pimStyle}
              </td>
            </tr>
            <tr>
              <td>
                {'Sellable Date: '}
                {productMetadata.firstSellableDate}
                <br />
                {!isEmpty(groupInfo) ? `Group Sellable Date: ${groupInfo.firstSellableDate}` : ''}
              </td>
            </tr>
            <tr>
              <td>
                {'Available Inventory: '}
                {productMetadata.onHandInventory}
              </td>
            </tr>
            <tr>
              <td>
                {'Colors: '}
                {productColors}
              </td>
            </tr>
            <tr>
              <td>
                {'Sizes: '}
                {productSizes}
              </td>
            </tr>
            <tr>
              <td>
                {'Web Dimensions:'}
                <br />
                {' '}
                {webDimensions}
              </td>
            </tr>
            <tr>
              <td>
                <b>Department: </b>
                {productMetadata.departmentCode}
                {' '}
                {productMetadata.departmentName}
                <b> Class: </b>
                {productMetadata.classCode}
                {' '}
                {productMetadata.className}
                <b> Sub-Class: </b>
                {productMetadata.subClass}
                {' '}
                {productMetadata.subClassName}
                <b> PIM Attributes: </b>
                {pimAttributes}
              </td>
            </tr>
            <tr>
              <td>
                {'Editorial Image: '}
                {productMetadata.hasEditorialImage ? 'Yes' : 'No'}
                <br />
                {!isEmpty(groupInfo) ? `Group Editorial Image: ${groupInfo.hasEditorialImage ? 'Yes' : 'No'}` : ''}
              </td>
            </tr>
          </tbody>
        )
      }
    </table>
  );
};

export default ProductMetadata;
