import React, { Component } from 'react';
import { scrollToTop } from 'client-utils/scrollToTop';
import upArrow from 'assets/images/arrow-up.svg';
import './scrollButton.scss';

export class ScrollToTopButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hide: true,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    const scrollRatio = 0.5;
    this.scrollAfter = window.innerHeight * scrollRatio;
    this.handleScroll();
    document.addEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    this.setState({ hide: window.pageYOffset < this.scrollAfter });
  }

  render() {
    const { filterSortToggle, abtestFilterSortToggle, abTestsFilterSortValue } = this.props;
    let newFilterSortOn = false;
    if (filterSortToggle) {
      newFilterSortOn = true;
    }
    if (!filterSortToggle) {
      if (abtestFilterSortToggle) {
        if (abTestsFilterSortValue === 'b') {
          newFilterSortOn = true;
        } else if (abTestsFilterSortValue === 'a') {
          newFilterSortOn = false;
        }
      } else {
        newFilterSortOn = false;
      }
    }
    return (
      !this.state.hide && (
        <button
          id="scroll-to-top"
          aria-label="Back to top"
          className={`scroll-to-top${this.props.isMobilePhone ? ' scroll-to-top-mobile' : ''} ${newFilterSortOn ? 'filterSortStyle' : ''}`}
          onClick={(e) => {
            scrollToTop(e);
          }}
          onKeyDown={(e) => {
            scrollToTop(e);
          }}
        >
            <img src={upArrow} />
        </button>
      )
    );
  }
}
export default ScrollToTopButton;
