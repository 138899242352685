import React, { Fragment } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import ConnectedLeftNavigation from 'storefront/components/LeftNavigation/leftNavigation';
import ConnectedBreadcrumb from 'storefront/components/Navigation/Breadcrumb/breadcrumb';
import { parseUrlId } from 'client/utilities/utilities-url';
import { getParams } from 'client-utils/utilities-router';
import EcmSiloThirdParty from 'ecm/components/EcmSiloThirdParty';
import ECMGet from 'ecm/components/EcmGet';
import { ECMSlots } from 'ecm/constants';
import { renderCMSContent, cmsContentExists } from '../../utilities/utilities-cms';
import './Silo.scss';

const Silo = (props) => {
  const { routeParams = {} } = props;
  let categoryId;
  switch (props.plpSeoUrlToggle) {
    case true:
      categoryId = routeParams.categoryInfo
        ? parseUrlId(routeParams.categoryInfo)
        : get(routeParams, 'categoryId', '').split('_').slice(0, 1).toString();
      break;
    case false:
      categoryId = get(routeParams, 'categoryId', '').split('_').slice(0, 1).toString();
      break;
    default:
      categoryId = get(routeParams, 'categoryId', '').split('_').slice(0, 1).toString();
      break;
  }

  const { templateDetails } = props;
  const { source } = getParams(props.location);
  const isBreadcrumbsAvailable = Boolean(get(templateDetails, 'templateAttributes.navigation.breadcrumbs', false));
  const isLeftNavAvailable = Boolean(get(templateDetails, 'templateAttributes.navigation.leftNavigation', false));
  const siloDisplayTitle = get(templateDetails, 'templateAttributes.dynamicContent.title', false);
  const isEcmBannerAvailable = !isEmpty(get(templateDetails, 'templateAttributes.ecmContent[0]', false));
  const isEcmMainContentAvailable = !isEmpty(get(templateDetails, 'templateAttributes.ecmContent[1]', false));
  const areSiloThumbnailsAvailableAsFallback = Boolean(get(templateDetails, 'templateAttributes.dynamicContent.thumbnails', false));

  const handleFallbackForSilo = (bool) => {
    if (bool === true) {
      return <EcmSiloThirdParty />;
    }
    return null;
  };

  const renderEcmBanner = (
    isEcmBannerAvailable && !isEmpty(get(props.templateDetails, 'templateAttributes.ecmContent[0]', false))
      ? (
        ""
      )
      : null
  );

  const renderEcmMainContent = (
    isEcmMainContentAvailable && !isEmpty(get(props.templateDetails, 'templateAttributes.ecmContent[1]', false))
      ? (
        <ECMGet
          ecmSlot={ECMSlots.SILO_FULL_ASSET}
          catId={props.templateDetails.id}
          defaultValue={<div />}
        />
      )
      : handleFallbackForSilo(areSiloThumbnailsAvailableAsFallback)
  );

  const renderBanner = cmsContentExists(props.renderCmsInTemplate) ? renderCMSContent(props.renderCmsInTemplate, 'Top') : renderEcmBanner;
  const renderMainContent = cmsContentExists(props.renderCmsInTemplate) ? renderCMSContent(props.renderCmsInTemplate, 'Main') : renderEcmMainContent;

  const renderBreadcrumbs = (
    <ConnectedBreadcrumb
      defaultCategoryId={categoryId}
      source={source}
    />
  );

  const renderLeftNavigation = (
    <ConnectedLeftNavigation
      routeParams={{ ...props.routeParams }}
      navpath={props.location.navpath}
      source={source}
    />
  );

  const breadcrumbsClass = classNames({
    'mt-15': true,
    'templates-breadcrumb-bottom-margin': true,
    Silo__breadcrumbs_empty: !isBreadcrumbsAvailable,
  });

  const ecmBannerClass = classNames({
    'grid-100': true,
  });

  const displayTitleClassName = classNames({
    Silo__header__title: true,
    'hide-on-mobile': true,
  });

  return (
    <Fragment>
      <div className="grid-parent">
        <div className={ecmBannerClass}>
          { renderBanner }
        </div>
        <div className={breadcrumbsClass}>
          { isBreadcrumbsAvailable && renderBreadcrumbs }
        </div>
      </div>
      <div className="grid-parent">
        { isLeftNavAvailable && renderLeftNavigation }
        <div className="Silo__dynamic_container grid-80 tablet-grid-80 mobile-grid-100 grid-parent">
          <div className="grid-100">
            {siloDisplayTitle && <h1 className={displayTitleClassName}>{siloDisplayTitle}</h1>}
            <section className="Silo__display_title_decoration hide-on-mobile" />
            <div>
              { renderMainContent }
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Silo;
