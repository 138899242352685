import NO_IMAGE_AVAILABLE from 'assets/images/no-image.jpeg';
import HC_NO_IMAGE_AVAILABLE from 'hcAssets/images/hc-no-image.jpeg';
import { InView } from 'react-intersection-observer';
import classNames from 'classnames';
import React, { Component } from 'react';
import CrossFadeImage from './Animations/transitionImages';
import FavoriteButton from '../Favorite/favoriteButton';
import QuickLookButton from '../QuickLookButton/quickLookButton';
import './image.scss';
import { isValidCloudinaryUrl, updateCloudinaryDpr, updateCloudinaryQuality, getQualityfromDpr } from 'client-utils/utilities-cloudinary';
import CloudinaryPicture from 'shared/components/CloudinaryPicture/CloudinaryPicture';
import MobileImageAnimation from './Animations/mobileImageAnimations';
import QuickLookViewSimilarButton from '../QuickLookViewSimilarButton/quickLookViewSimilarButton';

const createImagePreloader = (src, onError, onLoad) => () => {
  const image = new window.Image();
  image.onerror = onError;
  image.onload = onLoad;
  let imageSrc = src;
  if (src.includes('dpr_') && window?.devicePixelRatio) {
    imageSrc = getDPRValueFromImage(src, window?.devicePixelRatio);
  }
  image.src = imageSrc;
  return image;
};

const getDPRValueFromImage = (src, devicePixelRatio) => {
  const setDprRatio = devicePixelRatio >= 2 ? 2 : 1;
  const dprValue = `${setDprRatio}.0`;
  const quality = getQualityfromDpr(dprValue);
  let updatedSrc = src;
  updatedSrc = updateCloudinaryDpr(updatedSrc, dprValue);
  updatedSrc = updateCloudinaryQuality(updatedSrc, quality);
  return updatedSrc;
}

const activeImages = {
  MAIN_IMAGE: 'MAIN_IMAGE',
  ALT_IMAGE: 'ALT_IMAGE',
};

const defaultState = {
  activeImage: activeImages.MAIN_IMAGE,
  mainImageLoadFailed: false,
  altImageLoadFailed: false,
  isHover: false,
};

class Image extends Component {
  constructor(props) {
    super();
    this.state = defaultState;
    this.showAltImage = this.showAltImage.bind(this);
    this.showMainImage = this.showMainImage.bind(this);
    this.imageErrorHandler = this.imageErrorHandler.bind(this);
    this.imageLoadHandler = this.imageLoadHandler.bind(this);
    this.renderImage = this.renderImage.bind(this);
    this.renderFavoriteButton = this.renderFavoriteButton.bind(this);
    this.renderQuickLookButton = this.renderQuickLookButton.bind(this);

    const createPreloader = props.createPreloader || createImagePreloader;
    this.preload = createPreloader(props.src, this.imageErrorHandler, this.imageLoadHandler);
  }

  componentDidMount() {
    IS_CLIENT && !this.props.enableLazyLoading && this.preload();
  }

  getActiveImageSrc() {
    const { activeImage, mainImageLoadFailed } = this.state;
    const { src: mainImage, altImage, brand } = this.props;
    if (activeImage === activeImages.ALT_IMAGE) {
      return altImage;
    }
    if (activeImage === activeImages.MAIN_IMAGE && mainImageLoadFailed) {
      if (brand === 'HC') {
        return this.props.defaultURL || HC_NO_IMAGE_AVAILABLE;
      }
      return this.props.defaultURL || NO_IMAGE_AVAILABLE;
    }
    return mainImage;
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.src !== nextProps.src) {
      this.setState(defaultState);
    }
  }

  imageErrorHandler() {
    if (this.state.activeImage === activeImages.MAIN_IMAGE) {
      this.setState({
        mainImageLoadFailed: true,
      });

      this.props.defaultImageToggle && this.props.setMainImageFailed();

      if (window && window.QuantumMetricAPI) {
        window.QuantumMetricAPI.sendEvent(218, 0, `${this.props.productId}|${this.props.src}`);
      }
    } else {
      this.setState({
        activeImage: activeImages.MAIN_IMAGE,
        altImageLoadFailed: true,
      });
      if (window && window.QuantumMetricAPI) {
        window.QuantumMetricAPI.sendEvent(218, 0, `${this.props.productId}|${this.props.altImage}`);
      }
    }
  }

  imageLoadHandler() {
    this.props.defaultImageToggle && this.props.setMainImageLoaded();
  }

  showMainImage() {
    this.setState({ activeImage: activeImages.MAIN_IMAGE });
  }

  showAltImage() {
    if (this.state.altImageLoadFailed) return;
    if (this.props.isDevicetypeDesktop) {
      this.setState({ activeImage: activeImages.ALT_IMAGE });
    }
  }

  isHover() {
    this.setState({ isHover: true });
  }

  isNotHover() {
    this.setState({ isHover: false });
  }

  renderImage(productId) {
    const {
      alt,
      title,
      altImage,
      isSwatchHover = false,
      isDevicetypeDesktop = false,
      cloudinaryDPRToggle = false,
      selectedColorName,
      altImageAbTestToggle,
      adnlImageToggleEnabled,
      position,
      mainImageLoaded,
      showAltAnimation,
      adnlImage,
    } = this.props;
    const src = this.getActiveImageSrc();

    const image = isValidCloudinaryUrl(src) && cloudinaryDPRToggle ? (
      <CloudinaryPicture
        src={src}
        alt={alt}
        title={title}
        onError={(event) => { this.imageErrorHandler(event); }}
        onLoad={(event) => { this.imageLoadHandler(event); }}
        name="mainImage"
        dataProductId={productId}
        selectedColorName={selectedColorName}
        onClick={() => {
          if (altImage) {
            this.showAltImage();
          }
        }}
      />
    ) : (
      <img
        src={src}
        alt={alt}
        title={title}
        onError={(event) => { this.imageErrorHandler(event); }}
        onLoad={(event) => { this.imageLoadHandler(event); }}
        name="mainImage"
        data-product-id={productId}
        data-color-name={selectedColorName}
        onClick={() => {
          if (altImage) {
            this.showAltImage();
          }
        }}
      />
    );

    return (
      <>
        {isDevicetypeDesktop
          ? (
            <CrossFadeImage
              alt={alt}
              altImage={altImage}
              altImageLoadFailed={this.state.altImageLoadFailed}
              isHover={this.state.isHover}
              imageErrorHandler={this.imageErrorHandler}
              imageLoadHandler={this.imageLoadHandler}
              transitionToImage={src}
              isSwatchHover={isSwatchHover}
              mainImageLoadFailed={this.state.mainImageLoadFailed}
              productId={productId}
              src={src}
              title={title}
              selectedColorName={selectedColorName}
            />
          ) : ((adnlImageToggleEnabled || altImageAbTestToggle) ?
            (<MobileImageAnimation
              position={position}
              alt={alt}
              altImage={altImage}
              altImageLoadFailed={this.state.altImageLoadFailed}
              isHover={this.state.isHover}
              imageErrorHandler={this.imageErrorHandler}
              imageLoadHandler={this.imageLoadHandler}
              mainImageLoadFailed={this.state.mainImageLoadFailed}
              productId={productId}
              src={src}
              title={title}
              selectedColorName={selectedColorName}
              mainImageLoaded={mainImageLoaded}
              showAltAnimation={showAltAnimation}
              adnlImage={adnlImage}
              adnlImageToggleEnabled={adnlImageToggleEnabled}
            /> )
            : image
          )
        }
      </>
    );
  }

  renderFavoriteButton() {
    const {
      quickLookToggle,
      favoriteToggle = false,
      isUIPRICTest,
    } = this.props;

    return (
      <>
        {!isUIPRICTest && favoriteToggle && this.props.displayable && (
          <FavoriteButton
            productId={this.props.productId}
            isFavorite={this.props.isFavorite}
            isDisplayAsGroups={this.props.isDisplayAsGroups}
            cmosItem={this.props.cmosItem}
            cmosCatalogId={this.props.cmosCatalogId}
            quickLookToggle={quickLookToggle}
          />
        )}
      </>
    );
  }

  renderCtaButton = () => {
    const { quickLookVSToggle, showViewSimilarButton, isDomestic = false } = this.props;

    if(quickLookVSToggle && showViewSimilarButton() && isDomestic){
      return this.renderQuickLookViewSimilarButton();
    } 

    return this.renderQuickLookButton();
  }

  renderQuickLookButton() {
    const { quickLookToggle, showQLModalWindow } = this.props;

    return (
      <>
        {quickLookToggle && (
          <QuickLookButton
            showQLModalWindow={showQLModalWindow}
            isHover={this.state.isHover}
          />
        )}
      </>
    );
  }

  renderQuickLookViewSimilarButton = () => {
    const { 
      quickLookVSToggle, 
      showQLModalWindow, 
      selectedColorName, 
      productId,
      defaultColorName,
      selectedColorCode,
      groupChildColorName,
      groupChildId
    } = this.props;

    return (
      <>
        {quickLookVSToggle && 
          <QuickLookViewSimilarButton 
            showQLModalWindow={showQLModalWindow}
            isHover={this.state.isHover}
            selectedColorName={groupChildColorName ? groupChildColorName : selectedColorName}
            productId={groupChildId ? groupChildId : productId}
            defaultColorName={defaultColorName}
            selectedColorCode={selectedColorCode}
          />
        }
      </>
    );
  }

  render() {
    const { enableLazyLoading = false, quickLookVSToggle, adnlImageToggleEnabled, altImageAbTestToggle } = this.props;

    return (
      enableLazyLoading
        ? (
          <InView
            rootMargin="400px"
            triggerOnce
          >
            {({ inView, ref }) => (
              <div
                className={classNames("main-image-container", { 'mobile-fixed': adnlImageToggleEnabled || altImageAbTestToggle })}
                ref={ref}
                onMouseEnter={() => {
                  this.isHover();
                }}
                onMouseLeave={() => {
                  this.isNotHover();
                }}
                onFocus={() => {
                  this.isHover();
                }}
                onBlur={() => {
                  this.isNotHover();
                }}
              >
                {inView && (
                  <div>
                    {this.renderImage(this.props.productId)}
                    {this.renderFavoriteButton()}
                    {this.renderCtaButton()}
                  </div>
                )}
              </div>
            )}
          </InView>
        )
        : (
          <div
            className={classNames("main-image-container", { 'mobile-fixed': adnlImageToggleEnabled || altImageAbTestToggle })}
            onMouseEnter={() => {
              this.isHover();
            }}
            onMouseLeave={() => {
              this.isNotHover();
            }}
            onFocus={() => {
              this.isHover();
            }}
            onBlur={() => {
              this.isNotHover();
            }}
          >
            {this.renderImage(this.props.productId)}
            {this.renderFavoriteButton()}
            {this.renderCtaButton()}
          </div>
        )
    );
  }
}

export default Image;
