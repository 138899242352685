import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import classnames from 'classnames';
import RenderContentItem from '../renderContentItem';
import './promoTile.scss';

export function ConnectedCMSPromoTile({ cmsContent, index }) {
  const layouts = cmsContent[0]?.fields?.l1Layouts || [];
  const promoLayouts = layouts.filter((layout) => layout.fields?.placement === 'Promo');
  const dynamicPromoLayouts = layouts.filter((layout) => layout.fields?.placement === 'Dynamic Promo');
  const promoTiles = promoLayouts[0]?.fields?.components || [];
  const dynamicPromoTiles = dynamicPromoLayouts[0]?.fields?.components || [];

  return (
    <>
      {
        dynamicPromoTiles[index] && (
          <div
            className={classnames(
              { 'cms-promo-tile dynamic-tile product-thumbnail grid-33 tablet-grid-33 mobile-grid-50 grid-1600 enhancement': dynamicPromoTiles[index].fields?.columnSpan === 1 },
              { 'cms-promo-tile dynamic-tile product-thumbnail grid-50 tablet-grid-66 mobile-grid-100 grid-1600-2column enhancement': dynamicPromoTiles[index].fields?.columnSpan === 2 },
              { 'cms-promo-tile dynamic-tile product-thumbnail grid-75 tablet-grid-100 mobile-grid-100 enhancement': dynamicPromoTiles[index].fields?.columnSpan === 3 },
              { 'cms-promo-tile dynamic-tile product-thumbnail grid-100 tablet-grid-100 mobile-grid-100 enhancement': dynamicPromoTiles[index].fields?.columnSpan === 4 },
            )
             }
          >
            <RenderContentItem cmsContentItem={dynamicPromoTiles[index].fields?.content} />
          </div>
        )
      }
    </>
  );
}

const mapStateToProps = (state) => ({
  cmsContent: get(state, 'cms.entries', []),
});

export default connect(mapStateToProps)(ConnectedCMSPromoTile);
