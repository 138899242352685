import React from 'react';
import Loadable from 'react-loadable';
import Modal from 'shared/components/Modal/modal';

const IE11Modal = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-ie11' */ './ie11modal'),
  loading: () => false,
});

const ModalSelector = (props) => {
  if (props.deviceBrowser.includes('IE11')) {
    return <IE11Modal />;
  }
  return <Modal />;
};

export default ModalSelector;
