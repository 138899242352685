import React from 'react';
import { Link } from 'react-router';

const TypeaheadResults = React.memo((props) => {
  const {
    items = [],
    query = '',
    urlPrefix = '',
    handleClickSuggestion = () => null,
    setUtag,
    searchItems,
  } = props;
  const sanitizedQuery = query.toLowerCase().replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&');
  return (
    <div className="custom-typeAheadMenu-menu">
      <ul>
        {
          items.slice(0, 7).map(
            (item, i) => {
              const suggestion = item.parameters?.query || '';
              const re = new RegExp(`(${sanitizedQuery})`, 'gi');
              const matchedSuggestion = suggestion.trim().split(re).map((part, i) => (part === sanitizedQuery ? <span key={i} className="match">{part}</span> : part));
              const parsedUrl = `${urlPrefix + (window?.BR?.mobile?.createURLFromParams(item.toHashParameters()) || '')}&src=suggest`;
              return (
                <li
                  key={`${sanitizedQuery}_${i}`}
                >
                  <Link
                    to={parsedUrl}
                    onClick={(e) => {
                      setUtag(e, searchItems);
                      handleClickSuggestion(item);
                    }}
                  >
                    <p>{matchedSuggestion}</p>
                  </Link>
                </li>
              );
            }
          )
        }
      </ul>
    </div>
  );
});

export default TypeaheadResults;
TypeaheadResults.displayName = 'TypeaheadResults';
