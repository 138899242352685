import React, { Component, createContext } from 'react';
import window from 'window-or-global';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { guestConfiguration } from '@nmg/auth';
import IdleTimer from 'react-idle-timer';
import get from 'lodash/get';
import {
  getStoreList,
  getConfig,
  getProfileData,
  parsePanelOptCookie,
  setStoreCookie,
  signOutAtgHeader,
  logoutUcaProfileCookie,
} from './components/utilities';
import {
  incircleModule,
  getEnv,
  sendSelectedStore,
} from '../../../utilities/amplifyUtils';


const Context = createContext();
const { Consumer } = Context;
class Provider extends Component {
  state = {
    isOpen: false,
    pageStack: ['homePage'],
    name: '',
    location: '',
    saData: [],
    saSelected: {},
    storeSelected: undefined,
    storeChanged: false,
    storeData: [],
    storeStatus: 'resolved',
    toggles: {},
    callback: undefined,
    loggedInOnOpen: false,
    isTestGroup: null,
    incircleData: [],
    isAuthenticated: false,
    ...this.dispatch(),
  };

  componentDidMount() {
    const { toggles, location } = getConfig();
    const { name } = getProfileData();

    this.updateAuthenticatedStatus(toggles);

    this.setState({
      toggles,
      location,
      name,
    });

    if (typeof sessionStorage !== 'undefined') {
      const cachedSaData = sessionStorage.getItem(`YourNeimansSa${name}`);
      if (cachedSaData) {
        this.setState({ saData: JSON.parse(cachedSaData) });
      }
    }

    if (this.state.isControlGroup == null) {
      const panelTest = parsePanelOptCookie();
      if (panelTest) this.setState({ isTestGroup: panelTest === 'b' });
    }

    if (this.props.configureAmplify) {
      const envObj = getEnv();
      this.props.configureAmplify(envObj, 'NM', toggles.USE_CONFIDENTIAL_CLIENT);
      guestConfiguration(
        toggles.FINGERPRINT_PRO,
        toggles.GUEST_IDENTITY_DT,
        envObj,
        'NM',
        toggles.PZP_IDENTITY,
        toggles.DISABLE_ATG_LOGIN,
        toggles.ADOBE_PZP_IDENTITY
      );
    }


    if (toggles.IMPROVED_PANEL_CTA) {
      Hub.listen('auth', ({ payload: { event } }) => {
        if (event === 'signIn') {
          this.setState({ isAuthenticated: true });
        }
        if (event === 'signOut') {
          this.setState({ isAuthenticated: false });
          signOutAtgHeader();
          logoutUcaProfileCookie();
        }
      });
    }

    window.YourNeimans = {
      toggle: () => this.dispatch().togglePanel(),
      setData: (data) => this.setState({ ...data }),
    };
  }

  onIdle = () => {
    const kmsi = window.localStorage.getItem('_kmsi');
    if (!kmsi) {
      Auth.signOut();
      signOutAtgHeader();
      this.setState({ isAuthenticated: false });
    }
  }

  checkLoggedInStatus = (successCb) => {
    Auth.currentSession()
      .then(successCb)
      .catch((e) => e);
  }


  updateAuthenticatedStatus = (toggles = this.state.toggles) => {
    if (toggles.IMPROVED_PANEL_CTA) {
      Auth.currentAuthenticatedUser({})
        .then(() => this.setState({ isAuthenticated: true }))
        .catch(() => {
          this.setState({ isAuthenticated: false });
          signOutAtgHeader();
        });
    } else {
      const { isAuthenticated } = getProfileData();
      this.setState({ isAuthenticated });
    }
  }

  getAndSetIncircleData = () => {
    incircleModule.getIncircleData()
      .then((incircleData) => this.setState({ incircleData }));
  }

  dispatch() {
    return {
      togglePanel: () => {
        this.setState((state) => ({ isOpen: !state.isOpen }));
      },
      onAfterOpen: () => {
        const { toggles } = this.state;
        const successCb = () => { this.setState({ loggedInOnOpen: true }); };
        this.checkLoggedInStatus(successCb);
        const newState = { ...getProfileData() };
        if (toggles.IMPROVED_PANEL_CTA) delete newState.isAuthenticated;
        this.setState(newState);
      },
      onAfterClose: () => {
        this.setState({ pageStack: ['homePage'], callback: undefined });
        const reloadPage = () => window.location.reload();
        const onCloseAction = {
          '/favoriteitems': reloadPage,
          '/my/favorites/store': reloadPage,
          '/checkout/cart.jsp': reloadPage,
          '/guest-order-history': () => { window.location.href = '/order-history'; },
        }[window.location.pathname];

        if (onCloseAction && !this.state.loggedInOnOpen) {
          this.checkLoggedInStatus(onCloseAction);
        }
        this.setState({ loggedInOnOpen: false });

        if (onCloseAction && this.state.storeChanged) {
          onCloseAction();
        }
      },
      goToPage: (page, data, refreshToken) => {
        const { pageStack } = this.state;
        this.setState({
          pageStack: [...pageStack, page],
          ...data,
        });
        if (refreshToken) Auth.currentSession();
      },
      goBack: () => {
        const updatePageStack = [...this.state.pageStack];
        updatePageStack.pop();
        this.setState({ pageStack: updatePageStack });
      },
      setLocation: (location) => {
        this.setState({ location });
      },
      setSaData: (data) => {
        this.setState({ saData: data });
      },
      setStoreSelected: (store, isUpdate) => {
        isUpdate && sendSelectedStore(store);
        setStoreCookie(store);
        this.setState((prevState) => ({
          storeSelected: store,
          storeChanged: prevState.storeChanged || !!isUpdate,
        }));
      },
      setStoreList: (location, set) => {
        this.setState({ storeData: [], storeStatus: 'loading' });
        getStoreList(location).then(({ data: storeList }) => {
          this.setState({
            storeStatus: storeList.length ? 'resolved' : 'failed',
          });
          storeList.filter((str) => get(str, 'storeServices.inStoreShopping.isOpen', false))
            .sort((store1, store2) => store1.distance - store2.distance)
            .forEach((store, i) => {
              const { storeSelected, storeData } = this.state;
              storeSelected
                ? storeSelected.id === store.id && (
                  this.setState({ storeSelected: store })
                ) : set && i === 0 && this.setState({ storeSelected: store });
              this.setState({
                storeData: [...storeData, store],
              });
            });
        }).catch(() => {
          this.setState({ storeStatus: 'failed' });
        });
      },
      updateName: () => {
        const { name } = getProfileData();
        this.updateAuthenticatedStatus();
        this.setState({ name });
        return name;
      },
      setIncircleData: (incircleData) => this.setState({ incircleData }),
    };
  }

  render() {
    const { children } = this.props;
    const IS_CLIENT = typeof window !== 'undefined';
    return (
      <Context.Provider value={{ ...this.state }}>
        {IS_CLIENT && (
          <IdleTimer
            ref={(ref) => { this.idleTimer = ref; }}
            onIdle={this.onIdle}
            timeout={1000 * 60 * 60} // second -> minute -> hour
          />
        )}
        {children}
      </Context.Provider>
    );
  }
}

export { Provider, Consumer, Context };
