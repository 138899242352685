import window from 'window-or-global';
import logger from '../../../server/utilities/logger';

const showFeedBack = () => {
  try {
    window.OOo.oo_feedback.show();
  }
  catch(err) {
    logger.error(`Error loading site Feedback: ${err}`);
  }
}

export const showSearch = () => {
  try {
    window.OOo.oo_search.show();
  }
  catch(err) {
    logger.error(`Error loading site Feedback: ${err}`);
  }
}

export const showFeedbackFilter = () => {
  try {
    window.OOo.oo_feedback_filters.launchOOPopup();
  }
  catch(err) {
    logger.error(`Error loading filter feedback: ${err}`);
  }
}

export const showSearchFeedbackFilter = () => {
  try {
    window.OOo.oo_feedback_searchFilters.launchOOPopup();
  }
  catch(err) {
    logger.error(`Error loading filter feedback: ${err}`);
  }
}

export default showFeedBack;
