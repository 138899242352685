import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import LinkedData from 'shared/components/LinkedData/linkedData';
import classNames from 'classnames';
import { shouldLoad } from 'universal/http-client';
import { isMobile } from 'client-utils/utilities-page';
import { unescape } from 'he';
import { PAGE_ID_PLP } from 'shared/actions/actions-page';
import { getBreadcrumbList, dispatchPreFetchedBreadcrumbData } from '../actions-navigation';
import './breadcrumb.scss';

export class Breadcrumb extends Component {
  UNSAFE_componentWillMount() {
    const { breadcrumb_content, defaultCategoryId, source } = this.props;
    const shouldLoadBC = shouldLoad(breadcrumb_content);
    if (this.props.breadcrumbPath && this.props.breadcrumbPath !== 'undefined' && shouldLoadBC) {
      this.props.fetchBreadcrumb(this.props.breadcrumbPath, source);
    } else if (shouldLoadBC && defaultCategoryId) {
      this.props.fetchBreadcrumb(defaultCategoryId, source);
    }
  }

  render() {
    const {
      breadcrumbs = [],
      isChanel,
      pageId,
      hideOnMobile,
      pageCanonical,
      firstParam,
      secondParam,
      isSeoToggleOn,
      isHeaderPackage,
      isDomestic,
      isEntry
    } = this.props;
    const jsonLD = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbs.map((breadcrumb, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@id': breadcrumb.url,
          name: breadcrumb.name,
        },
      })),
    };

    const itemName = [];

    firstParam && itemName.push(firstParam);
    secondParam && itemName.push(secondParam);
    
    isSeoToggleOn && (firstParam || secondParam) && jsonLD.itemListElement.push({
      '@type': 'ListItem',
      position: jsonLD.itemListElement.length + 1,
      item: {
        '@id': pageCanonical ? pageCanonical.match(/(\/c\/).+/)[0] : null,
        name: itemName.join(' '),
      },
    })
    const breadcrumbsHTML = breadcrumbs.map((breadcrumb, index) => {
      const {
        name = '',
        nameForMobile = '',
      } = breadcrumb;
      const current = index + 1 === breadcrumbs.length && !(firstParam || secondParam) ? 'page' : '';
      return (
        (get(this.props, 'useForSeoPurposes', false) && index === breadcrumbs.length - 1)
          ? (
            <li key={index} aria-current={current}>
              <a href={breadcrumb.url}>
                <h1 className="seo-purposed-breadcrumb-item">
                  {isMobile()
                    ? unescape(nameForMobile.trim())
                    : unescape(name.trim())
                }
                </h1>
              </a>
            </li>
          )
          : (
            <li key={index} aria-current={current}>
              <a href={breadcrumb.url}>
                {isMobile()
                  ? unescape(nameForMobile.trim())
                  : unescape(name.trim())
              }
              </a>
            </li>
          )
      );
    });

    const firstParamBreadcrumb = <li key={breadcrumbs.length} aria-current={secondParam ? '' : 'page'}>
      <a href={''}>
        {firstParam}
      </a>
    </li>;

    const secondParamBreadcrumb = <li key={firstParam ? breadcrumbs.length + 1 : breadcrumbs.length} aria-current={'page'}>
      <a href={''}>
        {secondParam}
      </a>
    </li>;

    const breadcrumbsClass = classNames({
      breadcrumb: true,
      updated: pageId === PAGE_ID_PLP || isEntry,
      chanel: isChanel,
      'hide-on-mobile': hideOnMobile,
      'breadcrumb-top': isHeaderPackage && isDomestic,
    });

    return (
      <nav aria-label="Breadcrumb" className="grid-100 tablet-grid-100 mobile-grid-100">
        { (!isEmpty(breadcrumbs)) ? <LinkedData data={jsonLD} /> : null }
        <ul className={breadcrumbsClass}>
          { breadcrumbsHTML }
          { isSeoToggleOn && firstParam && firstParamBreadcrumb }
          { isSeoToggleOn && secondParam && secondParamBreadcrumb }
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  const isChanel = state.productCatalog.product.isGroup
    ? state.productCatalog.group.isChanel
    : state.productCatalog.product.isChanel;

  return {
    breadcrumb_content: state.api.breadcrumb_content,
    breadcrumbs: state.navigation.breadcrumbs,
    breadcrumbPath: state.navigation.breadcrumbPath,
    isChanel,
    pageId: get(state, 'page.pageId', ''),
    pageCanonical: get(state, 'page.canonical', ''),
    isSeoToggleOn: get(state, 'toggles.SEO_BUCKET_ONE', false),
    isHeaderPackage: get(state, 'toggles.UI_HEADER', false),
    isDomestic: get(state, 'locale.countryCode', 'US') === 'US',
    firstParam: state.facetedLeftNav.firstParam[Object.keys(state.facetedLeftNav.firstParam)[0]],
    secondParam: state.facetedLeftNav.secondParam[Object.keys(state.facetedLeftNav.secondParam)[0]],
  };
};

const mapDispatchToProps = ({
  fetchBreadcrumb: getBreadcrumbList,
  dispatchPreFetchedBreadcrumbData,
});

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumb);
