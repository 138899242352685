import React from 'react';
import Radio from 'shared/components/Radio/radio';
import './mobileSort.scss';

const MobileSort = ({
  options = [], selectedValue, close, onClick,
}) => (
  <div className="mobile-sort__pane__content">
    <ul>
      {options.map(
        (option) => {
          const [key] = Object.keys(option);
          return (
            <li key={key} onClick={close}>
              <Radio
                label={option[key]}
                value={key}
                selected={key === selectedValue}
                onClick={onClick}
              />
            </li>
          );
        }
      )}
    </ul>
  </div>
);

export default MobileSort;
