import React, {
  Children,
  cloneElement,
  Fragment,
  PureComponent,
} from 'react';
import { ProductListContextProvider } from '../../productListContext';

export default class StickyScroll extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      position: [0, 0],
      topY: 0,
      currentScrollType: 'down',
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    const { isDevicetypeMobilePhone } = this.props;
    if (isDevicetypeMobilePhone) {
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    const { isDevicetypeMobilePhone } = this.props;
    if (isDevicetypeMobilePhone) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const { isDevicetypeMobilePhone } = this.props;
    const { topY } = this.state;
    if (isDevicetypeMobilePhone) {
      if (prevState.topY > topY) {
        this.state.currentScrollType = 'down';
      } else {
        this.state.currentScrollType = 'up';
      }
    }
    return this.state;
  }

  handleScroll() {
    const { top, bottom, y } = this.childRef.getBoundingClientRect();
    this.setState((prevState) => ({
      ...prevState,
      position: [top, bottom],
      topY: y,
    }));
  }

  render() {
    const {
      children,
      isDevicetypeMobilePhone,
    } = this.props;
    const { currentScrollType, topY } = this.state;
    const clones = cloneElement(Children.only(children), {
      ref: (el) => { this.childRef = el; },
    });
    if (isDevicetypeMobilePhone) {
      return (
        <ProductListContextProvider value={{
          scrollType: currentScrollType,
          position: topY,
        }}
        >
          {clones}
        </ProductListContextProvider>
      );
    }
    return (<Fragment>{clones}</Fragment>);
  }
}
