import React from 'react';
import { addDays, getDay, format } from 'date-fns';
import classNames from 'classnames';
import { useDelivery } from './useDelivery';
import MiniCartBanner from './MiniCartBanner';
import './MiniCartContent.scss';

const CartProductItem = ({ item, clientLocationZip, key }) => {
  const prodVariation1 = item.currentSku.colorName;
  const prodVariation2 = item.currentSku.sizeName;
  const designerName = item.designerName;
  const name = item.displayName;
  const quantity = item.quantity;
  const offerItemId = item.offerItemId;
  const link = item.canonicalUrl;
  const dateFormat = 'MM/DD/YYYY';
  const hasCurbSidePickup = typeof item.storeId !== 'string';

  let skuIds = [];

  if (item && item.skus) {
    skuIds = item.skus.map((el) => el?.id).filter((elem) => elem != null);
  }

  const { deliveryCTAText = {}, storePickup = {} } = useDelivery(
    item.serviceLevelCodes,
    skuIds,
    item.quantity,
    item.skuId,
    clientLocationZip,
    item.storeId,
  );

  const shippingDate = () => {
    const genericShipMessage = 'Expected to ship no later than: ';

    if (item.preOrder || item.backOrder) {
      if (
        item.currentSku.expectedShipDate
        && new Date(item.currentSku.expectedShipDate) > new Date()
      ) {
        return `${genericShipMessage}${format(
          new Date(item.currentSku.expectedShipDate),
          dateFormat,
        )}`;
      }

      return `${genericShipMessage}Date Unavailable`;
    }

    if (item.currentSku.dropShip) {
      if (item.currentSku.deliveryDays > 0) {
        let deliveryDate = addDays(new Date(), item.currentSku.deliveryDays);

        switch (getDay(deliveryDate)) {
          case 0:
            deliveryDate = addDays(deliveryDate, 1);
            break;
          case 6:
            deliveryDate = addDays(deliveryDate, 2);
            break;
          default:
            break;
        }

        return `${genericShipMessage}${format(deliveryDate, dateFormat)}`;
      }

      return `${genericShipMessage}Date Unavailable`;
    }

    return '';
  };

  const shipMessages = () => {
    const messages = [];

    if (hasCurbSidePickup) {
      if (item.preOrder) {
        messages.push({ message: 'Pre-Order' });
        messages.push({ message: shippingDate() });
      }

      if (item.backOrder) {
        messages.push({ message: 'Back Order' });
        messages.push({ message: shippingDate() });
      }

      if (
        !item.backOrder
        && !item.preOrder
        && !item.currentSku.dropShip
        && !item.sourcedByPromo
      ) {
        messages.push({ message: 'In Stock' });

        if (item.replenishmentInterval || item.replenishInterval) {
          messages.push({
            message: `Replenish (
              ${item.replenishmentInterval ? item.replenishmentInterval : ''}
              ${item.replenishInterval ? item.replenishInterval : ''} days)`,
          });
        }
      }

      if (item.sourcedByPromo) {
        messages.push({ message: 'Promotion' });
      }
    }

    if (!hasCurbSidePickup) {
      messages.push({
        message: `Pickup: ${storePickup.name}`,
        bold: true,
      });

      if (deliveryCTAText.main) {
        messages.push({ message: deliveryCTAText.main });
      } else {
        messages.push({
          message: 'Ready for curbside pickup in 3-5 days upon purchase',
        });
      }
    }

    if (item.currentSku.dropShip) {
      messages.push({ message: 'Ships from vendor' });

      if (!item.perishableDeliveryByDate) {
        messages.push({ message: shippingDate() });
      }
    }

    if (item.perishableDeliveryByDate) {
      messages.push({
        message: `Delivery Date: ${format(
          new Date(item.perishableDeliveryByDate),
          dateFormat,
        )}`,
      });
    }

    if (item.customization) {
      messages.push({
        message: 'Personalization applied. View your shopping bag for details',
      });
    }

    return messages.length > 0
      ? messages.map((m) => (
        <div className={m.bold ? 'bold' : null}>{m.message}</div>
      ))
      : null;
  };

  const displayPrice = () => {
    const {
      promotionalDollarOff,
      promotionalPercentOff,
      itemMarkdown,
      priceAdornments,
      itemOriginPriceWithoutCustomization,
      itemPrice,
    } = item;

    const originalPrice = itemOriginPriceWithoutCustomization?.price || 0;

    /* eslint-disable no-nested-ternary */
    const promoPrices = () => (promotionalDollarOff > 0 && promotionalPercentOff > 0 ? (
      <>
        {Number(promotionalPercentOff).toFixed()}
        <span>% Off </span>
        -$
        {Number(itemMarkdown?.[0]).toFixed(2)}
        &nbsp;
        <span>$ Off </span>
        -$
        {promotionalDollarOff}
      </>
    ) : promotionalDollarOff > 0 ? (
      <>
        <span>$ Off </span>
        -$
        {promotionalDollarOff}
      </>
    ) : promotionalPercentOff > 0 ? (
      <>
        {Number(promotionalPercentOff).toFixed()}
        <span>% Off </span>
        -$
        {Number(itemMarkdown?.[0]).toFixed(2)}
      </>
    ) : (<></>));


    if (promotionalDollarOff > 0 || promotionalPercentOff > 0) {
      return (
        <>
          <div>
            $
            {originalPrice}
          </div>
          <div className="price-discount">
            <div>{promoPrices()}</div>
            <div>
              Your Price: $
              {itemPrice}
            </div>
          </div>
        </>
      );
    }

    if (priceAdornments?.length) {
      return priceAdornments.map((priceAdornment, index) => (
        <div className={`price ${index !== 0 ? 'new' : 'discount'}`}>
          {priceAdornment.label}
          : $
          {priceAdornment.price}
        </div>
      ));
    }

    if (!item.onSale) {
      return (
        <div className="price">
          $
          {itemPrice}
        </div>
      );
    }

    return <></>;
  };

  return (
    <div className="summaryItemWrap" key={key}>
      <img src={item.imageUrl} className="productImg" />
      <div className="productDataWrap">
        <div className="title">
          <a href={link}>
            {' '}
            {designerName}
            {' '}
            {name}
            {' '}
          </a>
        </div>
        <div className="item">
          Item:
          {' '}
          {offerItemId}
        </div>
        {displayPrice()}
        {quantity ? (
          <div className="quantity">
            Qty:
            {' '}
            {quantity}
          </div>
        ) : null}
        {prodVariation1 ? (
          <div>
            Color:
            {' '}
            {prodVariation1}
          </div>
        ) : null}
        {prodVariation2 ? (
          <div>
            Size:
            {' '}
            {prodVariation2}
          </div>
        ) : null}
        {shipMessages()}
      </div>
    </div>
  );
};

const InfoSection = ({
  itemsCount, mdseTotalAmt, isGuestUser, isBottom,
}) => {
  const isHC = NMConfig.BRAND_NAME === 'HC';
  const onCheckoutClick = () => {
    isHC && window.sessionStorage.removeItem('step');
    window.location.href = isGuestUser
      ? NMConfig.CHECKOUT_PAGE_URL_DT // /cart/
      : NMConfig.ORDER_REVIEW_URL_DT; // /checkout/
  };

  const shoppingBag = React.useMemo(
    () => (
      <>
        <span>
          <a href={NMConfig.CHECKOUT_PAGE_URL_DT}>View shopping bag</a>
        </span>
        <div>
          {itemsCount}
          {' '}
          items in your bag
        </div>
      </>
    ),
    [itemsCount],
  );

  const totalCheckoutSection = React.useMemo(
    () => (
      <>
        <div className="subtotal">
          Subtotal: $
          {' '}
          {mdseTotalAmt}
        </div>
        <div className={isBottom ? 'affirmCheckout' : null}>
          {isBottom && !isHC ? (
            <div className="affirm">
              <div
                className="affirm-as-low-as"
                data-page-type="cart"
                data-amount={mdseTotalAmt.replace('.', '')}
              />
            </div>
          ) : null}
          <button
            className={classNames(isHC ? 'hcCheckoutBtnBg' : 'checkoutBtn')}
            onClick={onCheckoutClick}
          >
            Checkout
          </button>
        </div>
      </>
    ),
    [mdseTotalAmt],
  );

  return (
    <div className="infoSectionContainer">
      <div className={isBottom ? 'shippingBag' : null}>{shoppingBag}</div>
      <div className="checkout">{totalCheckoutSection}</div>
    </div>
  );
};

const MiniCartContent = ({ cartData, clientLocationZip, isGuestUser }) => {
  const { prices = {} } = cartData;
  const { mdseTotalAmt } = prices;

  const normalizedCommerceItems = React.useMemo(() => {
    return cartData?.shoppingCart?.commerceItems?.shipments?.reduce(
      (result, { items, ...shipmentDetails }) => result.concat(
        items.map(({ productDetails, ...item }) => ({
          ...shipmentDetails,
          ...item,
          ...productDetails,
        })),
      ),
      [],
    );
  }, [cartData]);

  const renderProducts = React.useMemo(() => {
    return (
      <>
        <p>
          Please note: Items and promotional pricing are not reserved until
          checkout is complete.
        </p>
        {normalizedCommerceItems?.map((product) => (
          <CartProductItem
            item={product}
            key={product.dt_itemId}
            clientLocationZip={clientLocationZip}
          />
        ))}
      </>
    );
  }, [normalizedCommerceItems]);

  return (
    <div className="miniCartContentWrap miniCartContentContainer">
      {normalizedCommerceItems?.length ? (
        <>
          <InfoSection
            itemsCount={normalizedCommerceItems?.length}
            mdseTotalAmt={mdseTotalAmt}
            isGuestUser={isGuestUser}
          />
          <div className="cartItems">{renderProducts}</div>
          <MiniCartBanner />
          <InfoSection
            itemsCount={normalizedCommerceItems?.length}
            mdseTotalAmt={mdseTotalAmt}
            isGuestUser={isGuestUser}
            isBottom
          />
        </>
      ) : (
        <div>No products in shopping bag</div>
      )}
    </div>
  );
};

MiniCartContent.defaultProps = {
  cartData: {
    prices: {},
  },
};

export default MiniCartContent;
