import React, { Fragment } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import ConnectedLeftNavigation from 'storefront/components/LeftNavigation/leftNavigation';
import ConnectedBreadcrumb from 'storefront/components/Navigation/Breadcrumb/breadcrumb';
import { renderCMSContent, cmsContentExists } from 'client/utilities/utilities-cms';
import { parseUrlId } from 'client/utilities/utilities-url';
import { getParams } from 'client-utils/utilities-router';
import {
  ChanelBanner,
} from 'aClientComponents';
import ECMGet from 'ecm/components/EcmGet';
import { ECMSlots } from 'ecm/constants';
import Seo from 'plp/components/ProductListPage/components/Seo/seo';
import Thumbnail from './components/thumbnail';
import './SC.scss';

export const SC = (props) => {
  const { routeParams = {} } = props;
  let categoryId;
  switch (props.plpSeoUrlToggle) {
    case true:
      categoryId = routeParams.categoryInfo
        ? parseUrlId(routeParams.categoryInfo)
        : get(routeParams, 'categoryId', '').split('_').slice(0, 1).toString();
      break;
    case false:
      categoryId = get(routeParams, 'categoryId', '').split('_').slice(0, 1).toString();
      break;
    default:
      categoryId = get(routeParams, 'categoryId', '').split('_').slice(0, 1).toString();
      break;
  }

  const { source } = getParams(props.location);
  const scDisplayTitle = props.boutiqueChild
    ? `${props.designerName} ${get(props.templateDetails, 'templateAttributes.dynamicContent.title', false)}`
    : get(props.templateDetails, 'templateAttributes.dynamicContent.title', false);
  const thumbnailsMapOver = get(props.templateDetails, 'templateAttributes.dynamicContent.thumbnails', []);
  const isBreadcrumbsAvailable = Boolean(get(props.templateDetails, 'templateAttributes.navigation.breadcrumbs', false));
  const isLeftNavAvailable = Boolean(get(props.templateDetails, 'templateAttributes.navigation.leftNavigation', false));
  const isEcmAvailable = !isEmpty(get(props.templateDetails, 'templateAttributes.ecmContent', false));
  const isChanelAvailable = (props.templateType === 'ChanelSC');
  const isSeoAvailable = !isEmpty(get(props.templateDetails, 'templateAttributes.dynamicContent.seoContent', false));
  const isThumbnailsAvailable = get(props.templateDetails, 'templateAttributes.dynamicContent.thumbnails', false);
  const graphicalHeader = get(props.templateDetails, 'templateAttributes.cmsContent[0].fields.l1Layouts[0].fields.components[0]', {});

  const thumbnailsToDisplay = isThumbnailsAvailable
    ? thumbnailsMapOver
      .map((curr, index) => {
        return (
          <Thumbnail
            key={index}
            id={curr.id}
            name={curr.name}
            imageUrl={curr.imageUrl}
            url={curr.url}
          />
        );
      })
    : false;
  const renderEcm = (
    ""
  );

  const renderBreadcrumbs = (
    <ConnectedBreadcrumb
      defaultCategoryId={categoryId}
      source={source}
    />
  );

  const renderLeftNavigation = (
    <ConnectedLeftNavigation
      routeParams={{ ...props.routeParams }}
      navpath={props.location.navpath}
      source={source}
    />
  );

  const renderSeoContent = isSeoAvailable ? (
    <Seo
      title={get(props.templateDetails, 'templateAttributes.dynamicContent.seoContent.title', null)}
      content={get(props.templateDetails, 'templateAttributes.dynamicContent.seoContent.description', null)}
      margin={46}
    />
  )
    : null;

  const breadcrumbsClass = classNames({
    SC__breadcrumbs_empty: !isBreadcrumbsAvailable,
    'mt-15': true,
    'templates-breadcrumb-bottom-margin': true,
  });

  const ecmClass = classNames({
    'grid-100': true,
    'mb-20': isChanelAvailable && isEcmAvailable,
  });

  const displayTitleClassName = classNames({
    SC__header__title: true,
  });

  return (
    <Fragment>
      <div className="grid-parent">
        <div className={ecmClass}>
          { isEcmAvailable && renderEcm }
        </div>
        {
          props.templateType === 'ChanelSC'
          && (
          <div className="SC__chanel_banner_container">
            <ChanelBanner />
          </div>
          )
        }
        <div className={breadcrumbsClass}>
          { isBreadcrumbsAvailable && renderBreadcrumbs }
        </div>
        <div className={classNames('full-width-graphic-header', {
          'mobile-positioning': props.isMobilePhone,
        })}
        >
          {cmsContentExists(graphicalHeader) && renderCMSContent(graphicalHeader, 'Top')}
        </div>
      </div>

      <div className="grid-parent">
        { isLeftNavAvailable && renderLeftNavigation }
        <div className="SC__dynamic_container grid-80 tablet-grid-80 mobile-grid-100 grid-parent">
          <div className="grid-100">
            <h1 className={displayTitleClassName} dangerouslySetInnerHTML={{__html: scDisplayTitle}}></h1>
            <section className="SC__display_title_decoration" />
          </div>
          <div className="SC__thumbnails_container grid-100">
            { thumbnailsToDisplay }
          </div>
          { renderSeoContent }
        </div>
      </div>
    </Fragment>
  );
};

export default SC;
