/* eslint-disable arrow-body-style */

const noindexCategoryPage = (categoryId, { filterOptions = 'null' }, config) => {
  const query = JSON.parse(filterOptions);
  return filterOptions
    && query && config && config.some(({ catId, ...conf }) => {
    return catId === categoryId && Object.entries(conf).every(([key, val]) => {
      const queryValue = query[key];
      return Array.isArray(queryValue)
        ? queryValue.includes(val)
        : queryValue === val;
    });
  });
};

export default noindexCategoryPage;
