import React, { Component } from 'react';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { setHeaderState, setGender } from 'shared/components/App/app-actions';
import { isTypeAheadOn, isCollapsedSearchBarOn } from 'srp/utils/srpUtils';
import { GENDER_PLACEMENT } from 'universal/constants/genderPlacement';
import {
  reloadBrTypeAhead, getNumPromos, isSpecialOffersPage, getNumPromoCards, getNumIncircleCards,
} from '../utils/HeaderUtils';
import CleanHeader from '../CleanHeader/cleanHeader';
import { logoutUcaProfileCookie } from '../../../../common/components/YourNeimans/components/utilities';

class HeaderController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenSizeDesktopInMobile: false,
      stickyThreshold: 80,
      numNotifications: 0,
      isAuthenticated: null,
      offersData: {},
    };
    this.toggleSearchDisplay = this.toggleSearchDisplay.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.pageYOffset = 0;
    this.handleMobileScreenRotate = this.handleMobileScreenRotate.bind(this);
    this.isIPhoneXOrientation = this.isIPhoneXOrientation.bind(this);
  }

  shouldBlurActiveElementForHeader() {
    const tagName = document.activeElement?.tagName || '';
    return !(tagName === 'INPUT' || tagName === 'IFRAME');
  }

  blurAllowedActiveElement() {
    this.shouldBlurActiveElementForHeader() && document.activeElement?.blur();
  }

  componentDidMount() {
    const {
      isMobilePhone, ecmTickerRef, setHeaderState, isBlueCoreTestOn, bluecoresiteAssignment,
    } = this.props;
    const { stickyThreshold } = this.state;
    window.addEventListener('orientationchange', this.handleMobileScreenRotate);
    if (isMobilePhone) {
      window.addEventListener('scroll', throttle(this.handleScroll, 90));
    }
    window.enableBluecoreSite = Boolean(isBlueCoreTestOn && bluecoresiteAssignment === 'b');
    const height = ecmTickerRef && ecmTickerRef.current !== null
      ? ecmTickerRef.current.clientHeight
      : 0;
    if (pageYOffset > stickyThreshold) {
      setHeaderState({
        tickerHeight: height,
        isTop: false,
        isStickyActive: true,
        isSearchExposed: true,
        hasOpenedSearch: false,
      });
    } else {
      setHeaderState({ tickerHeight: height });
    }
    this.handleMobileScreenRotate(true);

    this.updateHeaderNotifications();

    Hub.listen('auth', ({ payload: { event } }) => {
      if (event === 'signIn') {
        this.updateHeaderNotifications();
        this.setState({ isAuthenticated: true });
      } else if (event === 'signOut') {
        logoutUcaProfileCookie();
        const { isPromoNotificationsToggle } = this.props;
        if (isPromoNotificationsToggle) {
          this.setState({ numNotifications: 0 });
        }
        this.setState({ isAuthenticated: false });
      }
    });

    Auth.currentAuthenticatedUser({})
      .then(() => this.setState({ isAuthenticated: true }))
      .catch(() => this.setState({ isAuthenticated: false }));
  }

  static getDerivedStateFromProps(props, state) {
    const { isStickyActive, hasOpenedSearch, typeAheadExperience, uiHeaderToggle, isDomestic } = props;
    if (isStickyActive && hasOpenedSearch) {
      setTimeout(() => {
        reloadBrTypeAhead(typeAheadExperience, uiHeaderToggle, isDomestic);
      }, 500);
    }
    return state;
  }

  componentWillUnmount() {
    const { isMobilePhone } = this.props;
    window.removeEventListener(
      'orientationchange',
      this.handleMobileScreenRotate
    );
    if (isMobilePhone) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  updateHeaderNotifications = async () => {
    const {
      isPromoNotificationsToggle,
      giffCardNotificationToggle,
      incircleCardNotificationToggle,
      specialOffersPrompt,
    } = this.props;
    try {
      let totalCount = 0;
      let offersPrompt;
      if ((isPromoNotificationsToggle || specialOffersPrompt) && !isSpecialOffersPage()) {
        const offersData = await getNumPromos();
        const offerCount = offersData?.count;
        offersPrompt = offersData?.promptData;
        totalCount += offerCount;
      }
      if (giffCardNotificationToggle) {
        const promoCardCount = await getNumPromoCards();
        totalCount += promoCardCount;
      }
      if (incircleCardNotificationToggle) {
        const incircleCardCount = await getNumIncircleCards();
        totalCount += incircleCardCount;
      }
      this.setState({ numNotifications: totalCount, offersData: offersPrompt });
    } catch (error) {
      this.setState({ numNotifications: 0, offersData: [] });
    }
  };

  handleMobileScreenRotate(onFirstLoad) {
    const { isMobilePhone, browser, typeAheadExperience, uiHeaderToggle } = this.props;
    const thisContext = this;
    if (isMobilePhone) {
      if (typeof onFirstLoad === 'boolean' && window.innerWidth < 400) {
        this.setState((prevState) => ({
          ...prevState,
          screenSizeDesktopInMobile: false,
        }));
      } else if (typeof onFirstLoad === 'boolean' && window.innerWidth > 768) {
        this.setState((prevState) => ({
          ...prevState,
          screenSizeDesktopInMobile: true,
        }));
      }
      setTimeout(() => {
        if (
          typeof onFirstLoad !== 'boolean'
          && ((thisContext.isIPhoneXOrientation() && window.innerWidth === 812)
            || (browser === 'Safari' && window.innerWidth > 768)
            || (browser === 'Chrome' && window.innerWidth < 768))
        ) {
          thisContext.setState((prevState) => ({
            ...prevState,
            screenSizeDesktopInMobile: true,
          }));
        } else if (typeof onFirstLoad !== 'boolean') {
          thisContext.setState((prevState) => ({
            ...prevState,
            screenSizeDesktopInMobile: false,
          }));
        }
        reloadBrTypeAhead(typeAheadExperience, uiHeaderToggle, isDomestic);
      }, 200);
    }
  }

  toggleSearchDisplay() {
    const { setHeaderState, isSearchExposed, hasOpenedSearch } = this.props;
    setHeaderState({
      isSearchExposed: !isSearchExposed,
      hasOpenedSearch: !hasOpenedSearch,
      addFocus: !isSearchExposed,
    });
  }

  isViewportAboveStickyThreshold() {
    const { setHeaderState, isTop } = this.props;
    const { stickyThreshold } = this.state;
    let continueScrollCheck;
    if (window.pageYOffset < stickyThreshold && !isTop) {
      this.blurAllowedActiveElement();
      this.props.setHeaderState({
        isStickyActive: true,
        isSearchExposed: true,
        isTop: true,
        addFocus: false,
      });
      continueScrollCheck = true;
    }

    if (window.pageYOffset > stickyThreshold && isTop) {
      this.blurAllowedActiveElement();
      setHeaderState({ isStickyActive: false, isTop: false });
      continueScrollCheck = false;
    }
    return continueScrollCheck;
  }

  scrollUpBehavior(
    isStickyActive,
    hasOpenedSearch,
    setHeaderState,
    continueScrollCheck
  ) {
    if (!isStickyActive && !continueScrollCheck) {
      setHeaderState({
        isStickyActive: true,
        isSearchExposed: hasOpenedSearch,
      });
    }
  }

  scrollDownBehavior(isStickyActive, isTop, setHeaderState) {
    if (isStickyActive && !isTop) {
      this.blurAllowedActiveElement();
      setHeaderState({
        isStickyActive: false,
        isSearchExposed: false,
        addFocus: false,
      });
    }
  }

  handleScroll() {
    const continueScrollCheck = this.isViewportAboveStickyThreshold();
    const {
      isStickyActive,
      hasOpenedSearch,
      isTop,
      setHeaderState,
    } = this.props;
    const scrolledUp = this.pageYOffset > window.pageYOffset;
    if (scrolledUp) {
      this.scrollUpBehavior(
        isStickyActive,
        hasOpenedSearch,
        setHeaderState,
        continueScrollCheck
      );
    } else {
      this.scrollDownBehavior(isStickyActive, isTop, setHeaderState);
    }
    this.pageYOffset = window.pageYOffset;
  }

  isIPhoneXOrientation() {
    const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
    const aspect = window.screen.width / window.screen.height;
    if (
      iPhone
      && (aspect.toFixed(3) === '0.462' || aspect.toFixed(3) === '2.165')
    ) {
      return true;
    }
    return false;
  }

  render() {
    const {
      isMobilePhone,
      hasOpenedSearch,
      isSearchExposed,
      tickerComponent,
      isStickyActive,
      tickerHeight,
      addFocus,
      pageId,
      isTop,
      user,
      panelToggle,
      giftFinderToggle,
      specialOffersPrompt,
      holidayLogoToggle,
      brand,
      hpGenderToggle,
      router,
      selectedGender,
      srpGenderToggle,
      typeAheadExperience,
      isMenDarkModeToggle,
      isDomestic,
      isImprovedPanelToggle,
      hcAssistanceToggle,
      globalNavUpdateToggle,
      isHNRightToggle,
      isCollapsedSearchBarToggle
    } = this.props;
    const defaultQuery = {
      from: '',
      responsive: '',
      request_type: '',
      search_type: '',
      q: '',
      l: '',
      fl: '',
    };
    const q = router ? router.location.query : defaultQuery;
    const {
      screenSizeDesktopInMobile,
      numNotifications,
      isAuthenticated,
      offersData,
    } = this.state;
    const isMenGender = selectedGender === 'M';
    const headerComponent = (
      <CleanHeader
        isMobilePhone={isMobilePhone}
        toggleSearchDisplay={this.toggleSearchDisplay}
        hasOpenedSearch={hasOpenedSearch}
        isSearchExposed={isSearchExposed}
        tickerComponent={tickerComponent}
        isStickyActive={isStickyActive}
        tickerHeight={tickerHeight}
        addFocus={typeAheadExperience ? false : addFocus}
        pageId={pageId}
        isTop={isTop}
        user={user}
        screenSizeDesktopInMobile={screenSizeDesktopInMobile}
        panelToggle={panelToggle}
        specialOffersPrompt={specialOffersPrompt}
        giftFinderToggle={giftFinderToggle}
        holidayLogoToggle={holidayLogoToggle}
        brand={brand}
        typeAheadExperience={typeAheadExperience}
        isMenDarkMode={hpGenderToggle && isMenDarkModeToggle && isMenGender}
        showGenderToggle={hpGenderToggle}
        onGenderSelected={(gender) => {
          this.props.setGender(gender);
          if (pageId !== 'PAGE_ID_SRP') {
            document.location.href = gender === 'W' ? '/' : '/mens';
          } else if (hpGenderToggle && !srpGenderToggle) {
            document.location.href = gender === 'W' ? '/' : '/mens';
          } else {
            const nextHref = `${'/s/'}?from=${
              q.from
            }&responsive=${q.responsive}&request_type=${
              q.request_type
            }&search_type=${q.search_type}&q=${q.q}&l=${q.l}&fl=${q.fl}`;
            window.location.href = nextHref;
          }
        }}
        selectedGender={selectedGender}
        isDomestic={isDomestic}
        numNotifications={numNotifications}
        isImprovedPanelToggle={isImprovedPanelToggle}
        isAuthenticated={isImprovedPanelToggle ? isAuthenticated : true}
        hcAssistanceToggle={hcAssistanceToggle}
        globalNavUpdateToggle={globalNavUpdateToggle}
        offersData={offersData}
        isHNRightToggle={isHNRightToggle}
        isCollapsedSearchBarToggle={isCollapsedSearchBarToggle}
      />
    );
    return (
      <>
        {this.props.children}
        <header>{headerComponent}</header>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const isDomestic = get(state, 'locale.countryCode', 'US') === 'US';

  return {
    isMobilePhone: state.device.isMobilePhone,
    hasOpenedSearch: state.header.hasOpenedSearch,
    isSearchExposed: state.header.isSearchExposed,
    isStickyActive: state.header.isStickyActive,
    tickerHeight: state.header.tickerHeight,
    addFocus: state.header.addFocus,
    browser: state.device.browser,
    pageId: state.page.pageId,
    isTop: state.header.isTop,
    user: state.user,
    giftFinderToggle: get(state, 'toggles.GIFT_FINDER_ICON', false),
    holidayLogoToggle: get(state, 'toggles.NM_HOLIDAY_GLOBAL_LOGO', false),
    brand: state.brand_name?.env,
    panelToggle: get(state, 'toggles.YOUR_NEIMANS_PANEL', false) && isDomestic,
    specialOffersPrompt: get(state, 'toggles.SPECIAL_OFFERS_PROMPT', false),
    hpGenderToggle: get(state, 'toggles.HP_GENDER', false) && isDomestic,
    srpGenderToggle: get(state, 'toggles.SRP_GENDER', false),
    typeAheadExperience: isTypeAheadOn(state),
    isMenDarkModeToggle: get(state, 'toggles.MEN_DARKMODE', false),
    isPromoNotificationsToggle: state?.toggles?.PROMO_NOTIFICATIONS,
    isImprovedPanelToggle: state?.toggles?.IMPROVED_PANEL_CTA,
    isSpecialOffersPage: state.profile?.personalizedPromoPage?.onPersonalPromoPage,
    isDomestic,
    hcAssistanceToggle: get(state, 'toggles.HC_ASSISTANCE_UI', false),
    giffCardNotificationToggle: get(state, 'toggles.GIFT_CARD_NOTIFICATIONS', false),
    incircleCardNotificationToggle: get(state, 'toggles.INCIRCLE_NOTIFICATIONS', false),
    isBlueCoreTestOn: get(state, 'toggles.BLUECORE_TEST', false),
    bluecoresiteAssignment: get(state, 'abTestsOpt.nmbc0001.variation', false),
    globalNavUpdateToggle: get(state, 'toggles.GLOBAL_NAV_UPDATE', false),
    uiHeaderToggle: get(state, 'toggles.UI_HEADER', false),
    isHNRightToggle: get(state, 'toggles.HN_RIGHT', false)
      || get(state, 'abTestsOpt.tl228.variation', 'a') === 'b',
    isCollapsedSearchBarToggle: isCollapsedSearchBarOn(state)
  };
};

const mapDispatchToProps = {
  setHeaderState,
  setGender: (gender) => setGender(gender, GENDER_PLACEMENT.HEADER),
};

HeaderController.propTypes = {
  isMobilePhone: PropTypes.bool,
  hasOpenedSearch: PropTypes.bool,
  isSearchExposed: PropTypes.bool,
  tickerComponent: PropTypes.shape({ current: PropTypes.any }),
  isStickyActive: PropTypes.bool,
  tickerHeight: PropTypes.number,
  addFocus: PropTypes.bool,
  pageId: PropTypes.string,
  isTop: PropTypes.bool,
  user: PropTypes.object,
  ecmTickerRef: PropTypes.shape({ current: PropTypes.any }),
  setHeaderState: PropTypes.func,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderController));
