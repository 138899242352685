import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import window from 'window-or-global';
import { DEFAULT_REQUEST_OPTIONS, EXP_HOURS_FOR_TWILIO_PROACTIVE } from 'plp/constants';
import { shouldLoad } from 'universal/http-client';
import { parseUrlId } from 'client/utilities/utilities-url';
import { getParams } from 'client-utils/utilities-router';
import {
  ChanelBanner,
  FloatGrid,
} from 'aClientComponents';
import ConnectedBreadcrumb from 'storefront/components/Navigation/Breadcrumb/breadcrumb';
import ScrollButton from 'shared/components/ScrollButton/scrollButton';
import {
  setPageId,
  PAGE_ID_PLP,
  ABTEST_FOUNDIT_VN,
  ABTEST_FOUNDIT_VN_NEW,
  ABTEST_FACET_BUTTONS,
} from 'shared/actions/actions-page';
import { openProactiveChatOnEvent, isEnableProactiveChat } from 'client/utilities/twilioUtils';
import ConnectedProductList from './components/ProductList/productList';
import ConnectedFacets from './components/Facets/Facets';
import {
  getPlpCategory,
  getPlpOptions,
  getPlpCategoryWithSpecificRecs,
  getPlpCategoryWithTests,
  initializeSwatchValues,
  updateCatId,
  updateInStoreFacetAvailability,
  fetchRecentlyViewedProductsData,
  attributionClientVisit,
} from './actions';
import { getECMRequest } from '../../../ecm/actions/actions-ecmcontent';
import './productListPage.scss';
import { renderCMSContent, cmsContentExists, hasContentType, checkCmsBannerPosition } from '../../../utilities/utilities-cms';
import { getCategoryTitle, modifyBreadCrumbs, getNmSeoContent } from './components/ProductList/components/ProductListHeader/ProductListHeader';


export function getRequestOptions(props) {
  const {
    routeParams = {},
    location = {},
    enforceMaxPageToggle = false,
    seoToggle = false,
    maxPageForAll = 999,
  } = props;
  const categoryId = routeParams.categoryInfo
    ? parseUrlId(routeParams.categoryInfo)
    : get(routeParams, 'categoryId', '').split('_').slice(0, 1).toString();
  let parentCategoryId = routeParams.categoryInfo
    ? ''
    : get(routeParams, 'categoryId', '').split('_').slice(1, 2).toString();

  const navPathValue = get(location, 'query.navpath', '');

  const navPath = Array.isArray(navPathValue) ? navPathValue : navPathValue.split('_');

  const siloCategoryId = navPath.length > 1 ? navPath[1] : '';
  if (!parentCategoryId) {
    parentCategoryId = navPath.length > 1 ? navPath[navPath.length - 2] : '';
  }
  const { sortBy, filterOptions, priorityProdId } = getParams(location);
  let { page, ...remainingParams } = getParams(location);
  if (enforceMaxPageToggle && page > maxPageForAll) page = 1;
  if (!seoToggle) remainingParams = null;
  return {
    ...DEFAULT_REQUEST_OPTIONS,
    parentCategoryId,
    ...omitBy({
      categoryId,
      sortBy,
      page,
      filterOptions,
      siloCategoryId,
      priorityProdId,
      remainingParams,
    }, isEmpty),
  };
}

export function iOSChromeScrollRestoration() {
  window.addEventListener('pagehide', () => {
    window.sessionStorage.setItem('plpScrollPosition', window.pageYOffset);
  });

  const isComingBackFromPDP = window.sessionStorage.getItem('visitedPDPpage');
  const plpScrollPosition = window.sessionStorage.getItem('plpScrollPosition');

  if (isComingBackFromPDP && plpScrollPosition > 0) {
    window.scrollTo(0, plpScrollPosition);
    window.sessionStorage.removeItem('visitedPDPpage');
  }
}

export class DumbProductListPage extends Component {
  constructor() {
    super();
    this.state = {
      titleWidth: 0
    }
    this.fallback = false;
  }

  componentDidMount() {
    (this.props.founditVisualNavTest) && window.FI_Core?.load();
    const iOS = !!navigator.platform && /iPad|iPhone/.test(navigator.platform);
    if (iOS && this.props.deviceBrowser === 'Chrome') {
      iOSChromeScrollRestoration();
    }

    if (this.props.isScrollFixToggleOn && this.props.deviceBrowser === 'Safari') {
      const isComingBackFromPDP = window.sessionStorage.getItem('visitedPDPpage');
      window.sessionStorage.setItem('isScrollFixToggleOn', 'true');
      isComingBackFromPDP !== 'true' && window.sessionStorage.setItem('initialPLPload', 'true');
      window.sessionStorage.setItem('isSrpScrollFix', 'false');
    } else if (window.sessionStorage) {
      window.sessionStorage.setItem('isScrollFixToggleOn', 'false');
      window.sessionStorage.setItem('isSrpScrollFix', 'false');
    }

    if (this.props.hideSwatchOnErrorToggle === true) {
      this.props.initializeSwatchValues();
    }

    if (
      this.props.isMobilePhone
      && typeof window !== 'undefined'
      && window.utag_data_dt
    ) {
      window.utag_data_dt = {
        ...window.utag_data_dt,
        csp_toggle: this.props.csp_toggle.toString(),
      };
    }

    const {
      twilioChatToggle, catId, proactiveChatCategories, isMobilePhone, proactiveChatDelay,
    } = this.props;
    const eventType = proactiveChatCategories[catId];

    if (!isMobilePhone && twilioChatToggle && isEnableProactiveChat(EXP_HOURS_FOR_TWILIO_PROACTIVE) && eventType) {
      openProactiveChatOnEvent({
        eventType,
        currentUrl: window.location.href,
        expHoursForTwilioProactive: EXP_HOURS_FOR_TWILIO_PROACTIVE,
        proactiveChatDelay,
      });
    }

    this.props.fetchRecentlyViewedProductsData();

    const cvId = get(this.props.location, 'query.cv_id', '');

    if (cvId) {
      setTimeout(() => attributionClientVisit(cvId), 3000);
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.apiState.loading) {
      return false;
    }
    return true;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location: prevLocation = {}, router = {} } = this.props;
    const isSPA = get(router, 'location.state.spa', false);
    const prevOptions = getRequestOptions(this.props);
    const nextOptions = getRequestOptions(nextProps);

    if (prevOptions.categoryId !== nextOptions.categoryId) {
      this.props.updateCatId(nextOptions.categoryId);
    }

    if (this.fallback) {
      this.fallback = false;
      return;
    }
    if (!isEqual(prevOptions, nextOptions) && !isSPA) {
      this.props.getPlpOptions(nextOptions, router.location)
        .catch(() => {
          this.fallback = true;
          router.replace(prevLocation);
        });
    }
  }

  UNSAFE_componentWillMount() {
    if (shouldLoad(this.props.apiState)) {
      const {
        useInternalAbTestService,
      } = this.props;
      const requestOptions = getRequestOptions(this.props);
      if (useInternalAbTestService) {
        const TEST_IDS = [];
        if (TEST_IDS.length > 0) {
          this.props.getPlpCategoryWithTests(requestOptions, TEST_IDS);
        } else {
          this.props.getPlpCategory(requestOptions);
        }
      } else {
        const MBOX_IDS = [];
        if (MBOX_IDS.length > 0 && shouldLoad(this.props.abTestApi)) {
          this.props.getPlpCategoryWithSpecificRecs(requestOptions, MBOX_IDS);
        } else {
          this.props.getPlpCategory(requestOptions);
        }
      }
    }
    this.props.setPageId(PAGE_ID_PLP);
  }

  setSeoWidth = (width) => {
    this.setState({ titleWidth: width });
  }

  render() {
    const {
      pageTitleAvailable,
      hideBreadcrumbs,
      templateType,
      cmsContent,
      boutiqueChild,
      title,
      designerName,
      isMobilePhone,
      prevCategory,
      currentNavPath,
      newChanelToggle,
      isDomestic,
      customHeaderCategories,
      isInStoreFacetAvailable,
      updateInStoreFacetAvailability,
      subTitle, 
      nmAwardToggle,
      founditVNToggle,
      founditVisualNavTest,
      showVisualNav,
      mobileFacetButtonsAbTest,
      boutiquePage,
      womenApparelCat,
      cmsEntriesContent,
      nmSeoCopyToggle,
      seoCopyContent,
    } = this.props;

    const categoryId = this.props.routeParams.categoryInfo
      ? parseUrlId(this.props.routeParams.categoryInfo)
      : get(this.props.routeParams, 'categoryId', '').split('_').slice(0, 1).toString();
    const parentCategoryId = this.props.routeParams.categoryInfo
      ? ''
      : get(this.props.routeParams, 'categoryId', '').split('_').slice(1, 2).toString();

    const navpath = this.props.routeParams.categoryInfo
      ? parseUrlId(this.props.routeParams.categoryInfo)
      : getParams(this.props.location).navpath;

    const { source } = getParams(this.props.location);
    const TEMPLATE_TYPE_CHANELP3 = 'ChanelP3';

    const hideVisualNav = isMobilePhone && !boutiquePage && !womenApparelCat;
    const loadFoundIt = (founditVNToggle || founditVisualNavTest)
    && hasContentType(cmsContent.fields?.l1Layouts || [], 'visualNav') 
    && !hideVisualNav;
    const founditVisualNavTestGroup = founditVisualNavTest
    && hasContentType(cmsContent.fields?.l1Layouts || [], 'visualNav') 
    && !hideVisualNav;

    let hasVisualNavOrPromoBanner = false;
    if (nmSeoCopyToggle) {
      const l1Layouts = cmsContent.fields?.l1Layouts || [];
      if (hasContentType(l1Layouts, 'visualNav') && !hideVisualNav && mobileFacetButtonsAbTest) {
        hasVisualNavOrPromoBanner = true;
      } else if (checkCmsBannerPosition(cmsEntriesContent, "Top")) {
        hasVisualNavOrPromoBanner = true;
      } else if(!isNull(subTitle) && nmAwardToggle){
        hasVisualNavOrPromoBanner = true;
      }
    }

    return (
      <FloatGrid className="is-full-width nav-float-grid" parent desktop="100" tablet="100" mobile="100">
        <div className={classNames('nav-container', { 'nav-left-shift-seo': nmSeoCopyToggle })}>
          <ConnectedBreadcrumb
            defaultCategoryId={categoryId}
            source={source}
            hideOnMobile={pageTitleAvailable && hideBreadcrumbs}
          />

          {
            isMobilePhone && prevCategory && (
            <div className={classNames("product-list__header__visualnav__breadcrumb")}
            >
              <button
                className="hide-on-desktop hide-on-tablet"
                onClick={() => { window.location.href = prevCategory.url; }}
              >
                <div>{currentNavPath && prevCategory.nameForMobile}</div>
              </button>
            </div>
            )
          }

          <div className={classNames('container-title mobile-container-title')}>
            {getCategoryTitle(classNames('product-list__header__pagetitle'),
              boutiqueChild,
              title,
              designerName,
              isDomestic,
              customHeaderCategories,
              categoryId,
              subTitle,
              nmAwardToggle,
              nmSeoCopyToggle,
              !isNull(seoCopyContent) ? this.state.titleWidth : null
              )}
          </div>

          {nmSeoCopyToggle && getNmSeoContent(seoCopyContent, isMobilePhone, hasVisualNavOrPromoBanner, this.setSeoWidth)}

          {templateType === TEMPLATE_TYPE_CHANELP3 && (
            <FloatGrid desktop="100" tablet="100" mobile="100">
              <ChanelBanner newChanelToggle={newChanelToggle} />
            </FloatGrid>
          )}

          {
            <div className={classNames('full-width-graphic-header', { 'mobile-positioning': isMobilePhone })}>
              {cmsContentExists(cmsContent) && renderCMSContent(cmsContent, 'Top', founditVNToggle, founditVisualNavTestGroup)}
              {(!founditVNToggle && founditVisualNavTestGroup) && <div className="header-foundit-plp"></div> }
            </div>
          }

          {
            <ConnectedFacets
              router={this.props.router}
              routeParams={{
                ...this.props.routeParams,
                categoryId,
                parentCategoryId,
              }}
              navpath={navpath}
              source={source}
              isInStoreFacetAvailable={isInStoreFacetAvailable}
              toggleInStoreFacetAvailability={updateInStoreFacetAvailability}
            />
          }
        </div>
        <ConnectedProductList
          routeParams={{
            ...this.props.routeParams,
            categoryId,
            parentCategoryId,
          }}
          isInStoreFacetAvailable={isInStoreFacetAvailable}
          loadFoundIt={loadFoundIt}
        />
        <ScrollButton isMobilePhone={this.props.isMobilePhone} filterSortToggle={this.props.filterSortToggle} abtestFilterSortToggle={this.props.abtestFilterSortToggle} abTestsFilterSortValue={this.props.abTestsFilterSortValue} />
      </FloatGrid>
    );
  }
}

export const isBoutiquePLP = (state) => {
  const boutiqueChild = get(state, 'templates.templateDetails.boutiqueChild', false)
      || get(state, 'productListPage.products.boutiqueChild', false);
  const boutiqueFlag = get(state, 'utag.productListPage.page_type') === 'boutique';
  return boutiqueChild || boutiqueFlag;
}

export const isWomenApparelCat = (state) => {
  const defaultPath = get(state, 'templates.templateDetails.defaultPath', '');
  const navPath = get(state, 'page.location.query.navpath', '');
  return (defaultPath || navPath || "").includes(NMConfig.WOMEN_APPAREL_CAT_ID) || false;
}

const mapStateToProps = (state) => {
  const breadcrumbs = get(state, 'navigation.breadcrumbs', '');
  const breadcrumbsForPrevTitle = modifyBreadCrumbs(breadcrumbs, get(state, 'leftNavigation.id', ''));
  const prevCategory = breadcrumbsForPrevTitle[breadcrumbsForPrevTitle.length - 2];
  const isDomestic = get(state, 'locale.countryCode') === 'US';

  return {
    apiState: state.api.product_list,
    abTestApi: state.api.abtest,
    crpApiState: state.api.crp,
    templateType: get(state, 'productListPage.products.templateType', 'P3'),
    apiForEcmSlot: state.api.ecmplpPromoTile,
    pageTitleAvailable: get(state.productListPage.products, 'title', false),
    hideBreadcrumbs: get(state.toggles, 'PLP_HIDE_BREADCRUMBS', false),
    page: state.productListPage.products.currentPage,
    enforceMaxPageToggle: state.toggles.ENFORCE_MAX_PAGE,
    maxPageForAll: state.productCatalog.maxPageForAll,
    useInternalAbTestService: state.toggles.USE_INTERNAL_ABTEST_SERVICE,
    abTestIds: state.abTestIds,
    cmsContent: get(state, 'cms.entries[0]', {}),
    excludeFromPCS: get(state, 'templates.templateDetails.templateAttributes.dynamicContent.categoryFlags.excludeFromPCS', false),
    hideSwatchOnErrorToggle: state.toggles.HIDE_SWATCH_ON_ERROR,
    isCrp: state.toggles.CRP,
    isScrollFixToggleOn: state.toggles.SCROLL_FIX_IOS_SAFARI,
    deviceBrowser: state.device.browser,
    topContentApi: state.api.top_content,
    topContent: get(state, 'cms.topContent', []),
    cmsEntriesApi: state.api.cms_entries,
    title: state.productListPage.products.title,
    boutiqueChild: get(state, 'templates.templateDetails.boutiqueChild', false)
      || get(state, 'productListPage.products.boutiqueChild', false),
    designerName: get(state, 'navigation.breadcrumbs[1].name', ''),
    isMobilePhone: get(state, 'device.isMobilePhone', false),
    currentNavPath: get(state, 'routing.locationBeforeTransitions', null),
    prevCategory,
    csp_toggle: get(state, 'facetedLeftNav.facetedFiltersList[\'In Store\'].csp', 'false'),
    newChanelToggle: get(state, 'toggles.NEW_CHANEL_LOGO', false),
    catId: get(state, 'templates.templateDetails.id', false),
    twilioChatToggle: get(state, 'toggles.TWILIO_PROACTIVE_CHAT_PLP', false),
    proactiveChatCategories: get(state?.session, 'proactiveChatCategoryMap', {}),
    proactiveChatDelay: get(state, 'session.proactiveChatDelay', 30000),
    seoToggle: state.toggles.SEO_BUCKET_ONE,
    filterSortToggle: state.toggles.FILTER_SORT,
    abtestFilterSortToggle: state.toggles.ABTEST_FILTER_SORT,
    abTestsFilterSortValue: get(state, 'abTestsOpt.nmfs0001.variation', ''),
    isDomestic: get(state, 'locale.countryCode') === 'US',
    customHeaderCategories: get(state?.session, 'customHeaderCategoriesMap', {}),
    isInStoreFacetAvailable: get(state, 'productListPage.products.hasInStoreFacet', false),
    nmAwardToggle: get(state, 'toggles.NM_AWARDS', false),
    subTitle: get(state, 'productListPage.products.subTitle', null),
    founditVNToggle: get(state, 'toggles.FOUNDIT_VN', false) || (get(state, 'toggles.ABTEST_FOUNDIT_VN_NEW', false) && get(state, `abTestsOpt.${ABTEST_FOUNDIT_VN_NEW}.variation`, 'a') === 'b'),
    founditVisualNavTest: get(state, 'toggles.ABTEST_FOUNDIT_VN', false) && get(state, `abTestsOpt.${ABTEST_FOUNDIT_VN}.variation`, 'a') === 'b',
    boutiquePage: isBoutiquePLP(state),
    womenApparelCat: isWomenApparelCat(state),
    cmsEntriesContent: get(state, 'cms.entries[0].fields.l1Layouts', []),
    nmSeoCopyToggle: isDomestic && get(state, 'toggles.NM_SEO_COPY', false),
    seoCopyContent: get(state, 'productListPage.seo.content', null),
    mobileFacetButtonsAbTest: get(state, `abTestsOpt.${ABTEST_FACET_BUTTONS}.variation`, 'a') === 'b',
  };
};

const mapDispatchToProps = {
  getPlpCategory,
  getPlpOptions,
  setPageId,
  getECMRequest,
  getPlpCategoryWithSpecificRecs,
  getPlpCategoryWithTests,
  initializeSwatchValues,
  updateCatId,
  updateInStoreFacetAvailability,
  fetchRecentlyViewedProductsData,
};

export default connect(mapStateToProps, mapDispatchToProps)(DumbProductListPage);
