import React from 'react';
import RenderContentItem from 'cms/components/renderContentItem';
import isEmpty from 'lodash/isEmpty';

export const renderCMSContent = (cmsContent, placement, founditVisualNav = false,  founditVisualNavTest = false) => (
  cmsContent
    ? (
      <>
        <RenderContentItem cmsContentItem={cmsContent} placement={placement} founditVisualNav={founditVisualNav} founditVisualNavTest={founditVisualNavTest} />
        <div className="cms-spacer" />
      </>
    )
    : null
);

export const cmsContentExists = (cmsContent) => {
  const keys = Object.keys(cmsContent);
  return cmsContent && keys.includes('contentType') && keys.includes('fields') && keys.includes('sys');
};

export const placementExists = (cmsContent, name) => {
  let content = {};
  if (cmsContent?.fields?.l1Layouts) {
    content = cmsContent.fields.l1Layouts.find(
      ({ fields: { placement } }) => placement === name
    );
  }
  return !isEmpty(content);
};

export const isGlobalBottomBanner = (globalContent) => {
  let globalBanner = {};

  if (globalContent?.fields?.l1Layouts) {
    globalBanner = globalContent?.fields?.l1Layouts.find(({ fields: { placement } }) => placement === 'Bottom');
  }

  return !isEmpty(globalBanner);
};

export const getImageUrl = (product) =>
  product?.media?.main?.dynamic?.url || product?.media?.main?.medium?.url;

export const setStylyzeUrl = (product) => ({
  ...product,
  stylyzeImgUrl: getImageUrl(product),
});

export const hasContentType = (array, targetContentType) => {
  
  for (const obj of array) {
      if (obj.fields && typeof obj.fields === 'object') {
          if (obj.fields.components && Array.isArray(obj.fields.components)) {
              for (const component of obj.fields.components) {
                  if (component.contentType === targetContentType) {
                      return true;
                  }
              }
          }
          
          if (hasContentType(obj.fields.components || [], targetContentType)) {
              return true;
          }
      }
  }
  
  return false;
}

export const checkCmsBannerPosition = (entries, position) => {
  if (!entries) return false;

  const checkComponents = (components) => {
    if (!Array.isArray(components)) return false;

    for (const component of components) {
      if (
        component.contentType === 'imageContentAsset' ||
        component?.fields?.content?.contentType === 'imageContentAsset'
      ) {
        return true;
      }
    }
    return false;
  };

  for (const entry of entries) {
    if (!entry.fields || typeof entry.fields !== 'object') continue;

    const { placement, l2Layout, components } = entry.fields;

    if (placement === position) {
      if (checkComponents(components)) {
        return true;
      }

      if (Array.isArray(l2Layout)) {
        for (const layout of l2Layout) {
          if (checkComponents(layout?.fields?.components)) {
            return true;
          }
        }
      }
    }
  }

  return false;
};
