import React from 'react';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import classnames from 'classnames';
import { unescape } from 'he';
import { LEFT_NAV } from 'storefront/components/constants';
import { referralSourceClicked } from 'client/common/actions/actions-page';
import LeftNavTreeNode from '../LeftNavTreeNode/leftNavTreeNode';
import LeftNavRefreshableContent from '../leftNavRefreshableContent';

const setPrevPageCookie = (name) => {
  referralSourceClicked(name, LEFT_NAV);
};

const LeftNavSubTree = ({ subTree, boutiqueLeftNav, refreshableContentSrc, templateType }) => {
  if (isEmpty(subTree)) return null;
  return (
    <ul className={classnames('left-nav__category', { 'transform-text': templateType === 'ChanelEntry' })}>
      {
        subTree.map((treeNode) => (
          <LeftNavTreeNode
            key={treeNode.id}
            node={treeNode}
          />
        ))
      }
      {
        !isNull(refreshableContentSrc)
        && !isEmpty(refreshableContentSrc)
        && <LeftNavRefreshableContent refreshableContentSrc={refreshableContentSrc} />
      }
      {
        !isEmpty(boutiqueLeftNav) && boutiqueLeftNav.map((treeNode) => (
          <li>
            <a
              id={`leftNav-${treeNode.id}`}
              href={treeNode.url}
              className="left-nav__category"
              onClick={() => setPrevPageCookie(treeNode.name)}
            >
              { unescape(treeNode.name.trim()) }
            </a>
          </li>
        ))
      }
    </ul>
  );
};

export default LeftNavSubTree;
