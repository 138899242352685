import cookie from 'react-cookie';

const defaultQuery = {
  associatePin: '',
  chanel: 'nm',
  osp_lxts: 0,
};

const setConnectCookie = (query = defaultQuery, expDaysForOSP = 14) => {
  const {
    associatePin, channel, osp_lxts: honorTimestamp, cv_id: v2Id,
  } = query;
  const currentTS = new Date().getTime();
  const validFormat = new Date(parseInt(honorTimestamp, 10)).getTime() > 0;
  const validTime = validFormat && parseInt(honorTimestamp, 10) > currentTS;

  if (associatePin && channel && validTime && !v2Id) {
    const numberOfDays = expDaysForOSP;
    const nowPlusDays = new Date(
      Date.now() + numberOfDays * 24 * 60 * 60 * 1000,
    );
    cookie.save('assocInfo', `${associatePin}:${channel}`, {
      path: '/',
      expires: nowPlusDays,
    });
  } else if (v2Id) {
    cookie.remove('assocInfo', { path: '/' });
  }
};

export default setConnectCookie;
