import classNames from 'classnames';
import { ENTER_KEYCODE, ESC_KEYCODE } from 'client-utils/keyCodes';
import { isGiftCategory, isSaleSiloInRed } from 'client-utils/utilities-navigation';
import { isMobile } from 'client-utils/utilities-page';
import { referralSourceClicked } from 'client/common/actions/actions-page';
import RenderContentItem from 'cms/components/renderContentItem';
import RenderLayout from 'cms/components/renderLayout';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { Component } from 'react';
import HoverIntent from 'react-hoverintent';
import Measure from 'react-measure';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loseFocus, putComponentInFocus } from 'shared/components/App/app-actions';
import { ABTEST_SALE, CUSP_CAT_ID, DESIGNER_CAT_ID } from 'storefront/components/constants';
import CuspDrawer from './CuspDrawer/cuspDrawer';
import DesignersDrawer from './DesignersDrawer/designersDrawer';
import './drawer.scss';
import { determineAlignmentStyle } from './drawerPositioningHelper';
import FullWidthDrawer from './FullWidthDrawer/fullWidthDrawer';
import StandardDrawer from './StandardDrawer/standardDrawer';
import { ABTEST_BF_SALE } from '../../../../../common/actions/actions-page';
import { drawerControl } from 'cms/actions/index';
import { getGenderWithOverride } from 'client/utilities/utilities-gender';

const selectDrawerTypeBasedOnId = (siloId, drawerProps) => {
  switch (siloId) {
    case DESIGNER_CAT_ID: {
      return (<DesignersDrawer {...drawerProps} />);
    }
    case CUSP_CAT_ID: {
      return (<CuspDrawer {...drawerProps} />);
    }
    default:
      return (<StandardDrawer {...drawerProps} />);
  }
};

const getDrawerContent = (silo, globalDrawerContent) => {
  return globalDrawerContent?.[0]?.fields?.l2Layout.filter(
    (drw) => drw?.fields?.slugs?.indexOf(silo.id) !== -1)?.[0];
}

export const getPromoContent = (silo, globalDrawerContent, showGlobal) => {
  if (showGlobal) {
    const glblContent = getDrawerContent(silo, globalDrawerContent);
    if (glblContent) {
      return <RenderLayout cmsLayout={glblContent} />;
    }
  } else if (silo.attributes && silo.attributes.cms) {
    return <RenderContentItem cmsContentItem={silo.attributes.cms} placement="Main" />;
  }
  return false;
};

class Drawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerWidth: 0,
    };

    this.setWidth = this.setWidth.bind(this);
    this.siloHoverDelaytimer = null;
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps === this.props) {
      return false;
    }
    return true;
  }

  componentDidMount(){
    document.addEventListener('keydown', this.handleOnKeyDown);
  }

  componentWillUnmount() {
    clearTimeout(this.siloHoverDelaytimer);
    this.siloHoverDelaytimer = null;
    document.removeEventListener('keydown', this.handleOnKeyDown);
  }

  handleOnKeyDown = (e) => {
    if(e.keyCode === ESC_KEYCODE || e.which === ESC_KEYCODE){
      const { silo, closeDrawer, componentInFocus } = this.props;
  
      const classNameWithDrawerId = `.drawer-container-${silo.id}`;
      const isDrawerOpen = (componentInFocus === classNameWithDrawerId);
      const isSubCategoryAvailable = !isEmpty(silo.categories);
  
      if(isDrawerOpen && isSubCategoryAvailable){
        const focusSiloElement = document.querySelector(componentInFocus); 
        focusSiloElement.childNodes[0].setAttribute('aria-expanded', false);
        closeDrawer(classNameWithDrawerId);
      }
    }
  }

  setWidth(dimensions) {
    this.setState({ drawerWidth: dimensions.width });
  }

  getGiftSiloClassName(silo) {
    const { giftSiloToggle, globalNavUpdateToggle, selectedGender } = this.props;

    const giftCategory = isGiftCategory(silo, giftSiloToggle);
    let giftSiloClassName = '';

    if (globalNavUpdateToggle && giftCategory) {
      giftSiloClassName = 'gift-image-new-globalNav';

      if(selectedGender === 'M'){
        giftSiloClassName = `${giftSiloClassName} gift-image-new-globalNav__men`;
      } 
    } else if(giftCategory){
      giftSiloClassName = 'gift-image-new';
    }

    return giftSiloClassName;
  }

  render() {
    const DRAWER_PAGE_TYPE = 'drawer';
    const {
      renderDrawersOnDesktopOnly,
      silo,
      openDrawer,
      closeDrawer,
      componentInFocus,
      globalNavUpdateToggle,
      globalDrawerContent,
      globalDrawerContentToggle,
      dispatch,
      saleSiloAbTestToggle,
      bfSaleSiloAbTestToggle,
    } = this.props;
    const isSubCategoryAvailable = !isEmpty(silo.categories);
    const positioning = {
      drawerWidth: this.state.drawerWidth,
      navBarLeft: this.props.navBarLeft,
      navBarRight: this.props.navBarRight,
      siloLeft: this.siloRef ? this.siloRef.getBoundingClientRect().left : 0,
      siloRight: this.siloRef ? this.siloRef.getBoundingClientRect().right : 0,
    };

    const alignmentStyle = determineAlignmentStyle(positioning);
    const setWidth = this.setWidth;

    const classNameWithDrawerId = `.drawer-container-${silo.id}`;
    const isDrawerOpen = (componentInFocus === classNameWithDrawerId);

    const toggleDrawer = (e) => {
      if (isDrawerOpen) {
        closeDrawer(classNameWithDrawerId);
        window.location = silo.url;
        e.preventDefault();
      } else {
        openDrawer(classNameWithDrawerId);
        e.preventDefault();
      }
    };

    const validateAndSetCookie = (e, siloName) => {
      if (e.target.localName === 'a' || e.target.localName === 'span') {
        referralSourceClicked(siloName, DRAWER_PAGE_TYPE);
      }
    };

    const onCategoryKeyDown = (e, isSubCategoryAvailable, classNameWithDrawerId) => {
      const isTopLevelSilo = (e.target === this.siloRef.querySelector('.silo-link'));
      if (isDrawerOpen && isTopLevelSilo) return;
      if (isSubCategoryAvailable && e.which === ENTER_KEYCODE) {
        openDrawer(classNameWithDrawerId);
        if (isTopLevelSilo) e.preventDefault();
      } else if (e.which === ESC_KEYCODE) {
        closeDrawer(classNameWithDrawerId);
      }
    };

    const onFocusOut = (e) => {
      if (globalNavUpdateToggle){
        dispatch(drawerControl(false))
      }
      const offsetParent = (e.relatedTarget && e.relatedTarget.offsetParent) || '';
      const parentComp = (offsetParent === e.currentTarget.querySelector('.silo-group'));
      if (e.target.offsetParent === e.currentTarget.querySelector('.drawer-close') && !parentComp) {
        closeDrawer(this.props.componentInFocus);
      }
    };

    const drawerContent = getDrawerContent(silo, globalDrawerContent);
    const isContentTaggedForTestGroup = drawerContent?.fields?.testGroup === true;

    const drawerProps = {
      silo,
      style: alignmentStyle,
      closeFunction: () => closeDrawer(classNameWithDrawerId),
      delayHoverOnSilos: this.props.delayHoverOnSilos,
      getPromoContent: () => getPromoContent(silo, globalDrawerContent, globalDrawerContentToggle),
      getPlpSinglePage: {
        isSinglePageOn: this.props.isSinglePageOn,
        blackListedCategories: this.props.blackListedCategories,
        templateType: this.props.templateType,
        router: this.props.router,
      },
      globalNavUpdateToggle,
      isContentTaggedForTestGroup
    };
    const drawer = (this.props.isSiloDrawerFullWidth && silo.id !== DESIGNER_CAT_ID)
      ? (<FullWidthDrawer {...drawerProps} />)
      : selectDrawerTypeBasedOnId(silo.id, drawerProps);
    const openDrawerIfSubCategoryIsAvailableAndDrawerIsClosed = () => {
      if (globalNavUpdateToggle){
        dispatch(drawerControl(true))
        clearTimeout(this.siloHoverDelaytimer);
      }
      isSubCategoryAvailable && !isDrawerOpen
        ? openDrawer(classNameWithDrawerId) : false;
    };
    const closeDrawerIfAlreadyOpen = () => {
      if (globalNavUpdateToggle){
        dispatch(drawerControl(false))
        if (isDrawerOpen) {
          const defaultHoverTimeout = 300;
          const { timeout } = this.props.siloDrawerHoverIntent;
          this.siloHoverDelaytimer = setTimeout(() => {
            closeDrawer(classNameWithDrawerId);
          }, timeout || defaultHoverTimeout);
        }
      } else{
        isDrawerOpen ? closeDrawer(classNameWithDrawerId) : false;
      }
      
    };

    const renderNow = () => {
      let returnVal = true;
      if (renderDrawersOnDesktopOnly) {
        returnVal = !isMobile();
      }
      return returnVal;
    };

    if (this.props.delayHoverOnSilos) {
      return (
        <HoverIntent
          onMouseOver={openDrawerIfSubCategoryIsAvailableAndDrawerIsClosed}
          onMouseOut={closeDrawerIfAlreadyOpen}
          sensitivity={this.props.siloDrawerHoverIntent.sensitivity || 5}
          interval={this.props.siloDrawerHoverIntent.interval || 100}
          timeout={this.props.siloDrawerHoverIntent.timeout || 0}
        >
          <div
            ref={(ref) => { this.siloRef = ref; }}
            className={classNames(
              'make-relative',
              `drawer-container-${silo.id}`,
              { active: isDrawerOpen },
              { 'gift-image-border-new': isGiftCategory(silo, this.props.giftSiloToggle) },
              this.getGiftSiloClassName(silo),
            )}
            onClick={(e) => validateAndSetCookie(e, silo.name)}
            onKeyDown={(e) => onCategoryKeyDown(e, isSubCategoryAvailable, classNameWithDrawerId)}
            onBlur={onFocusOut}
          >
            <a
              className={classNames('silo-link', { 'silo-link__gift-image-link-new': isGiftCategory(silo, this.props.giftSiloToggle), 'silo-link__sale-silo-link-new': isSaleSiloInRed(silo, saleSiloAbTestToggle, bfSaleSiloAbTestToggle) })}
              itemProp="url"
              role="button"
              data-title={silo.name}
              aria-expanded={isDrawerOpen}
              href={silo.url}
              onTouchEnd={toggleDrawer}
            >
              <span itemProp="name">{silo.name}</span>
            </a>
            {renderNow() && drawer}
          </div>
        </HoverIntent>
      );
    }
    return (
      <div
        ref={(ref) => { this.siloRef = ref; }}
        className={classNames(
          'make-relative',
          `drawer-container-${silo.id}`,
          { active: isDrawerOpen },
          { 'gift-image-border-new': isGiftCategory(silo, this.props.giftSiloToggle) },
          this.getGiftSiloClassName(silo),
        )}
        onMouseEnter={openDrawerIfSubCategoryIsAvailableAndDrawerIsClosed}
        onMouseLeave={closeDrawerIfAlreadyOpen}
        onClick={(e) => validateAndSetCookie(e, silo.name)}
        onKeyDown={(e) => onCategoryKeyDown(e, isSubCategoryAvailable, classNameWithDrawerId)}
        onBlur={onFocusOut}
      >
        <a
          className={classNames('silo-link', { 'silo-link__gift-image-link-new': isGiftCategory(silo, this.props.giftSiloToggle) })}
          itemProp="url"
          role="button"
          data-title={silo.name}
          aria-expanded={isDrawerOpen}
          href={silo.url}
          onTouchEnd={toggleDrawer}
        >
          <span itemProp="name">{silo.name}</span>
        </a>
        {renderNow()
          && (
            <Measure whitelist={['width']} includeMargin={false} onMeasure={setWidth}>
              {drawer}
            </Measure>
          )
        }
      </div>
    );
  }
}

const isGlobalNavUpdateToggleEnabled = (state) =>{
  const isDomestic = get(state, 'locale.countryCode') === 'US';
  const globalNavUpdateToggle = get(state, 'toggles.GLOBAL_NAV_UPDATE', false );
  return isDomestic && Boolean(globalNavUpdateToggle);
}

const mapStateToProps = (state) => {
  const globalDrawerContentToggle = get(state, 'toggles.GLOBAL_SILO_ASSETS', false);
  let globalDrawerContent = [];
  if (globalDrawerContentToggle) {
    globalDrawerContent = get(state, 'cms.global[0].fields.l1Layouts', [])
      .filter((L1) => { return L1.fields.placement === 'Drawers'; });
  }
  return {
    navBarLeft: state.navigation.position.navBarLeft,
    navBarRight: state.navigation.position.navBarRight,
    componentInFocus: state.app.componentInFocus,
    giftSiloToggle: state.toggles.GIFT_SILO_IMAGE,
    renderDrawersOnDesktopOnly: get(state.toggles, 'RENDER_DRAWERS_ON_DESKTOP_ONLY', false),
    isSiloDrawerFullWidth: get(state, 'toggles.SILO_DRAWER_FULL_WIDTH', false),
    delayHoverOnSilos: get(state, 'toggles.DELAY_HOVER_ON_SILOS', false),
    siloDrawerHoverIntent: get(state, 'navigation.siloDrawerHoverIntent', {}),
    improvePerformanceOfStyleToggle: get(state, 'toggles.IMPROVE_PERFORMANCE_OF_STYLE', false),
    isSinglePageOn: get(state, 'toggles.SINGLE_PAGE_APP_CATEGORY', false),
    templateType: get(state, 'templates.templateType', ''),
    blackListedCategories: state.crp.blackListedCategories,
    globalNavUpdateToggle: isGlobalNavUpdateToggleEnabled(state),
    isTablet: state.device.isTablet,
    globalDrawerContentToggle,
    globalDrawerContent,
    saleSiloAbTestToggle: get(state, `abTestsOpt.${ABTEST_SALE}.variation`, false),
    selectedGender: getGenderWithOverride(state),
    bfSaleSiloAbTestToggle: get(state, `abTestsOpt.${ABTEST_BF_SALE}.variation`, false),
  };
};

const mapDispatchToProps = (dispatch) => {
  const actionsToBind = {
    closeDrawer: loseFocus,
    openDrawer: putComponentInFocus,
  };
  const boundActions = bindActionCreators(actionsToBind, dispatch);

  return {
    ...boundActions,
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);
