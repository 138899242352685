import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import window from 'window-or-global';
import { dangerousProperty } from 'client-utils/utilities-html-sanitizer';
import classnames from 'classnames';
import './NmSeo.scss';

export const truncateHtml = (html, maxChars) => {
  let charCount = 0;
  const parts = [];

  if (typeof window !== 'undefined' && window.DOMParser && typeof Node !== 'undefined') {
    const parser = new window.DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const traverseNodes = (nodes) => {
      for (const node of nodes) {
        if (charCount >= maxChars) break;

        if (node.nodeType === Node.TEXT_NODE) {
          if (charCount + node.textContent.length > maxChars) {
            parts.push(node.textContent.substring(0, maxChars - charCount));
            charCount = maxChars;
          } else {
            parts.push(node.textContent);
            charCount += node.textContent.length;
          }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          parts.push(`<${node.nodeName.toLowerCase()}`);
          for (const attr of node.attributes) {
            parts.push(` ${attr.name}="${attr.value}"`);
          }
          parts.push('>');

          traverseNodes(node.childNodes);

          parts.push(`</${node.nodeName.toLowerCase()}>`);
        }
      }
    };

    traverseNodes(doc.body.childNodes);
  } else {
    return html.length > maxChars ? html.substring(0, maxChars) : html;
  }
  return parts.join('');
};

const NmSeo = ({ content, isMobilePhone, setSeoWidth }) => {
  const [maxChars, setMaxChars] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const seoContentRef = useRef(null);

  const calculateMaxChars = () => {
    const viewportWidth = window?.innerWidth || 0;
    let maxChars = 0;
  
    if (!isMobilePhone) {
      switch (true) {
        case viewportWidth >= 1440: maxChars = 122;
          break;
        case viewportWidth >= 1024: maxChars = 85;
          break;
        case viewportWidth >= 768:
          switch (viewportWidth) {
            case 810: maxChars = 65;
              break;
            case 820: maxChars = 66;
              break;
            case 834:  maxChars = 68;
              break;
            case 768: maxChars = 62;
              break;
            default: maxChars = 66;
              break;
          }
          break;
        case 744: maxChars = 58;
          break;
      }
    } else {
      switch (true) {
        case viewportWidth >= 430: maxChars = 57;
          break;
        case viewportWidth >= 390: maxChars = 50;
          break;
        case viewportWidth >= 375: maxChars = 48;
          break;
        default: maxChars = 40;
          break;
      }
    }
    setMaxChars(maxChars);
  };

  useEffect(() => {
    calculateMaxChars();
    window?.addEventListener('resize', calculateMaxChars);
    return () => {
      window?.removeEventListener('resize', calculateMaxChars);
    };
  }, []);

  useEffect(() => {
    const strippedContent = content?.replace(/<[^>]+>/g, '');
    setHasMore(strippedContent.length > maxChars);
  }, [content, maxChars]);

  useEffect(() => {
    if(hasMore && !isExpanded && maxChars > 0 && seoContentRef?.current?.offsetWidth > 0 && setSeoWidth){
      const strippedContent = content?.replace(/<[^>]+>/g, '');
      if(strippedContent.length > maxChars){
        setSeoWidth(seoContentRef?.current?.offsetWidth)
      }
    }
  }, [seoContentRef, hasMore, maxChars]);

  const formattedContent = useMemo(() => content?.replace(/<br \/>/g, ''), [content]);
  const truncatedContent = useMemo(() => truncateHtml(formattedContent, maxChars), [content, maxChars]);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div className={classnames(
        isMobilePhone ? 'mobile-content' : 'text-container',
        isExpanded ? 'hidden-content' : 'visible-content'
      )}>
        <div ref={seoContentRef} className='seo-content-hidden'>
          <span dangerouslySetInnerHTML={dangerousProperty(truncatedContent, [], { a: ['href'] })} className="custom-link" />
          {hasMore && (
            <span className='gradient-overlay'>
              <button className="expand-button" onClick={toggleExpansion}>
                More
              </button>
            </span>
          )}
        </div>
      </div>
      {hasMore && (
        <div className={classnames(
          isMobilePhone ? 'mobile-content' : 'text-container',
          isExpanded ? "visible-content" : "hidden-content")}>
          <span dangerouslySetInnerHTML={dangerousProperty(formattedContent, [], { a: ['href'] })} className="custom-link" />
          <button className="collapse-button" onClick={toggleExpansion}>
            Less
          </button>
        </div>
      )}
    </>
  );
};

NmSeo.propTypes = {
  content: PropTypes.string,
  isMobilePhone: PropTypes.bool,
};

export default NmSeo;

