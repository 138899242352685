import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { shouldLoad } from 'universal/http-client';
import { getLeftNavTreeByNavPath } from './actions-leftnavigation';
import LeftNavSubTree from './LeftNavSubTree/leftNavSubTree';
import FavoriteCategoryButton from './favoriteCategoryButton';
import './leftNavigation.scss';

export class LeftNavigation extends Component {
  UNSAFE_componentWillMount() {
    const {
      leftnav_content,
      routeParams = {},
      navpath,
      source,
    } = this.props;
    if (shouldLoad(leftnav_content)) {
      const leftNavPath = this.buildNavPath(routeParams, navpath);
      this.props.fetchLeftNavTreeByNavPath(leftNavPath, source);
    }
  }

  reversePath(path) {
    return (path) ? path.split('_')[0] : '';
  }

  buildNavPath(routeParams, navPath) {
    return navPath || this.reversePath(routeParams.categoryId);
  }

  render() {
    const {
      leftnavtree,
      templateType,
    } = this.props;
    const designerId = leftnavtree.leftNav ? leftnavtree.leftNav[0].id : '';
    const designerName = leftnavtree.leftNav ? leftnavtree.leftNav[0].name : '';
    return (
      <nav className={
        classnames({ 'left-nav grid-20 tablet-grid-20 hide-on-mobile': templateType !== 'P' },
          { 'left-nav category-selector': templateType === 'P' },
          { 'left-nav transform-text': templateType === 'Entry' })
      }
      >
        {
          !isEmpty(leftnavtree.boutiqueLeftNav)
          && (
          <FavoriteCategoryButton
            id={designerId}
            name={designerName}
          />
          )
        }
        <LeftNavSubTree
          subTree={leftnavtree.leftNav}
          boutiqueLeftNav={leftnavtree.boutiqueLeftNav}
          refreshableContentSrc={leftnavtree.refreshablePath}
          templateType={templateType}
        />
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  leftnav_content: state.api.leftnav_content,
  leftnavtree: state.leftNavigation,
  templateType: state.templates.templateType,
});

const mapDispatchToProps = ({
  fetchLeftNavTreeByNavPath: getLeftNavTreeByNavPath,
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftNavigation);
