import intersection from 'lodash/intersection';
import React, { Component } from 'react';
import logger from 'server/utilities/logger';
import { referralSourceClicked } from 'client/common/actions/actions-page';

import './relatedItems.scss';

export const ANALYTICS_RELATEDITEMS = 'Related items';

export default class RelatedItemsComponent extends Component {
  constructor(props) {
    super(props);
    this.validateRelatedItemClick = this.validateRelatedItemClick.bind(this);
  }

  getLinks(brContainer) {
    const brRelatedSearchLink = brContainer.querySelectorAll('.br-related-query-link');
    const brRelatedProductsTitleLink = brContainer.querySelectorAll('.br-sf-widget-merchant-title b');
    const brRelatedProductsImgLink = brContainer.querySelectorAll('.br-sf-widget-merchant-img a');
    const viewProductLink = brContainer.querySelectorAll('.br-sf-widget-merchant-popup-view a');
    const modalImageLink = brContainer.querySelectorAll('.br-sf-widget-merchant-popup-img a');
    return [
      ...viewProductLink,
      ...brRelatedSearchLink,
      ...brRelatedProductsTitleLink,
      ...brRelatedProductsImgLink,
      ...modalImageLink,
    ];
  }

  validateRelatedItemClick(e) {
    try {
      if (e && e.nativeEvent) {
        const path = e.nativeEvent.propagationPath() || [];
        const brClickedLink = intersection(path, this.getLinks(e.currentTarget));
        if (brClickedLink.length > 0) {
          referralSourceClicked(ANALYTICS_RELATEDITEMS, this.props.pageType);
        }
      }
    } catch (e) {
      logger.error('Unable to get bloomreach link for analytics tagging', e);
    }
  }

  render() {
    const { relatedItemsHtml } = this.props;
    if (!relatedItemsHtml) return false;
    return (
      <div
        className="common-br-container grid-100"
        dangerouslySetInnerHTML={{ __html: relatedItemsHtml }}
        onClick={this.validateRelatedItemClick}
      />
    );
  }
}
