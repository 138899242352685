import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import classnames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { getAvailableStoresInfo, getMappedStoreInfo, convertLowerCase } from 'srp/utils/srpUtils';
import { setStoreName } from 'plp/components/ProductListPage/components/FacetedLeftNav/actions';
import './OmniFacet.scss';

export class _OmniFacet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStore: '',
      favoriteStore: '',
      selectedZip: cookie.load('dt_selected_zipcode') && cookie.load('dt_selected_zipcode').slice(1, -1),
    };
    this.handleZipChange = this.handleZipChange.bind(this);
    this.handleStoreChange = this.handleStoreChange.bind(this);
    this.changeStorePrompt = this.changeStorePrompt.bind(this);
    this.changeZipPrompt = this.changeZipPrompt.bind(this);
    this.updateStoreInfoState = this.updateStoreInfoState.bind(this);
    this.renderFilterOptions = this.renderFilterOptions.bind(this);
    this.checkKeyForSelected = this.checkKeyForSelected.bind(this);
    this.updateStoreInfo = this.updateStoreInfo.bind(this);
    this.handleKeyDownZipPrompt = this.handleKeyDownZipPrompt.bind(this);
    this.handleKeyDownStorePrompt = this.handleKeyDownStorePrompt.bind(this);
  }

  async componentDidMount() {
    window.YNZipCallback = this.handleZipChange;
    window.YNStoreCallback = this.handleStoreChange;

    await this.updateStoreInfo();
  }

  async updateStoreInfo() {
    const { selectedStore, favoriteStore, cleanZip } = getAvailableStoresInfo();

    try {
      const storeInfo = await getMappedStoreInfo(selectedStore, favoriteStore, cleanZip);

      !isEmpty(storeInfo) && this.updateStoreInfoState(storeInfo);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Error occurred while fetching store name. Error: ${e}`);
    }
  }

  async handleZipChange() {
    await this.updateStoreInfo();
    this.props.submitFunc('In Store', 'zipChange');
  }

  async handleStoreChange(selectedStore) {
    await this.updateStoreInfo();
    this.props.submitFunc('In Store', 'storeChange', undefined, null, selectedStore);
  }

  updateStoreInfoState(mappedStoreData) {
    const { stores, isNmNewHeader } = this.props;
    const { selectedStore, favoriteStore, selectedZip } = mappedStoreData;

    if(isNmNewHeader){
      this.setInitialStoreName(selectedStore, favoriteStore, stores);
    }
    this.setState({
      ...(selectedStore && { selectedStore }),
      ...(favoriteStore && { favoriteStore }),
      ...(selectedZip && { selectedZip }),
    });
  }

  setInitialStoreName(selectedStore, favoriteStore, stores) {
    const { setStoreName } = this.props;
    let storeName = '';
    if (selectedStore) {
      storeName = selectedStore;
    } else if (favoriteStore) {
      storeName = favoriteStore;
    } else if (!isEmpty(stores)) {
      const store = stores && stores[0];
      storeName = store?.name;
    }
    storeName = storeName && convertLowerCase(storeName);
    if (storeName) {
      setStoreName(storeName);
    }
  }

  changeStorePrompt() {
    window.YourNeimans && window.YourNeimans.setData({
      isOpen: true,
      pageStack: ['storeListPage'],
    });
  }

  changeZipPrompt() {
    window.YourNeimans && window.YourNeimans.setData({
      isOpen: true,
      pageStack: ['updateZipPage'],
    });
  }

  handleKeyDownZipPrompt(e) {
    if (e.key === "Enter" || e.key === "Return") {
      e.stopPropagation();
      this.changeZipPrompt();
    }
  }

  handleKeyDownStorePrompt(e) {
    if (e.key === "Enter" || e.key === "Return") {
      e.stopPropagation();
      this.changeStorePrompt();
    }
  }

  checkKeyForSelected(key) {
    const {
      isSameDayDeliverySelected,
      isNextDayDeliverySelected,
      isCurbsidePickupSelected,
    } = this.props;

    switch (key) {
      case 'sdd':
        return isSameDayDeliverySelected;
      case 'ndd':
        return isNextDayDeliverySelected;
      case 'csp':
        return isCurbsidePickupSelected;
      default:
        return false;
    }
  }

  renderFilterOptions = (
    isPageSRP,
    filterKey,
    key,
    facetedFiltersList,
    label
  ) => {
    if (isPageSRP) {
      return (
        <input
          type="checkbox"
          checked={facetedFiltersList[key]}
          onClick={(e) => {
            this.props.submitFunc(filterKey, key, e);
          }}
          onKeyDown={(e) => { 
            if (e.key === "Enter" || e.key === "Return") {
              e.stopPropagation();
              this.props.submitFunc(filterKey, key, e);
            }
          }}
          value={key}
          aria-label={label}
          data-tid={`facet-input-key-${key}`}
        />
      );
    }

    return (
      <input
        type="checkbox"
        checked={facetedFiltersList[key]}
        onClick={(e) => {
          this.props.submitFunc(filterKey, key, e);
        }}
        onKeyDown={(e) => { 
          if (e.key === "Enter" || e.key === "Return") {
            e.stopPropagation();
            this.props.submitFunc(filterKey, key, e);
          }
        }}
        value={key}
        aria-label={label}
        data-tid={`facet-input-key-${key}`}
      />
    );
  }


  render() {
    const renderDelivery = (configObject) => {
      const { text, nonGeoText, options } = configObject;
      const { selectedZip } = this.state;
      const {
        locationInfo,
        validFacets,
        filterKey,
        facetedFiltersList,
        pageId,
        isMobilePhone,
      } = this.props;

      const isPageSRP = pageId === 'PAGE_ID_SRP';
      const deliveryOptions = ['sdd', 'ndd'];

      const isDeliveryOption = (item) => {
        return item === 'sdd' || item === 'ndd';
      };

      let deliveryOptionActive = Array.isArray(validFacets)
        ? validFacets.filter(isDeliveryOption)
        : [];

      if (isPageSRP) {
        deliveryOptionActive = deliveryOptions;
      }

      let zipCode = '';

      if (selectedZip) {
        zipCode = selectedZip;
      } else if (locationInfo && locationInfo.zip) {
        zipCode = locationInfo.zip;
      }

      if (zipCode) {
        return (
          <span className="omni-filter-title">
            <div>
              {text}
              &nbsp;
              <span className="dynamic-content"
                role="button"
                onClick={this.changeZipPrompt}
                onKeyDown={this.handleKeyDownZipPrompt}
                tabIndex="0"
              >
                {zipCode}
:
              </span>
            </div>
            <ul className="faceted-left-nav__facet full-row omni-facet">
              {deliveryOptions.map((key) => {
                const labelsMap = {
                  sdd: options[0].filterText,
                  ndd: options[1].filterText,
                };

                const label = labelsMap[key];
                if (deliveryOptionActive.indexOf(key) !== -1) {
                  return (
                    <li
                      key={`${key}`}
                      onClick={(e) => {
                        this.props.submitFunc(filterKey, key, e);
                      }}
                      onKeyDown={(e) => { 
                        if (e.key === "Enter" || e.key === "Return") {
                          e.stopPropagation();
                          this.props.submitFunc(filterKey, key, e);
                        }
                      }}
                    >
                      <span className="faceted-left-nav__facet__input">
                        {
                          this.renderFilterOptions(
                            isPageSRP,
                            filterKey,
                            key,
                            facetedFiltersList,
                            label,
                          )
                        }
                      </span>
                      <span className="faceted-left-nav__facet__desc">
                        <span
                          className={classnames(
                            'faceted-left-nav__facet__label',
                            {
                              'filter--selected': facetedFiltersList[key],
                            }
                          )}
                          dangerouslySetInnerHTML={{ __html: label }}
                        />
                      </span>
                    </li>
                  );
                }
                return (
                  <li key={`${key}`}>
                    <span className="faceted-left-nav__facet__input">
                      <input
                        type="checkbox"
                        checked={false}
                        value={key}
                        data-tid={`facet-input-key-${key}`}
                        aria-label={label}
                        disabled
                      />
                    </span>
                    <span className="faceted-left-nav__facet__desc">
                      <span
                        className="faceted-left-nav__facet__label--disabled"
                        dangerouslySetInnerHTML={{
                          __html: label,
                        }}
                      />
                    </span>
                  </li>
                );
              })}
            </ul>
          </span>
        );
      }

      return (
        <div>
          <span className="omni-filter-title">
            {nonGeoText}
            :&nbsp;
          </span>
          <p>
            <span 
              className="select-button dynamic-content"
              onClick={this.changeZipPrompt}
              onKeyDown={this.handleKeyDownZipPrompt}
              tabIndex="0"
            >
              Enter ZIP code
            </span>
            &nbsp;for delivery options
          </p>
        </div>
      );
    };

    const renderPickup = (configObject) => {
      const { text, nonGeoText } = configObject;
      const { selectedStore, favoriteStore } = this.state;
      const {
        stores,
        validFacets,
        filterKey,
        facetedFiltersList,
        pageId,
        isMobilePhone,
        geolocatedStore,
      } = this.props;
      const isPageSRP = pageId === 'PAGE_ID_SRP';

      const labelsMap = {
        csp: configObject.options[0].filterText,
      };

      let storeName = '';

      if (selectedStore) {
        storeName = selectedStore;
      } else if (favoriteStore) {
        storeName = favoriteStore;
      } else if (!isEmpty(stores)) {
        const store = stores && stores[0];
        storeName = store.name;
      }

      storeName = storeName && convertLowerCase(storeName);

      const storeNoFromCookie = cookie.load('dt_selected_store') !== undefined ? cookie.load('dt_selected_store').split('/')[0] : null;
      const geolocatedStoreNo = geolocatedStore !== null ? geolocatedStore.split('/')[0] : null;
      const storeNo = storeNoFromCookie !== null ? storeNoFromCookie : geolocatedStoreNo;

      let pickupOptions = Array.isArray(validFacets)
        && validFacets.indexOf('csp') !== -1
        && validFacets.indexOf(storeNo) !== -1
        ? ['csp']
        : [];

      if (isPageSRP) {
        pickupOptions = ['csp'];
      }

      if (storeName) {
        return (
          <span className="omni-filter-title">
            <p>
              {text}
              &nbsp;
              <span className="dynamic-content"
                role="button"
                onClick={this.changeStorePrompt}
                onKeyDown={this.handleKeyDownStorePrompt}
                tabIndex="0"
              >
                {storeName}
:
              </span>
            </p>
            <ul className="faceted-left-nav__facet full-row omni-facet">
              {pickupOptions.map((key) => {
                const label = labelsMap[key];
                return (
                  <li
                    key={`${key}`}
                    onClick={(e) => {
                      this.props.submitFunc(filterKey, key, e);
                    }}
                    onKeyDown={(e) => { 
                      if (e.key === "Enter" || e.key === "Return") {
                        e.stopPropagation();
                        this.props.submitFunc(filterKey, key, e);
                      }
                    }}
                  >
                    <span className="faceted-left-nav__facet__input">
                      {
                        this.renderFilterOptions(
                          isPageSRP,
                          filterKey,
                          key,
                          facetedFiltersList,
                          label,
                        )
                      }
                    </span>
                    <span className="faceted-left-nav__facet__desc">
                      <span
                        className={classnames(
                          'faceted-left-nav__facet__label',
                          {
                            'filter--selected': facetedFiltersList[key],
                          }
                        )}
                        dangerouslySetInnerHTML={{ __html: label }}
                      />
                    </span>
                  </li>
                );
              })}

              {
                isEmpty(pickupOptions) && (
                  <li>
                    <span className="faceted-left-nav__facet__input">
                      <input
                        type="checkbox"
                        checked={false}
                        value="csp"
                        data-tid="facet-input-key-csp"
                        aria-label={labelsMap.csp}
                        disabled
                      />
                    </span>
                    <span className="faceted-left-nav__facet__desc">
                      <span
                        className="faceted-left-nav__facet__label--disabled"
                        dangerouslySetInnerHTML={{
                          __html: labelsMap.csp,
                        }}
                      />
                    </span>
                  </li>
                )
              }
            </ul>
          </span>
        );
      }
      return (
        <>
          <span className="omni-filter-title">
            {nonGeoText}
            :&nbsp;
          </span>
          <button
            className="dynamic-content select-button"
            onClick={this.changeStorePrompt}
            onKeyDown={this.handleKeyDownStorePrompt}
            tabIndex="0"
          >
            Select a store location
          </button>
        </>
      );
    };

    const {
      delivery, pickup, sysFacetOrder, turnOffDeliveryToggle,
    } = this.props;

    const renderOmniFacet = (sysFacetOrder) => {
      let result;
      if (sysFacetOrder) {
        result = (
          <Fragment>
            {renderPickup(pickup)}
            {!turnOffDeliveryToggle && renderDelivery(delivery)}
          </Fragment>
        );
      } else {
        result = (
          <Fragment>
            {!turnOffDeliveryToggle && renderDelivery(delivery)}
            {renderPickup(pickup)}
          </Fragment>
        );
      }

      return result;
    };

    return (
      <div className="omni-container">
        {renderOmniFacet(sysFacetOrder)}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { omniFacet } = state;
  const { facetName, delivery, pickup } = omniFacet;
  return {
    facetName,
    delivery,
    pickup,
    stores: get(state, 'facetedLeftNav.stores', []),
    locationInfo: get(state, 'api.requestContext.akamaiEdgescape', {}),
    sysFacetOrder: get(state, 'toggles.SYSFACET_ORDER', false),
    turnOffDeliveryToggle: get(state, 'toggles.SYSFACET_TURNOFF_DELIVERY', false),
    pageId: state.page.pageId,
    isMobilePhone: get(state, 'device.isMobilePhone', false),
    geolocatedStore: get(state, 'facetedLeftNav.storeListPayload[0].id', null),
    isNmNewHeader: get(state, 'toggles.NM_NEW_HEADER', false),
  };
};

const mapDispatchToProps = ({
  setStoreName,
});

export default connect(mapStateToProps, mapDispatchToProps)(_OmniFacet);
