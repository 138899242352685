import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import SiloImage from './components/SiloImage';
import './EcmSiloThirdParty.scss';

const SiloImageWithWrapper = ({ imageNum, thumbIndex, ...props }) => {
  return (
    <div className="small_image_wrapper_no_grid">
      <SiloImage
        siloTitle={props.siloDisplayTitle}
        imageUrl={get(props, `thumbnailData[${thumbIndex}].imageUrl`, '')}
        name={get(props, `thumbnailData[${thumbIndex}].name`, '')}
        url={get(props, `thumbnailData[${thumbIndex}].url`, '')}
        imageNum={imageNum}
        countryCode={props.countryCode}
      />
    </div>
  );
};
const EcmSiloThirdParty = (props) => {
  const zShotForLargeImage = get(props, 'thumbnailData[0].imageUrl', '')
    .replace(/mk.jpg/, 'mz.jpg')
    .replace(/ek.jpg/, 'ez.jpg');
  return (
    get(props, 'thumbnailData', null) === null || get(props, 'thumbnailData', []) === []
      ? null
      : (
        <div className="Silo__subcat_container_no_grid grid-100">
          <section className="ratio_container">
            <section className="large_container_wrapper">
              <div className="first_image_wrapper_no_grid">
                <SiloImage
                  siloTitle={props.siloDisplayTitle}
                  imageUrl={zShotForLargeImage}
                  name={get(props, 'thumbnailData[0].name', '')}
                  url={get(props, 'thumbnailData[0].url', '')}
                  imageNum={1}
                  countryCode={props.countryCode}
                />
              </div>
            </section>
            <section className="small_container_wrapper left">
              <SiloImageWithWrapper {...props} imageNum={2} thumbIndex={1} />
              <SiloImageWithWrapper {...props} imageNum={4} thumbIndex={3} />
            </section>
            <section className="small_container_wrapper right">
              <SiloImageWithWrapper {...props} imageNum={3} thumbIndex={2} />
              <SiloImageWithWrapper {...props} imageNum={5} thumbIndex={4} />
            </section>
          </section>
        </div>
      )
  );
};

const mapStateToProps = (state) => {
  return {
    thumbnailData: state.templates.siloThumbnailData,
    countryCode: state.locale.countryCode,
    siloDisplayTitle: get(state.templates, 'templateDetails.templateAttributes.dynamicContent.title', ''),
  };
};

export default connect(mapStateToProps)(EcmSiloThirdParty);
