import React from 'react';
import EcmGet from 'ecm/components/EcmGet';
import classnames from 'classnames';
import './promoTile.scss';

export default function PromoTile(props) {
  const { ecmSlot } = props;
  return (
    <div
      className={classnames(
        'product-thumbnail grid-33 tablet-grid-33 mobile-grid-50 promo-tile grid-1600',
        'promo-tile__aspect_ratio_2_3'
      )
      }
    >
      <EcmGet ecmSlot={ecmSlot} />
    </div>
  );
}
