import React, { Fragment, Component } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import ECMGet from 'ecm/components/EcmGet';
import { ECMSlots } from 'ecm/constants';
import DesignerSearchBox from 'templates/DesignerIndex/components/DesignerSearchBox';
import { sanitizeInput, indexOfSearchTerm } from 'templates/DesignerIndex/components/searchDesigners';
import { connect } from 'react-redux';
import favIconActive from 'assets/images/favorite-active.svg';
import {
  getDesignerIndexDesignerSearchTerm,
  getDesignerIndexFoundDesigners,
} from 'storefront/components/Navigation/NavSlider/navSlider-reducer';
import AlphaIndexSelector from './components/AlphaIndexSelector';
import CategoryFilter from './components/CategoryFilter';
import AlphaSection from './components/AlphaSection';
import './DesignerIndex.scss';
import { renderCMSContent, cmsContentExists } from '../../utilities/utilities-cms';

class DesignerIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyAlphaIndex: false,
      initialAlphaIndexOffsetTop: 0,
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.setInitialAlphaIndexOffset = this.setInitialAlphaIndexOffset.bind(this);
    this.scrollToSection = this.scrollToSection.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.setInitialAlphaIndexOffset();

    const sectionId = get(this.props.location, 'query.dIndex', null);
    if (sectionId !== null) { this.scrollToSection(sectionId); }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  setInitialAlphaIndexOffset() {
    const alphaIndex = document.getElementsByClassName('alpha-index')[0];
    this.setState({ initialAlphaIndexOffsetTop: get(alphaIndex, 'offsetTop', 0) });
  }

  scrollToSection(sectionId) {
    const selectedSection = document.getElementById(sectionId);
    const alphaIndexSelector = document.getElementsByClassName('alpha-index')[0];

    if (selectedSection && alphaIndexSelector) {
      const alphaIndexSelectorHeight = alphaIndexSelector.getBoundingClientRect().height;
      const scrollToPos = selectedSection.getBoundingClientRect().top
        - alphaIndexSelectorHeight - 10;

      window.scrollTo(0, scrollToPos);
    }
  }

  handleScroll() {
    const stickyAlphaIndexVisible = window.pageYOffset >= this.state.initialAlphaIndexOffsetTop + 5;

    if (stickyAlphaIndexVisible !== this.state.stickyAlphaIndex) {
      this.setState({
        stickyAlphaIndex: stickyAlphaIndexVisible,
      });
    }
  }

  render() {
    const alphaIndexClass = classNames({
      'alpha-index': true,
      'sticky-alpha-index': this.state.stickyAlphaIndex,
    });

    const mainContentClass = classNames({
      'grid-parent': true,
      'mt-75': this.state.stickyAlphaIndex && $('.alpha-index').css('--custom-width-identifier-property') === undefined,
    });

    const designersByIndex = get(this.props.templateDetails, 'templateAttributes.dynamicContent.designers.designersByIndex', []);
    const designerCategories = get(this.props.templateDetails, 'templateAttributes.dynamicContent.designers.designersByCategory', []);
    const categoryName = get(this.props.templateDetails, 'templateAttributes.dynamicContent.designers.name', null);
    const categoryId = get(this.props.templateDetails, 'templateAttributes.dynamicContent.designers.id', null);
    const isEcmAvailable = !isEmpty(get(this.props.templateDetails, 'templateAttributes.ecmContent', false));
    const searchTerm = this.props.searchTerm;
    const foundDesigners = this.props.foundDesigners;
    const designerSilo = this.props.designerSilo;
    const { isDesktop, isDomestic, nmAwardWinnerToggle } = this.props;

    const renderEcm = (
      isEcmAvailable
        ? (
          ""
        )
        : (<div className="ecm-blank-space" />)
    );

    const cmsContent = this.props.renderCmsInTemplate;
    const renderCMSNav = renderCMSContent(cmsContent, 'LeftNav');
    const renderLeftNav = cmsContentExists(cmsContent) ? renderCMSNav : renderEcm;
    const renderMainContent = (nmAwardWinnerToggle && isDomestic && isDesktop &&
      cmsContentExists(cmsContent)) ? renderCMSContent(cmsContent, 'Main') : null;

    const sectionsToRender = designersByIndex.map((section, idx) => {
      if (section.categories.length > 0) {
        return <AlphaSection id={section.name} key={idx} designers={section.categories} />;
      }
      return false;
    });

    const spacedSectionsToRender = designersByIndex.map((section, idx) => {
      if (section.categories.length > 0) {
        return <AlphaSection spaced id={section.name} key={idx} designers={section.categories} />;
      }
      return false;
    });

    const favDesignersLink = this.props.brand === 'HC' ? '' : (
      <a tabIndex={0} href={NMConfig.FAVORITE_DESIGNERS_PAGE} className="view-favorite-designers-link">
        <img tabIndex={-1} alt="Favorite" src={favIconActive} />
        <h2 tabIndex={-1}>View Favorite Designers</h2>
      </a>
    );

    const SearchMatchItem = ({ silo, searchTerm }) => {
      const matchIndex = indexOfSearchTerm(searchTerm, silo.name);
      const start = silo.name.substring(0, matchIndex);
      const matchingText = silo.name.substring(matchIndex, matchIndex + searchTerm.length);
      const end = silo.name.substring(matchIndex + searchTerm.length);
      return (
        <a className="designer-index-nav-slider-menu__item designer-index-nav-slider-menu__link" href={silo.url}>
          {start}
          <u>{matchingText}</u>
          {end}
        </a>
      );
    };


    const determineContent = (searchTerm, foundDesigners) => {
      let content;
      const sanitizedSearch = searchTerm ? sanitizeInput(searchTerm) : '';
      if (!isEmpty(sanitizedSearch) && isEmpty(foundDesigners)) {
        // TODO: NMOWEB-8460 Fix invalid anchor
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        content = (<a className="designer-index-nav-slider-menu__item designer-index-nav-slider-menu__link">No results found.</a>);
      } else if (!isEmpty(sanitizedSearch)) {
        content = foundDesigners.map((silo) => (
          <SearchMatchItem silo={silo} searchTerm={sanitizedSearch} key={silo.id} />
        ));
      }
      return content;
    };

    const subMenuItems = (
      <ul className="designer-index-nav-slider-menu__secondary-menu">
        { determineContent(searchTerm, foundDesigners, designerSilo) }
      </ul>
    );

    return (
      <Fragment>
        <div className="grid-parent">
          <h1 className="designer-index-mobile-title hide-on-desktop hide-on-tablet">Designers</h1>
          <DesignerSearchBox />
          { subMenuItems }
          <div className={alphaIndexClass}>
            <div className="grid-100">
              <AlphaIndexSelector designersByIndex={designersByIndex} />
            </div>
          </div>
          <div className="hide-on-desktop hide-on-tablet">
            { favDesignersLink }
          </div>
        </div>
        <div className="designer-index-container clearfix">
          <div className={mainContentClass}>
            <div className="grid-20 tablet-grid-20 hide-on-mobile ">
              <div className="alpha-sidebar">
                <CategoryFilter categories={designerCategories} selectedCatId={categoryId} />
                { favDesignersLink }
                { renderLeftNav }
              </div>
            </div>
            <div className="grid-80 tablet-grid-80 mobile-grid-100">
              {categoryName && <div><h1 className="alpha-index-title">{categoryName}</h1></div> }
              {renderMainContent}
              { categoryName ? spacedSectionsToRender : sectionsToRender }
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  templates: get(state, 'templates', {}),
  designerSilo: get(state, 'templates.templateDetails.templateAttributes.dynamicContent.designers.designersByIndex', []),
  searchTerm: getDesignerIndexDesignerSearchTerm(state),
  foundDesigners: getDesignerIndexFoundDesigners(state),
  brand: state.brand_name.env,
  isDesktop: get(state, 'device.isDesktop', false),
  isDomestic: get(state, 'locale.countryCode', '') === 'US',
  nmAwardWinnerToggle: get(state, 'toggles.NM_AWARDS', false),
});

export default connect(mapStateToProps)(DesignerIndex);
export { DesignerIndex as PureDesignerIndex };
