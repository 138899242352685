import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import classnames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { getAvailableStoresInfo, getMappedStoreInfo, convertLowerCase } from 'srp/utils/srpUtils';
import { setStoreName } from 'plp/components/ProductListPage/components/FacetedLeftNav/actions';
import './OmniFacet.scss';

export class _OmniFacet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStore: '',
      favoriteStore: '',
      selectedZip: cookie.load('dt_selected_zipcode') && cookie.load('dt_selected_zipcode').slice(1, -1),
    };
    this.handleZipChange = this.handleZipChange.bind(this);
    this.handleStoreChange = this.handleStoreChange.bind(this);
    this.changeStorePrompt = this.changeStorePrompt.bind(this);
    this.updateStoreInfoState = this.updateStoreInfoState.bind(this);
    this.renderFilterOptions = this.renderFilterOptions.bind(this);
    this.updateStoreInfo = this.updateStoreInfo.bind(this);
    this.handleKeyDownStorePrompt = this.handleKeyDownStorePrompt.bind(this);
  }

  async componentDidMount() {
    window.YNZipCallback = this.handleZipChange;
    window.YNStoreCallback = this.handleStoreChange;

    await this.updateStoreInfo();
  }

  async updateStoreInfo() {
    const { selectedStore, favoriteStore, cleanZip } = getAvailableStoresInfo();

    try {
      const storeInfo = await getMappedStoreInfo(selectedStore, favoriteStore, cleanZip);

      !isEmpty(storeInfo) && this.updateStoreInfoState(storeInfo);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Error occurred while fetching store name. Error: ${e}`);
    }
  }

  async handleZipChange() {
    await this.updateStoreInfo();
    this.props.submitFunc('In Store', 'zipChange');
  }

  async handleStoreChange(selectedStore) {
    await this.updateStoreInfo();
    this.props.submitFunc('In Store', 'storeChange', undefined, null, selectedStore);
  }

  updateStoreInfoState(mappedStoreData) {
    const { stores, isNmNewHeader } = this.props;
    const { selectedStore, favoriteStore, selectedZip } = mappedStoreData;

    if (isNmNewHeader) {
      this.setInitialStoreName(selectedStore, favoriteStore, stores);
    }
    this.setState({
      ...(selectedStore && { selectedStore }),
      ...(favoriteStore && { favoriteStore }),
      ...(selectedZip && { selectedZip }),
    });
  }

  setInitialStoreName(selectedStore, favoriteStore, stores) {
    const { setStoreName } = this.props;
    let storeName = '';
    if (selectedStore) {
      storeName = selectedStore;
    } else if (favoriteStore) {
      storeName = favoriteStore;
    } else if (!isEmpty(stores)) {
      const store = stores && stores[0];
      storeName = store?.name;
    }
    storeName = storeName && convertLowerCase(storeName);
    if (storeName) {
      setStoreName(storeName);
    }
  }

  changeStorePrompt() {
    window.YourNeimans && window.YourNeimans.setData({
      isOpen: true,
      pageStack: ['storeListPage'],
    });
  }

  handleKeyDownStorePrompt(e) {
    if (e.key === "Enter" || e.key === "Return") {
      e.stopPropagation();
      this.changeStorePrompt();
    }
  }

  renderFilterOptions = (
    isPageSRP,
    filterKey,
    key,
    facetedFiltersList,
    label
  ) => {
    if (isPageSRP) {
      return (
        <input
          type="checkbox"
          checked={facetedFiltersList[key]}
          onClick={(e) => {
            this.props.submitFunc(filterKey, key, e);
          }}
          onKeyDown={(e) => { 
            if (e.key === "Enter" || e.key === "Return") {
              e.stopPropagation();
              this.props.submitFunc(filterKey, key, e);
            }
          }}
          value={key}
          aria-label={label}
          data-tid={`facet-input-key-${key}`}
        />
      );
    }

    return (
      <input
        type="checkbox"
        checked={facetedFiltersList[key]}
        onClick={(e) => {
          this.props.submitFunc(filterKey, key, e);
        }}
        onKeyDown={(e) => { 
          if (e.key === "Enter" || e.key === "Return") {
            e.stopPropagation();
            this.props.submitFunc(filterKey, key, e);
          }
        }}
        value={key}
        aria-label={label}
        data-tid={`facet-input-key-${key}`}
      />
    );
  }


  render() {
    const renderPickup = (configObject) => {
      const { text, nonGeoText } = configObject;
      const { selectedStore, favoriteStore } = this.state;
      const {
        stores,
        validFacets,
        filterKey,
        facetedFiltersList,
        pageId,
        isMobilePhone,
        geolocatedStore,
      } = this.props;
      const isPageSRP = pageId === 'PAGE_ID_SRP';

      const labelsMap = {
        csp: configObject.options[0].filterText,
      };

      let storeName = '';

      if (selectedStore) {
        storeName = selectedStore;
      } else if (favoriteStore) {
        storeName = favoriteStore;
      } else if (!isEmpty(stores)) {
        const store = stores && stores[0];
        storeName = store.name;
      }

      storeName = storeName && convertLowerCase(storeName);

      const storeNoFromCookie = cookie.load('dt_selected_store') !== undefined ? cookie.load('dt_selected_store').split('/')[0] : null;
      const geolocatedStoreNo = geolocatedStore !== null ? geolocatedStore.split('/')[0] : null;
      const storeNo = storeNoFromCookie !== null ? storeNoFromCookie : geolocatedStoreNo;

      let pickupOptions = Array.isArray(validFacets)
        && validFacets.indexOf('csp') !== -1
        && validFacets.indexOf(storeNo) !== -1
        ? ['csp']
        : [];

      if (isPageSRP) {
        pickupOptions = ['csp'];
      }

      if (storeName) {
        return (
          <span className="omni-filter-title">
            <p>
              {text}
              &nbsp;
              <span className="dynamic-content"
                role="button"
                onClick={this.changeStorePrompt}
                onKeyDown={this.handleKeyDownStorePrompt}
                tabIndex="0"
              >
                {storeName}
:
              </span>
            </p>
            <ul className="faceted-left-nav__facet full-row omni-facet">
              {pickupOptions.map((key) => {
                const label = labelsMap[key];
                return (
                  <li
                    key={`${key}`}
                    onClick={(e) => {
                      this.props.submitFunc(filterKey, key, e);
                    }}
                    onKeyDown={(e) => { 
                      if (e.key === "Enter" || e.key === "Return") {
                        e.stopPropagation();
                        this.props.submitFunc(filterKey, key, e);
                      }
                    }}
                  >
                    <span className="faceted-left-nav__facet__input">
                      {
                        this.renderFilterOptions(
                          isPageSRP,
                          filterKey,
                          key,
                          facetedFiltersList,
                          label
                        )
                      }
                    </span>
                    <span className="faceted-left-nav__facet__desc">
                      <span
                        className={classnames(
                          'faceted-left-nav__facet__label',
                          {
                            'filter--selected': facetedFiltersList[key],
                          }
                        )}
                        dangerouslySetInnerHTML={{ __html: label }}
                      />
                    </span>
                  </li>
                );
              })}

              {
                isEmpty(pickupOptions) && (
                  <li>
                    <span className="faceted-left-nav__facet__input">
                      <input
                        type="checkbox"
                        checked={false}
                        value="csp"
                        data-tid="facet-input-key-csp"
                        aria-label={labelsMap.csp}
                        disabled
                      />
                    </span>
                    <span className="faceted-left-nav__facet__desc">
                      <span
                        className="faceted-left-nav__facet__label--disabled"
                        dangerouslySetInnerHTML={{
                          __html: labelsMap.csp,
                        }}
                      />
                    </span>
                  </li>
                )
              }
            </ul>
          </span>
        );
      }
      return (
        <>
          <span className="omni-filter-title">
            {nonGeoText}
            :&nbsp;
          </span>
          <button className="dynamic-content select-button"
            onClick={this.changeStorePrompt}
            onKeyDown={this.handleKeyDownStorePrompt}
            tabIndex="0"
          >
            Select a store location
          </button>
        </>
      );
    };

    const { pickup } = this.props;

    return (
      <div className="omni-container">
        {renderPickup(pickup)}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { omniFacet } = state;
  const { facetName, pickup } = omniFacet;
  return {
    facetName,
    pickup,
    stores: get(state, 'facetedLeftNav.stores', []),
    pageId: state.page.pageId,
    isMobilePhone: get(state, 'device.isMobilePhone', false),
    geolocatedStore: get(state, 'facetedLeftNav.storeListPayload[0].id', null),
    isNmNewHeader: get(state, 'toggles.NM_NEW_HEADER', false),
  };
};

const mapDispatchToProps = ({
  setStoreName,
});

export default connect(mapStateToProps, mapDispatchToProps)(_OmniFacet);
