import React, { PureComponent } from 'react';
import ConnectedFavoriteDesignerButton from './FavoriteDesignerButton';
import './DesignerLink.scss';

class DesignerLink extends PureComponent {
  render() {
    let adornmentRender = null;
    let ariaLabelSuffix = null;
    const {
      id, name, url, isFavorite, adornmentTag,
    } = this.props;

    if (adornmentTag) {
      adornmentRender = adornmentTag.endsWith('.png')
        ? <img src={adornmentTag} className="adornment-image" alt="New" />
        : <span className="adornment-tag">{adornmentTag}</span>;
      ariaLabelSuffix = adornmentTag.endsWith('.png') ? 'new' : adornmentTag.toLowerCase();
    }


    return (
      <div className="designer-link">
        <div className="flex-container">
          <ConnectedFavoriteDesignerButton isFavorite={isFavorite} id={id} name={name} />
          <a aria-label={`${name} ${ariaLabelSuffix === null ? '' : ariaLabelSuffix}`} id={id} href={`${url}`}>{name}</a>
          { adornmentRender }
        </div>
      </div>
    );
  }
}

export default DesignerLink;
