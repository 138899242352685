import { connect } from 'react-redux';
import RelatedItemsComponent from 'client/common/components/RelatedItems/relatedItems';

const mapStateToProps = (state) => (
  {
    pageType: 'product',
    relatedItemsHtml: decodeURIComponent(state.productListPage.seo.relatedContent),
  }
);
export default connect(mapStateToProps)(RelatedItemsComponent);
