/* eslint-disable no-mixed-operators */
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import renderHTML from 'react-render-html';
import { Helmet } from 'react-helmet';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Backdrop from '@material-ui/core/Backdrop';
import { withStyles } from "@material-ui/core/styles";
import { shouldLoad } from 'universal/http-client';
import { addEventListener, removeEventListener } from 'window-or-global';
import { saveQueryParamToCookies } from 'client/utilities/saveQueryParamToCookies';
import { sanitize } from 'client-utils/utilities-html-sanitizer';
import { hasNoIndexParams } from 'client/utilities/utilities-url';
import { getParams } from 'client-utils/utilities-router';
import { createCookiesFromAbTests } from 'client-utils/utilities-abtest';
import SkipToContent from 'storefront/components/SkipToContent/skipToContent';
import Loadable from 'react-loadable';
import WelcomeMat from 'storefront/components/WelcomeMat/welcomeMat';
import WelcomeMatModal from 'storefront/components/WelcomeMat/welcomeMatModal';
import NavSliderContianer from 'storefront/components/Navigation/NavSlider/navSliderContainer';
import ScreenReaderAnnouncer from 'storefront/components/ScreenReaderAlert/screenReaderAnnouncer';
import HeaderWrapper from 'storefront/components/Header/HeaderWrapper/HeaderWrapper';
import Spinner from 'shared/components/Spinner/spinner';
import { CMSParams } from 'cms/constants';
import { ECMSlots, ECMParams } from 'ecm/constants';
import CPPixel from 'ecm/components/CPPixel/cpPixel';
import EcmGet from 'ecm/components/EcmGet';
import EcmPreviewControl from 'ecm/components/EcmPreviewControl/ecmPreviewControl';
import { loseFocus } from 'shared/components/App/app-actions';
import ModalSelector from 'shared/components/Modal/modalSelector';
import noindexCategoryPage from 'client-utils/noindexCategoryPage';
import { getCmsGlobal } from 'cms/actions';
import RenderGlobalContent from 'cms/components/renderGlobalContent';
import { getGenderWithOverride } from 'client-utils/utilities-gender';
import Toggle from '../Toggle/toggle';
import PanelContainer from '../YourNeimans/PanelContainer';
import {
  PAGE_ID_HOME,
  PAGE_ID_PLP,
  PAGE_ID_SRP,
  PAGE_ID_PDP,
  PAGE_ID_CAT_TMPL,
  domLoadCompleted,
  PAGE_ID_PLA,
  BLUECORE_TEST,
  AB_TEST_SITE_TICKER,
  getCmsGlobalBasedOnToogle
} from '../../actions/actions-page';
import CategoryRouting from '../../../templates/CategoryRoutingStrategy';
import { onModalAnchorClick } from './utilities';
import Footer from '../Footer';
import { useFilterOptions } from '../../../utilities/utilities-router';
import { isGlobalNavUpdateToggleEnabled } from 'cms/actions/index';

const ProductPanelWrapper = Loadable({
  loader: () => import(/* webpackChunkName: 'productPanel' */ 'client/cms/components/ProductPanel/ProductPanelWrapper'),
  loading: () => false,
});

const NewHeaderWrapper = Loadable({
  loader: () => import(/* webpackChunkName: 'ui-header' */'storefront/components/Header/HeaderWrapper/NewHeaderWrapper'),
  loading: () => false,
});

const LimitedBackdrop = withStyles({
  root: {
    position: "fixed",
    zIndex: 1
  }
})(Backdrop);

export class DumbCategoryApp extends Component {
  constructor(props) {
    super(props);
    this.handleModalAnchorClick = this.handleModalAnchorClick.bind(this);
    this.ecmTickerRef = createRef();
    this.defaultTickerHeight = 60;
  }

  componentDidMount() {
    const { isBlueCoreTestOn, bluecoresiteAssignment } = this.props;
    addEventListener('click', this.handleModalAnchorClick);
    addEventListener('load', this.handleLoad);

    if (this.props.useInternalAbTestService) {
      createCookiesFromAbTests(this.props.abTestResults);
    }
    window.enableBluecoreSite = Boolean(isBlueCoreTestOn && bluecoresiteAssignment === 'b');
  }

  componentWillUnmount() {
    removeEventListener('click', this.handleModalAnchorClick);
  }

  UNSAFE_componentWillMount() {
    const { location, isGlobalUpdateOn, siteTickerToggleForGenderURL, selectedGender } = this.props;
    saveQueryParamToCookies(location, ECMParams.PARAM_LAUNCH);
    saveQueryParamToCookies(location, CMSParams.cmsSchedule);
    this.handleClickOutsideFocus = this.handleClickOutsideFocus.bind(this);
    this.handleLoad = this.handleLoad.bind(this);

    if (shouldLoad(this.props.cmsGlobalApi)) {
      const cmsGlobalType =  getCmsGlobalBasedOnToogle(isGlobalUpdateOn, siteTickerToggleForGenderURL, selectedGender);
      this.props.dispatch(getCmsGlobal(cmsGlobalType));
    }
  }

  handleModalAnchorClick(e) {
    onModalAnchorClick(e, this.props);
  }

  handleClickOutsideFocus(e, currentComponentInFocus) {
    const component = this.container.querySelector(currentComponentInFocus);
    if (component && !component.contains(e.target)) {
      this.props.dispatch(loseFocus(currentComponentInFocus));
    }
  }

  handleLoad() {
    this.props.dispatch(domLoadCompleted());
    removeEventListener('load', this.handleLoad);
  }

  render() {
    const {
      isSliderMenuVisible,
      componentInFocus,
      session = {},
      location = {},
      modal = {},
      pageId = '',
      templateType,
      isInternationalToggleOn,
      skeletonLoadingToggle,
      isMobilePhone,
      tickerHeight,
      hpGenderToggle,
      isMenDarkModeToggle,
      selectedGender,
      noindexConfig,
      isSeoToggleOn,
      noIndexFromSelection,
      brand,
      holidayLogoToggle,
      loadHeaderPackage,
      facets,
      seoFacets,
      enableAutoPlay,
      perfLogLevelFive,
      isDrawerOpen,
      isGlobalUpdateOn,
      panelScript,
      isCollapsedSearchBarToggle,
      isRebrandNMToggleOn,
      noFollowNoIndex,
    } = this.props;
    const styles = isSliderMenuVisible ? {
      width: '100%',
      height: '87vh',
      overflow: 'hidden',
      position: 'fixed',
      top: 0,
      left: 0,
    } : {};

    const outsideClickHandler = componentInFocus
      ? (e) => this.handleClickOutsideFocus(e, componentInFocus) : undefined;
    const isFullWidth = (pageId === PAGE_ID_HOME
      || pageId === PAGE_ID_PLP
      || pageId === PAGE_ID_PDP
      || pageId === PAGE_ID_PLA
      || pageId === PAGE_ID_SRP
      || pageId === PAGE_ID_CAT_TMPL);

    const isPlpPage = pageId === PAGE_ID_PLP;
    const isMenGender = selectedGender === 'M';
    const isDarkMode = hpGenderToggle && isMenDarkModeToggle && isMenGender;

    const tickerComponent = (
      <div
        className={classnames("grid-container full-bleed ecm-siteticker", {
          "zindex-master": holidayLogoToggle
        })}
        ref={this.ecmTickerRef}
      >
        {!isEmpty(this.props.cmsGlobal) ? (
          <RenderGlobalContent
            cmsContent={this.props.cmsGlobal}
            placement="SiteTicker"
          />
        ) : (
          ''
        )}
      </div>
    );

    const deviceBrowser = this.props.browserName + this.props.browserVersion;
    const categoryId = location && location.pathname && location.pathname.split('-').pop();
    const params = getParams(this.props.location);
    const noIndexParams = hasNoIndexParams(params);

    // See if all of the filter options are in seo facets. If so, noindex = true
    const { filterOptions } = useFilterOptions(location)
    delete filterOptions["get-it-fast"]
    let newFacets = {...facets}
    delete newFacets["In Store"]

    const mappedSeoFacets = seoFacets.map(seoFacet => seoFacet.replace(/\s/g, "-").replace(/'/g, ""))
    const allFilterOptionsInSeoFacets = Object.keys(filterOptions).every(key => mappedSeoFacets.includes(key))

    const noindex = noFollowNoIndex || (isSeoToggleOn
      && (Object.values(newFacets).map(values => Object.entries(values)).map(values => values.filter(item => (item && item[1] === true))).some(values => values?.length > 1) || !allFilterOptionsInSeoFacets) || (location
        && location.pathname
        && noindexCategoryPage(categoryId, location.query || {}, noindexConfig)));

    let appContainerPadding = isCollapsedSearchBarToggle ? 50 : 95;
    if (this.ecmTickerRef?.current) {
      appContainerPadding += Math.max(60 , this.ecmTickerRef?.current?.clientHeight);
    } else {
      appContainerPadding += Math.max(60 , this.defaultTickerHeight);
    }

    return (
      <div id="appContainer">
        <ScreenReaderAnnouncer />
        <div
          style={{paddingTop: `${isMobilePhone ? appContainerPadding : 0}px`}}
          id="contentContainer"
          className={classnames(`application-container`, {'rebrand-bg': isRebrandNMToggleOn})}
          ref={(ref) => { this.container = ref; }}
          onClick={outsideClickHandler}
          onTouchEnd={outsideClickHandler}
          aria-hidden={modal.open || isSliderMenuVisible}
          tabIndex="-1"
        >
          <Helmet>
            {
              Boolean(this.props.canonical) && !noindex && <link rel="canonical" href={this.props.canonical} />
            }

            {(noindex && !(!enableAutoPlay && perfLogLevelFive)) || noFollowNoIndex ? <meta name="robots" content="noindex,nofollow" /> : null}
          </Helmet>
          <Helmet>
            {
              Boolean(this.props.metaSEOOverride)
              && renderHTML(sanitize(this.props.metaSEOOverride))
            }
          </Helmet>

          {!isMobilePhone && <SkipToContent />}
          {session.personalize && <EcmPreviewControl location={location} />}

          {loadHeaderPackage ? (
            <NewHeaderWrapper
              isDarkMode={isDarkMode}
              tickerComponent={tickerComponent}
              ecmTickerRef={this.ecmTickerRef}
              selectedGender={this.props.selectedGender}
            />
          ) : (
            <HeaderWrapper
              isDarkMode={isDarkMode}
              tickerComponent={tickerComponent}
              ecmTickerRef={this.ecmTickerRef}
              selectedGender={this.props.selectedGender}
            />
          )}
         <div id="panel-container" />
          <div className='main-content-wrapper'>
            {isGlobalUpdateOn && componentInFocus && isDrawerOpen && 
              <LimitedBackdrop open={isDrawerOpen}/>}
            {templateType !== 'F' ? (
              <div
                className={classnames('grid-container full-width-container', {
                  'mainContent-large grid-parent': isFullWidth,
                  'plp-full-width': isPlpPage
                })}
              >
                <div id="mainContent" style={styles}>
                  <CategoryRouting {...this.props} />
                </div>
                {!skeletonLoadingToggle && <Spinner />}
              </div>
            ) : (
              <div
                className="grid-container full-bleed"
              >
                <div id="mainContent" style={styles}>
                  <CategoryRouting {...this.props} />
                </div>
                {!skeletonLoadingToggle && <Spinner />}
              </div>
            )}
          </div>

          <Footer/>
          {
            isInternationalToggleOn ? (<WelcomeMatModal />) : (<WelcomeMat />)
          }
        </div>
        <ModalSelector deviceBrowser={deviceBrowser} />
        <NavSliderContianer />
      {!panelScript &&  <PanelContainer />}
        <ProductPanelWrapper />
        <Toggle feature="COHERENT_PATH_PERSONALIZATION">
          <CPPixel />
        </Toggle>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const isDomestic = get(state, 'locale.countryCode', 'US') === 'US';

  return {
    session: state.session,
    device: state.device,
    pageId: state.page.pageId,
    canonical: state.page.canonical,
    metaSEOOverride: state.page.metaSEOOverride,
    isSliderMenuVisible: state.navigation.navSlider.sliderMenuVisible,
    componentInFocus: state.app.componentInFocus,
    cacheMainNavigation: state.toggles.CACHE_MAIN_NAV,
    modal: state.modal,
    brand: state.brand_name?.env,
    useInternalAbTestService: state.toggles.USE_INTERNAL_ABTEST_SERVICE,
    skeletonLoadingToggle: state.toggles.SKELETON_LOADING,
    abTestResults: state.abTests.abTestResults,
    browserName: state.device.browser,
    browserVersion: state.device.version,
    siteTickerToggle: state.toggles.SITE_TICKER_MODAL,
    isInternationalToggleOn: state.toggles.NM_INTERNATIONAL,
    isMobilePhone: state.device.isMobilePhone,
    tickerHeight: state.header.tickerHeight,
    cmsGlobalApi: state.api.cms_global,
    cmsGlobal: state.cms.global,
    noindexConfig: state.noindexConfig,
    hpGenderToggle: get(state, 'toggles.HP_GENDER', false) && isDomestic,
    isMenDarkModeToggle: get(state, 'toggles.MEN_DARKMODE', false),
    isSeoToggleOn: get(state, 'toggles.SEO_BUCKET_ONE', false),
    selectedGender: getGenderWithOverride(state),
    noIndexFromSelection: get(state, 'facetedLeftNav.noIndexFromSelection', false),
    holidayLogoToggle: get(state, 'toggles.NM_HOLIDAY_GLOBAL_LOGO', false),
    loadHeaderPackage: get(state, 'toggles.UI_HEADER', false) && isDomestic,
    facets: get(state, 'facetedLeftNav.facetedFiltersList', null),
    seoFacets: state.facetedLeftNav.seoFacets,
    isBlueCoreTestOn: get(state, 'toggles.BLUECORE_TEST', false),
    bluecoresiteAssignment: get(state, `abTestsOpt.${BLUECORE_TEST}.variation`, false),
    enableAutoPlay: (get(state, 'page.location.query.ENABLE_AUTOPLAY', 'true') === 'true'),
    perfLogLevelFive: (get(state, 'page.location.query.PERF_LOG_LEVEL', NMConfig.PERF_LOG_LEVEL) === '5'),
    isDrawerOpen: get(state, 'drawerPanel.open'),
    isGlobalUpdateOn: isGlobalNavUpdateToggleEnabled(state),
    siteTickerToggleForGenderURL: get(state, `abTestsOpt.${AB_TEST_SITE_TICKER}.variation`, ''),
    panelScript: get(state, 'toggles.PANEL_SCRIPT', false),
    isCollapsedSearchBarToggle: get(state, 'toggles.COLLAPSED_SEARCH_BAR', false)
      || get(state, `abTestsOpt.tl229.variation`, 'a') === 'b',
    isRebrandNMToggleOn: get(state, 'toggles.NM_REBRAND_2023', false) && isDomestic,
    noFollowNoIndex: get(state, 'productListPage.products.noFollowNoIndex', false),
  };
};

export default connect(mapStateToProps)(DumbCategoryApp);
