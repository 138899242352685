import React, { useState, useEffect } from 'react';
import xss from 'xss';

const MINICART_REFRESHABLE = '/category/checkout/easycart/minicart_banner.html';

const MiniCartBanner = () => {
  const [content, setContent] = useState('');

  useEffect(() => {
    if (!content.length) {
      // eslint-disable-next-line compat/compat
      fetch(MINICART_REFRESHABLE)
        .then((response) => response.text())
        .then((html) => {
          if (!(['403', '404', 'Oops', 'IP'].some((str) => html.includes(str)))) {
            setContent(html);
          }
        })
        .catch((error) => {
          setContent('');
          console.log('Failed to fetch refreshable: ', error);
        });
    }
  }, []);

  return (
    <div
      className=""
      dangerouslySetInnerHTML={{
        __html: xss(content, {
          whiteList: {
            Loading: ['refreshable'],
            p: ['style'],
            a: [
              'data-modal-width',
              'data-modal-url',
              'data-modal-entry-id',
              'class',
              'href',
            ],
            span: ['style'],
            div: ['style'],
            b: [],
            picture: [],
            img: ['src'],
          },
        }),
      }}
    />
  );
};

export default MiniCartBanner;
