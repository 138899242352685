import React, { Fragment, PureComponent } from 'react';
import DesignerLink from './DesignerLink';
import './AlphaSection.scss';

class AlphaSection extends PureComponent {
  constructor(props) {
    super(props);
    this.displayAlphaSection = this.displayAlphaSection.bind(this);
    this.modFourRender = this.modFourRender.bind(this);
  }

  modFourRender(subDesignersHackClass) {
    return (
      <Fragment>
        <div className="sub-designers-container two">
          {
            this.props.designers.map((el, i) => {
              return i < Math.floor(this.props.designers.length / 2)
                && i >= Math.floor(this.props.designers.length / 4)
                ? (
                  <DesignerLink
                    key={i}
                    id={el.id}
                    name={el.name}
                    url={el.url}
                    adornmentTag={el.adornmentTag}
                    isFavorite={el.favorites}
                  />
                )
                : null;
            })
          }
        </div>
        <div className="sub-designers-container three">
          {
            this.props.designers.map((el, i) => {
              return i < Math.floor(this.props.designers.length * (3 / 4))
                && i >= Math.floor(this.props.designers.length / 2)
                ? (
                  <DesignerLink
                    key={i}
                    id={el.id}
                    name={el.name}
                    url={el.url}
                    adornmentTag={el.adornmentTag}
                    isFavorite={el.favorites}
                  />
                )
                : null;
            })
          }
        </div>
        <div className={subDesignersHackClass}>
          {
            this.props.designers.map((el, i) => {
              return i >= Math.floor(this.props.designers.length * (3 / 4))
                ? (
                  <DesignerLink
                    key={i}
                    id={el.id}
                    name={el.name}
                    url={el.url}
                    adornmentTag={el.adornmentTag}
                    isFavorite={el.favorites}
                  />
                )
                : null;
            })
          }
        </div>
      </Fragment>
    );
  }


  displayAlphaSection() {
    const iterationInterval = Math.floor(this.props.designers.length % 4 !== 0)
      ? Math.floor(this.props.designers.length / 4)
      : Math.floor(this.props.designers.length / 4) - 1;

    let subDesignersHackClass;
    if (this.props.designers.length % 4 === 1 || this.props.designers.length % 4 === 2) {
      subDesignersHackClass = 'sub-designers-container four compensate-space';
    } else {
      subDesignersHackClass = 'sub-designers-container four';
    }

    return (
      <div className="designers-container">
        <div className="sub-designers-container one">
          {
            this.props.designers.map((el, i) => {
              return i <= iterationInterval
                ? (
                  <DesignerLink
                    key={i}
                    id={el.id}
                    name={el.name}
                    url={el.url}
                    adornmentTag={el.adornmentTag}
                    isFavorite={el.favorites}
                  />
                )
                : null;
            })
          }
        </div>
        {this.props.designers.length % 4 !== 2 && this.props.designers.length % 4 !== 0
          && (
          <Fragment>
            <div className="sub-designers-container two">
              {
                this.props.designers.map((el, i) => {
                  return i <= Math.floor(this.props.designers.length / 2)
                    && i > Math.floor(this.props.designers.length / 4)
                    ? (
                      <DesignerLink
                        key={i}
                        id={el.id}
                        name={el.name}
                        url={el.url}
                        adornmentTag={el.adornmentTag}
                        isFavorite={el.favorites}
                      />
                    )
                    : null;
                })
              }
            </div>
            <div className="sub-designers-container three">
              {
                this.props.designers.map((el, i) => {
                  return i <= Math.floor(this.props.designers.length * (3 / 4))
                    && i > Math.floor(this.props.designers.length / 2)
                    ? (
                      <DesignerLink
                        key={i}
                        id={el.id}
                        name={el.name}
                        url={el.url}
                        adornmentTag={el.adornmentTag}
                        isFavorite={el.favorites}
                      />
                    )
                    : null;
                })
              }
            </div>
            <div className={subDesignersHackClass}>
              {
                this.props.designers.map((el, i) => {
                  return i > Math.floor(this.props.designers.length * (3 / 4))
                    ? (
                      <DesignerLink
                        key={i}
                        id={el.id}
                        name={el.name}
                        url={el.url}
                        adornmentTag={el.adornmentTag}
                        isFavorite={el.favorites}
                      />
                    )
                    : null;
                })
              }
            </div>
          </Fragment>
          )
        }
        {
          this.props.designers.length % 4 === 0 && this.modFourRender(subDesignersHackClass)
        }
        {
          this.props.designers.length % 4 === 2
            && (
            <Fragment>
              <div className="sub-designers-container two hide-on-tablet">
                {
                  this.props.designers.map((el, i) => {
                    return i <= Math.floor(this.props.designers.length / 2)
                      && i > Math.floor(this.props.designers.length / 4)
                      ? (
                        <DesignerLink
                          key={i}
                          id={el.id}
                          name={el.name}
                          url={el.url}
                          adornmentTag={el.adornmentTag}
                          isFavorite={el.favorites}
                        />
                      )
                      : null;
                  })
                }
              </div>
              <div className="sub-designers-container three hide-on-tablet">
                {
                  this.props.designers.map((el, i) => {
                    return i <= Math.floor(this.props.designers.length * (3 / 4))
                      && i > Math.floor(this.props.designers.length / 2)
                      ? (
                        <DesignerLink
                          key={i}
                          id={el.id}
                          name={el.name}
                          url={el.url}
                          adornmentTag={el.adornmentTag}
                          isFavorite={el.favorites}
                        />
                      )
                      : null;
                  })
                }
              </div>
              <div className="sub-designers-container four hide-on-tablet">
                {
                  this.props.designers.map((el, i) => {
                    return i > Math.floor(this.props.designers.length * (3 / 4))
                      ? (
                        <DesignerLink
                          key={i}
                          id={el.id}
                          name={el.name}
                          url={el.url}
                          adornmentTag={el.adornmentTag}
                          isFavorite={el.favorites}
                        />
                      )
                      : null;
                  })
                }
              </div>
              <div className="sub-designers-container two hide-on-desktop hide-on-mobile">
                {
                  this.props.designers.map((el, i) => {
                    return i < Math.floor(this.props.designers.length / 2)
                      && i > Math.floor(this.props.designers.length / 4)
                      ? (
                        <DesignerLink
                          key={i}
                          id={`${el.id}_hidden`}
                          name={el.name}
                          url={el.url}
                          adornmentTag={el.adornmentTag}
                          isFavorite={el.favorites}
                        />
                      )
                      : null;
                  })
                }
              </div>
              <div className="sub-designers-container three hide-on-desktop hide-on-mobile">
                {
                  this.props.designers.map((el, i) => {
                    return i <= Math.floor(this.props.designers.length * (3 / 4))
                      && i >= Math.floor(this.props.designers.length / 2)
                      ? (
                        <DesignerLink
                          key={i}
                          id={`${el.id}_hidden`}
                          name={el.name}
                          url={el.url}
                          adornmentTag={el.adornmentTag}
                          isFavorite={el.favorites}
                        />
                      )
                      : null;
                  })
                }
              </div>
              <div className="sub-designers-container four hide-on-desktop hide-on-mobile">
                {
                  this.props.designers.map((el, i) => {
                    return i > Math.floor(this.props.designers.length * (3 / 4))
                      ? (
                        <DesignerLink
                          key={i}
                          id={`${el.id}_hidden`}
                          name={el.name}
                          url={el.url}
                          adornmentTag={el.adornmentTag}
                          isFavorite={el.favorites}
                        />
                      )
                      : null;
                  })
                }
              </div>
            </Fragment>
            )
        }
      </div>
    );
  }

  render() {
    const { id, spaced } = this.props;
    const sectionClass = spaced ? 'alpha-section grid-parent spaced' : 'alpha-section grid-parent';

    return (
      <section id={`${id === '#' ? 'NUMBER' : id}`} ref={`${id}`} className={sectionClass}>
        <h2 tabIndex={0} className="section-title">{id}</h2>
        {this.displayAlphaSection()}
      </section>
    );
  }
}

export default AlphaSection;
